import { Group, Text } from "@mantine/core";
import { FlexSpacer, PhoneNumber } from "../../../../common-ui";
import { formatTime } from "../../../../shared-utils";

interface BodyProps {
    mdnText: string;
    secondsTillCall: number;
    errorCalling: boolean;
}
export function CallbackCallBarBody({ mdnText, secondsTillCall, errorCalling }: BodyProps) {
    if (errorCalling) {
        return (
            <Group flex={1}>
                <Text fw={700} size="md">
                    Call Failed{" "}
                    <Text fw={700} size="md">
                        <PhoneNumber mdn={mdnText} />
                    </Text>
                </Text>
                <FlexSpacer />
            </Group>
        );
    }

    return (
        <Group align="center">
            <Text fw={400} size="sm">
                Calling{" "}
                <b className="fs-mask">
                    <PhoneNumber mdn={mdnText} />
                </b>{" "}
                <>
                    in
                    <Text fw={700} size="md">
                        {formatTime(secondsTillCall, true, "m:ss")}
                    </Text>
                </>
            </Text>
        </Group>
    );
}
