import { type SolveGuidedFlowStep } from "../types";

export const defaultErrorStep: SolveGuidedFlowStep = {
    stepId: "DefaultError",
    content: "Oops—something went wrong.",
    progress: 0,
    variant: "Error",
    actions: [
        {
            label: "Close guide",
        },
    ],
};
