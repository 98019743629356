import type { SVGProps } from "react";

export function DollarSign(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M14.5 9.5H12.358C11.4984 9.50031 10.7517 10.0915 10.5543 10.9281C10.3568 11.7647 10.7603 12.6273 11.529 13.012L13.479 13.988C14.2477 14.3727 14.6512 15.2353 14.4537 16.0719C14.2563 16.9085 13.5096 17.4997 12.65 17.5H10.5"
                stroke="#D0AAF1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M12.504 9.5V8" stroke="#D0AAF1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.504 19V17.5" stroke="#D0AAF1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.5 13C24.4975 11.3605 23.4948 9.88856 21.97 9.28604C22.6279 7.78206 22.297 6.02859 21.1362 4.86781C19.9755 3.70704 18.222 3.37614 16.718 4.03404C16.1191 2.50586 14.6453 1.50049 13.004 1.50049C11.3627 1.50049 9.88887 2.50586 9.29 4.03404C7.78602 3.37614 6.03255 3.70704 4.87177 4.86781C3.711 6.02859 3.3801 7.78206 4.038 9.28604C2.50982 9.88491 1.50445 11.3587 1.50445 13C1.50445 14.6414 2.50982 16.1152 4.038 16.714C3.3801 18.218 3.711 19.9715 4.87177 21.1323C6.03255 22.293 7.78602 22.6239 9.29 21.966C9.88887 23.4942 11.3627 24.4996 13.004 24.4996C14.6453 24.4996 16.1191 23.4942 16.718 21.966C18.222 22.6239 19.9755 22.293 21.1362 21.1323C22.297 19.9715 22.6279 18.218 21.97 16.714C23.4948 16.1115 24.4975 14.6395 24.5 13Z"
                stroke="#D0AAF1"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
