import { type Whisper } from "../../sdk";

function createWhisperAudio(src: string, { volume = 0.6 } = {}) {
    const audio = new Audio(src);
    audio.volume = volume;
    return audio;
}

const spanishAudio = createWhisperAudio("/assets/audio/spanish-whisper.mp3");
const genericTone = createWhisperAudio("/assets/audio/generic-tone.mp3");

const playSpanishWhisper = () => {
    void spanishAudio.play();
};

const playGenericWhisper = () => {
    void genericTone.play();
};

export const isWhisperSpanish = (whisper: Whisper | undefined) => whisper?.includes("spanish");

export const verifyAndPlayWhisper = (whisper: Whisper | undefined) => {
    if (isWhisperSpanish(whisper)) {
        playSpanishWhisper();
    } else {
        playGenericWhisper();
    }
};
