import { Button, Flex, Group, Title } from "@mantine/core";
import { GetStartedAnimation } from "../common-ui";

export function LoggedOut() {
    const handleLogin = () => {
        window.location.href = "/";
    };
    return (
        <Flex justify="center" align="center" h="100vh" p="xl">
            <Group maw="500">
                <Title order={1} data-testid="description" fw="var(--mantine-font-weight-light)">
                    Thanks for helping customers today! See you next time.
                </Title>
                <Button
                    data-testid="login-button"
                    onClick={handleLogin}
                    size="lg"
                    variant="outline"
                    color="asurion.6"
                    c="white"
                >
                    Log back in
                </Button>
            </Group>
            <Group maw="600" miw="400" w="100%">
                <GetStartedAnimation />
            </Group>
        </Flex>
    );
}
