import { ActionIcon, Tooltip } from "@mantine/core";
import { forwardRef } from "react";
import { useAnalytics } from "../../../analytics";
import { classNames } from "../../../shared-utils";
import styles from "./IconButton.module.css";
import type { IconButtonProps } from "./types";

const IconButtonWrapper = forwardRef<HTMLButtonElement, IconButtonProps>(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function IconButtonWrapper({ active, tooltip, ...rest }: IconButtonProps, ref) {
        return (
            <ActionIcon
                className={classNames(styles.iconButtonRoot, active ? styles.iconButtonActive : null)}
                {...rest}
                ref={ref}
            />
        );
    },
);

export function IconButton(props: IconButtonProps) {
    const { dispatcher } = useAnalytics();
    const { actionId, extraContext, eventData } = props.analytics;
    const enhancedDispatcher = extraContext ? dispatcher.withExtra(extraContext) : dispatcher;

    function onClick(event: React.MouseEvent<HTMLButtonElement>) {
        void enhancedDispatcher.dispatchAction("ButtonClick", actionId, { ...eventData });
        props.onClick?.(event);
    }

    const handleWrapperEnter = () => {
        void enhancedDispatcher.dispatchAction("KeypressEnter", actionId, eventData);
    };

    return (
        <Tooltip label={props.tooltip} position="bottom" zIndex={999}>
            <IconButtonWrapper
                className={classNames(styles.iconButtonRoot, props.active ? styles.iconButtonActive : null)}
                {...props}
                onClick={onClick}
                onKeyUp={(e) => e.key === "Enter" && handleWrapperEnter}
            >
                {props.children}
            </IconButtonWrapper>
        </Tooltip>
    );
}
