import type { SVGProps } from "react";

export function DoubleCheckMark(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 13" width="100%" height="100%" fill="none" {...props}>
            <path
                fill={props.fill ?? "#A5AAAF"}
                fillRule="evenodd"
                d="M16.707 1.137a1 1 0 0 1 0 1.414l-5 5a1 1 0 1 1-1.414-1.414l5-5a1 1 0 0 1 1.414 0Zm5 0a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L11 10.43l9.293-9.293a1 1 0 0 1 1.414 0Zm-21.414 5a1 1 0 0 1 1.414 0l5 5a1 1 0 1 1-1.414 1.414l-5-5a1 1 0 0 1 0-1.414Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
