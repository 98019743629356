import { useEffect } from "react";
import { getIVRDetails } from "../ivr-api";
import { useDeviceActivationStore } from "../store";
import { userCache } from "../../shared-utils";

export const useConversationalIVR = (isConversationalIVRGuideEnabled: boolean, ivrCallSid?: string) => {
    const { setIvrInfo } = useDeviceActivationStore();

    useEffect(() => {
        if (!isConversationalIVRGuideEnabled) return;

        async function requestIVRDetails() {
            if (!ivrCallSid) return;

            const ivrDetails = await getIVRDetails(ivrCallSid, userCache.ssoAccessToken ?? "");

            if (ivrDetails) {
                setIvrInfo(
                    {
                        details: ivrDetails.detailsCaptured,
                    },
                    ivrDetails.device,
                );
            }
        }

        void requestIVRDetails();
    }, [isConversationalIVRGuideEnabled, ivrCallSid, setIvrInfo]);
};
