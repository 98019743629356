import { Container, Flex, Text } from "@mantine/core";
import { useEffect } from "react";
import { useVerifiedCustomerStore } from "../customer-info";
import type { VoiceTask } from "../sdk";
import { getSessionId, isVoiceTask, useMostRecentTask, usePartner } from "../sdk";
import classes from "./SalesFlow.module.css";
import { useEligibilityV2, useHomeProductSessionStore, useOtherProductSessionStore } from "./home-product";
import { IneligibleIcon, LoadingAnimation } from "./home-product/components/Icons";
import { MultiProduct } from "./multi-product/MultiProduct";
import { DetectedDevicesInfo } from "./detected-devices/DetectedDevicesInfo";

interface SalesFlowProps {
    previousDevices?: string[] | undefined;
}
export function SalesFlow({ previousDevices }: SalesFlowProps): JSX.Element {
    const mostRecentTask = useMostRecentTask();
    const partner = usePartner();

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }
    const mdn = useVerifiedCustomerStore((state) => state.customer?.mdn);
    const sessionId = getSessionId();
    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);
    const {
        data: eligibilityV2,
        isError: eligibilityV2Error,
        isLoading: isLoadingEligibilityV2,
    } = useEligibilityV2(
        {
            asurionCallId: mostRecentTask.asurionCallId,
            partner,
            reservationSid: (mostRecentTask as VoiceTask & { config?: { reservation?: { sid: string } } }).config
                ?.reservation?.sid,
            sessionId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            customerMdn: mdn!.slice(2),
        },
        useHomeProductSessionStore.persist.hasHydrated() && !homeProduct,
    );

    useEffect(() => {
        if (eligibilityV2 && useHomeProductSessionStore.persist.hasHydrated() && !homeProduct) {
            useOtherProductSessionStore.getState().updateProducts(eligibilityV2.filter((p) => !p.isPrimaryProduct));
            useHomeProductSessionStore.getState().updateProduct(eligibilityV2.find((p) => p.isPrimaryProduct));
        }
    }, [eligibilityV2, homeProduct]);

    if (eligibilityV2Error) {
        return (
            <Flex className={classes.loadingContainer} data-testid="failure">
                <Container>{IneligibleIcon}</Container>
                <Text className={classes.statusBodyHeader}>We had an issue checking eligibility.</Text>
                <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                    Try refreshing the page.
                </Text>
            </Flex>
        );
    }

    if (isLoadingEligibilityV2) {
        return (
            <Flex className={classes.loadingContainer}>
                <Text>Confirming customer information...</Text>
                <Container maw="160px">
                    <LoadingAnimation />
                </Container>
            </Flex>
        );
    }

    return (
        <>
            <DetectedDevicesInfo previousDevices={previousDevices} />
            <MultiProduct />
        </>
    );
}
