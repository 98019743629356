import dayjs from "dayjs";

export const getCurrentWeekRange = (): string => {
    const startOfWeek = dayjs().day(1);
    const endOfWeek = dayjs().day(5);

    const startMonth = startOfWeek.format("MMM");
    const startDay = startOfWeek.format("D");
    const endMonth = endOfWeek.format("MMM");
    const endDay = endOfWeek.format("D");

    if (startMonth === endMonth) {
        return `${startMonth} ${startDay}–${endDay}`;
    }
    return `${startMonth} ${startDay}–${endMonth} ${endDay}`;
};
