import { type CctsContact, type Contact, vdnToPartner } from "./transfer";

// TODO: VDN database is currently littered with "NOT FOUND" instead of
// a partner value.
export const partners = ["verizon", "att", "cricket", "asurion"] as const;
export type Partner = (typeof partners)[number];
export interface PartnerProgram {
    name: string;
    claimEligible: boolean;
    survey: {
        generalSurveyId: string;
        claimsSurveyId: string;
    };
}

export const PartnerMapping: ReadonlyMap<string, Partner> = new Map<string, Partner>()
    .set("Verizon ExWo", "verizon")
    .set("ATT MPP ExWo", "att")
    .set("ATT MDPP ExWo", "att");

export function isValidPartner(value: string | undefined): value is Partner {
    if (!value) {
        return false;
    }
    return (partners as readonly string[]).includes(value);
}

export type FeatureName = "callbackLater" | "outboundCall" | "autoPopEnabled" | "surveyEnabled";

export function getContactPartner(contact: Contact | CctsContact): Partner | undefined | "NOT FOUND" {
    return vdnToPartner.get(contact.vdn) ?? contact.partner;
}

export function filterContacts(contacts: Contact[], partner: Partner): Contact[] {
    return contacts.filter(
        // If contact does not have a partner, or is NOT FOUND, then it is available
        // for all experts to use.
        (contact) =>
            !contact.partner ||
            contact.partner === partner ||
            contact.partner === "NOT FOUND" ||
            contact.partner === "asurion",
    );
}
