import { DatePickerInput, type DateValue } from "@mantine/dates";
import dayjs from "dayjs";
import { useEffect } from "react";
import { Calendar } from "../../../../../common-ui";
import { getWidgetStatus } from "../../../../api";
import { useCallbacksFormStore } from "../../../callbacks.store";
import { FIRST_DAY_OF_WEEK, MAX_SCHEDULE_AHEAD_DAYS } from "../../../types";
import { getDefaultTimeslots } from "../../../utilities";
import classes from "./inputs.module.css";

export function DateInput() {
    const { setScheduledDate, setUnixTimeslots, setFetchingTimeslots, scheduledDate, scheduledTimeZone } =
        useCallbacksFormStore();

    const now = dayjs();

    const minDate = now.toDate();
    const maxDate = now.add(MAX_SCHEDULE_AHEAD_DAYS, "days").toDate();

    /** Fetch available time-slots from Mindful */
    const fetchAvailableTimes = async (date: Date) => {
        setFetchingTimeslots(true);
        const { timeslotsAvailable: timeslots } = await getWidgetStatus({
            dateTimestamp: date.getTime(),
        });

        timeslots.length === 0
            ? setUnixTimeslots(getDefaultTimeslots(date, scheduledTimeZone))
            : setUnixTimeslots(timeslots);
    };

    const handleChange = (newDate: DateValue): void => {
        // ? getting middle of day to compensate for weird timezone offsets when fetching available times from backend
        const newDateMidDay = dayjs(newDate).hour(12).minute(0).toDate();
        setScheduledDate(newDateMidDay);
    };

    useEffect(() => {
        void fetchAvailableTimes(scheduledDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scheduledDate]);

    //? zIndex="var(--mantine-priority-higher)" is needed if this is used in a modal someday
    return (
        <DatePickerInput
            className={classes.datePickerInput}
            dropdownType="popover"
            firstDayOfWeek={FIRST_DAY_OF_WEEK}
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleChange}
            onDateChange={handleChange}
            placeholder="Date:"
            rightSection={<Calendar height={24} />}
            rightSectionPointerEvents="none"
            size="lg"
            value={scheduledDate}
        />
    );
}
