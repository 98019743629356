export const IneligibleIcon = (
    <svg data-testid="ineligible-icon" xmlns="http://www.w3.org/2000/svg" width={66} height={66} fill="none">
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M33 63.667c16.937 0 30.667-13.73 30.667-30.666 0-16.937-13.73-30.667-30.667-30.667S2.334 16.064 2.334 33.001c0 16.936 13.73 30.666 30.666 30.666Z"
            clipRule="evenodd"
        />
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2.667}
            d="M15.667 30.334c0-.737.597-1.334 1.333-1.334h32c.736 0 1.333.597 1.333 1.334v5.333c0 .736-.596 1.334-1.333 1.334H17a1.333 1.333 0 0 1-1.333-1.334v-5.333Z"
            clipRule="evenodd"
        />
    </svg>
);
