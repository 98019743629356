import { useState } from "react";
import { parsePhoneNumber } from "awesome-phonenumber";
import { Flex, Group } from "@mantine/core";
import { type VoiceTask } from "../../sdk";
import { type Contact, TFN_LENGTH, US } from "../../shared-types";
import { extractNumbers, isInternalTfn } from "../../shared-utils";
import { AddParticipantButton } from "../CallControls/Buttons/AddParticipantButton";
import { useControlsStore } from "../CallControls/controls.store";
import { PhoneNumberInput } from "./components/PhoneNumberInput";
import { TransferCallButton } from "./components/TransferCallButton";
import { useContactsStateStore } from "./vdnContacts.store";

interface TransferCallProps {
    voiceTask: VoiceTask;
}

export function TransferCall({ voiceTask }: TransferCallProps) {
    const [transferTfn, setTransferTfn] = useState<string>();
    const [transferVdn, setTransferVdn] = useState<Contact>();

    const { controlLock } = useControlsStore();
    const { contacts } = useContactsStateStore();
    const isAddButtonDisabled = (!transferTfn && !transferVdn) || controlLock;

    const trySetTransferTfn = (tfn: string | undefined) => {
        if (!tfn) {
            setTransferTfn(undefined);
            return;
        }

        const digitsOnly = extractNumbers(tfn).slice(-TFN_LENGTH); // Prevent adding any numbers from the name
        const { valid } = parsePhoneNumber(`${US}${digitsOnly}`);

        if (!valid && !isInternalTfn(tfn, contacts)) {
            //TODO: should we reset state and handle this error more gracefully?
            throw new Error("Invalid phone number");
        }

        setTransferTfn(digitsOnly);
        setTransferVdn(undefined);
    };

    const trySetTransferVdn = (vdn: Contact | undefined) => {
        setTransferVdn(vdn);

        if (vdn) {
            setTransferTfn(undefined);
        }
    };

    return (
        <>
            <PhoneNumberInput
                transferTfn={transferTfn}
                setTransferTfn={trySetTransferTfn}
                transferVdn={transferVdn}
                setTransferVdn={trySetTransferVdn}
            />
            <Flex align="center" justify="end">
                <Group>
                    <TransferCallButton task={voiceTask} transferTfn={transferTfn} transferVdn={transferVdn} />
                    <AddParticipantButton
                        task={voiceTask}
                        transferVdn={transferVdn}
                        transferTfn={transferTfn}
                        disabled={isAddButtonDisabled}
                        isPrimary={
                            !!voiceTask.customerLeftTheConference &&
                            !!transferTfn &&
                            voiceTask.callerId.includes(transferTfn)
                        }
                    />
                </Group>
            </Flex>
        </>
    );
}
