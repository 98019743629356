import { getLogger } from "@expert/logging";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import type { CachedUser } from "../../shared-utils";
import { userCache } from "../../shared-utils";
import { getServiceProviderAccessToken } from "../service-provider";

const logger = getLogger({ module: "useAccessToken" });

type UseAccessTokenOutput = [boolean, Error | null, string | null];

/** Can ONLY be used under the <AuthProvider> scope
 * Retrieves the service provider access token, such as Twilio
 */
export function useAccessToken(userFriendlyName?: string): UseAccessTokenOutput {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const auth = useAuth();

    useEffect(() => {
        const getTokenLocal = async () => {
            if (!auth.user) {
                throw new Error("Unable to initialize SDK, no SSO User Available");
            }
            userCache.setUser(auth.user as unknown as CachedUser);
            const serviceProviderAccessToken = await getServiceProviderAccessToken(userFriendlyName);
            logger.debug("Token received");

            setToken(serviceProviderAccessToken);
            setLoading(false);
        };

        // TODO: More elegant error handling/reporting when we decide how we want this to bubble up to user space
        getTokenLocal().catch((err: unknown) => {
            setError(err as Error);
            logger.error({ err });
            setLoading(false);
        });
    }, [auth]);

    return [loading, error, token];
}
