import type { ElementProps, InputProps } from "@mantine/core";
import { MDN_MASK, PhoneNumberFormattedMask } from "../../shared-types";
import { MaskedInput } from "./MaskedInput";
import inputs from "./inputs.module.css";

interface MdnInputProps extends InputProps, ElementProps<"input", keyof InputProps> {
    onInput: ElementProps<"input", keyof InputProps>["onInput"];
}

export function MdnInput(props: Omit<MdnInputProps, "className">) {
    return (
        <MaskedInput
            className="fs-mask"
            mask={MDN_MASK}
            type="tel"
            placeholder={PhoneNumberFormattedMask}
            classNames={{ input: inputs.mdn }}
            {...props}
        />
    );
}
