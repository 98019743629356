import { useDisclosure } from "@mantine/hooks";
import { useTimer } from "react-timer-hook";
import { getLogger } from "@expert/logging";
import { useGetQuestionnaire } from "../hooks/useQuestionnaire";
import { useAnalytics } from "../../../analytics";
import { MicroLearningModal } from "./MicroLearningModal";

export function MicroLearning(): JSX.Element {
    const logger = getLogger({
        module: "MicroLearning",
    });
    const { dispatcher } = useAnalytics();
    const [opened, { open, close }] = useDisclosure(false);
    const { data: questionnaireData, isLoading } = useGetQuestionnaire();

    useTimer({
        expiryTimestamp: new Date(Date.now() + 5000),
        autoStart: true,
        onExpire: () => {
            open();
            void dispatcher.dispatchBusinessEvent("OpenMicroLearningModal", {
                questionId: questionnaireData?.questionId,
                question: questionnaireData?.question,
            });
        },
    });

    function OnClose(): void {
        try {
            close();
        } catch (e) {
            logger.error({ err: e }, "postAnswer API call failed");
        }
    }

    return (
        <>
            {!isLoading && questionnaireData && (
                <MicroLearningModal
                    isOpen={opened}
                    close={() => {
                        OnClose();
                    }}
                    question={questionnaireData}
                />
            )}
        </>
    );
}
