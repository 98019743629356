import { Badge, Text } from "@mantine/core";
import type { SaleStatusStyle } from "../../types";
import { EligibilityStatus, StatusStyles } from "../../types";
import styles from "./SalesTag.module.css";

export interface EligibilityTagProps {
    eligibility: EligibilityStatus;
}

export function SalesTag({ eligibility }: EligibilityTagProps) {
    const { badgeText, badgeStyle } = EligibilityBadgeMap[eligibility] as {
        badgeText: string;
        badgeStyle: SaleStatusStyle;
    };
    return badgeText ? (
        <Badge autoContrast radius={2} color={badgeStyle.bgColor}>
            <Text className={styles.text} c={badgeStyle.fontColor} size="xs">
                {badgeText}
            </Text>
        </Badge>
    ) : null;
}

const EligibilityBadgeMap: Record<EligibilityStatus, { badgeText: string; badgeStyle: SaleStatusStyle }> = {
    [EligibilityStatus.Eligible]: {
        badgeText: "Eligible",
        badgeStyle: StatusStyles.Success,
    },
    [EligibilityStatus.Enrolled]: {
        badgeText: "Already enrolled",
        badgeStyle: StatusStyles.Neutral,
    },
    [EligibilityStatus.Ineligible]: {
        badgeText: "Ineligible",
        badgeStyle: StatusStyles.Error,
    },
    [EligibilityStatus.Pending]: {
        badgeText: "Pending",
        badgeStyle: StatusStyles.Neutral,
    },
    [EligibilityStatus.Unknown]: {
        badgeText: "Unknown",
        badgeStyle: StatusStyles.Neutral,
    },
};
