export function PlayIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={19} height={20} fill="#E6E6EB">
            <path
                stroke="#E6E6EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.014}
                d="M2.216 18.619a1.042 1.042 0 0 1-1.508-.931V2.307a1.042 1.042 0 0 1 1.508-.93l15.38 7.689a1.042 1.042 0 0 1 0 1.862l-15.38 7.69Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
