import { Flex, SimpleGrid, Tabs, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useProductInfo } from "@expert/contentful";
import { useAnalytics } from "../../analytics";
import type { EligibleProduct } from "../home-product";
import { SalesTag, useHomeProductSessionStore, useOtherProductSessionStore } from "../home-product";
import { HomeProductFlow } from "../home-product/components/HomeProductFlow";
import { EligibilityStatus, SaleProgress } from "../home-product/types";
import { SaleView } from "../home-product/components/StepSpecific/SaleView";
import { isVoiceTask, useMostRecentTask, usePartner } from "../../sdk";
import { getAppConfigSingleton } from "../../config/appConfigSingleton";
import classes from "./MultiProduct.module.css";
import { SaleTrackingModal } from "./components";
import { OfferBaseCard } from "./components/BaseOfferCard";

export function MultiProduct(): JSX.Element {
    const [opened, { open, close }] = useDisclosure(false);
    const { dispatcher } = useAnalytics();
    const partner = usePartner();
    const [selectedProduct, setSelectedProduct] = useState<EligibleProduct | undefined>(undefined);
    const eligibleProducts = useOtherProductSessionStore((state) => state.products);
    const mostRecentTask = useMostRecentTask();
    const skuArray = eligibleProducts.map((product) => product.productSku);
    const { productInfo, error } = useProductInfo(partner, skuArray);
    const appConfig = getAppConfigSingleton();

    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }

    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);
    if (error) {
        return (
            <Text ta="center" m="3rem">
                We ran into an issue. Legal disclosures are not available right now.
            </Text>
        );
    }

    return (
        <Tabs
            classNames={{ root: classes.root }}
            radius={0}
            data-testid="multi-product-tab-container"
            defaultValue="homePlan"
        >
            <Tabs.List grow justify="center">
                <Tabs.Tab
                    className={classes.tab}
                    onClick={() => void dispatcher.dispatchAction("ButtonClick", "HomeTab")}
                    value="homePlan"
                >
                    <Flex className={classes.tabHeader}>
                        <Text size="sm">Home plan</Text>{" "}
                        {homeProduct && homeProduct.status === EligibilityStatus.Eligible ? (
                            <SalesTag eligibility={homeProduct.status} />
                        ) : null}
                    </Flex>
                </Tabs.Tab>
                {appConfig.partnersConfig[partner].hasMultipleSalesSkus && (
                    <Tabs.Tab
                        className={classes.tab}
                        onClick={() => void dispatcher.dispatchAction("ButtonClick", "OtherPlansTab")}
                        value="otherPlans"
                    >
                        Other plans
                    </Tabs.Tab>
                )}
            </Tabs.List>
            <Tabs.Panel className={classes.tabPanel} value="homePlan">
                <HomeProductFlow />
            </Tabs.Panel>
            {appConfig.partnersConfig[partner].hasMultipleSalesSkus && (
                <Tabs.Panel className={classes.tabPanel} value="otherPlans">
                    {eligibleProducts.length === 0 ? (
                        <SaleView planType="Other" status={EligibilityStatus.Ineligible} />
                    ) : (
                        <SimpleGrid className={classes.cardGrid} cols={2} spacing="lg">
                            {eligibleProducts.map((product) => {
                                return product.productSku !== "VZN_VMPMD" ||
                                    eligibleProducts.find((p) => p.productSku === "VZN_AAL")?.saleProgress ===
                                        SaleProgress.Success ? (
                                    <OfferBaseCard
                                        productInfo={productInfo?.[product.productSku]}
                                        product={product}
                                        key={product.productSku}
                                        onClick={(selected) => {
                                            void dispatcher.dispatchAction("ButtonClick", "OfferCardClicked", {
                                                productSku: selected.productSku,
                                            });
                                            setSelectedProduct(selected);
                                            open();
                                        }}
                                    />
                                ) : null;
                            })}
                        </SimpleGrid>
                    )}
                </Tabs.Panel>
            )}
            {selectedProduct ? (
                <SaleTrackingModal
                    onClose={close}
                    opened={opened}
                    displayName={productInfo?.[selectedProduct.productSku]?.displayName}
                    selectedProduct={selectedProduct}
                />
            ) : null}
        </Tabs>
    );
}
