export function ShieldIcon() {
    return (
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.50002 3.61522V8.69122C1.50005 11.9756 3.52268 14.9207 6.58802 16.0999L7.28202 16.3666C7.74418 16.5443 8.25586 16.5443 8.71802 16.3666L9.41202 16.0999C12.4774 14.9207 14.5 11.9756 14.5 8.69122V3.61522C14.5026 3.22505 14.2761 2.86964 13.9214 2.70722C12.0546 1.89242 10.0368 1.481 8.00002 1.49989C5.96326 1.481 3.94546 1.89242 2.07869 2.70722C1.72392 2.86964 1.49741 3.22505 1.50002 3.61522Z"
                stroke="#A5AAAF"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M5.3335 9.33325L7.3335 10.9999L10.6668 6.33325"
                stroke="#A5AAAF"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
