import type { SVGProps } from "react";

export function MessagingIcon({ height = 20, width = 20, fill, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            {...rest}
            fill="none"
        >
            <path
                fill={fill}
                d="M6.248 8a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0ZM9.998 9.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5ZM16.248 8a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Z"
            />
            <path
                fill={fill}
                fillRule="evenodd"
                d="M3.998 16v4l5.333-4h8.667c1.103 0 2-.897 2-2V2c0-1.104-.897-2-2-2h-16c-1.103 0-2 .896-2 2v12c0 1.103.897 2 2 2h2Zm-2-2V2h16v12H8.665l-2.667 2v-2h-4Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
