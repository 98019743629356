import type { Task } from "./task";

export interface TrainingAttachment {
    url: string;
    title: string;
}

// These are the details we will expose internally, if we need more information here
// it can be added as needed.
export interface TrainingTask extends Task {
    get createdDate(): Date;
    get isActive(): boolean;
    get client(): string | undefined;
    get clientFriendlyName(): string | undefined;

    get tags(): string[];
    get attachments(): TrainingAttachment[] | undefined;
    get lengthMinutes(): number;
    get link(): string | undefined;
    get name(): string;
    get notes(): string;

    startTimestamp: number | undefined;
}

export function isTrainingTask(task?: Task): task is TrainingTask {
    return !!task && task.type === "training";
}
