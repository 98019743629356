import * as React from "react";
import type { SVGProps } from "react";

export function GreenQuote(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none" {...props}>
            <path
                fill="#20C997"
                d="M9.048 12.729c0 1.121-.424 2.06-1.273 2.819-.849.757-1.91 1.136-3.183 1.136a4.573 4.573 0 0 1-3.228-1.273C.454 14.532 0 13.274 0 11.637c0-.97.197-1.94.591-2.91.394-.97.894-1.879 1.5-2.728a16.507 16.507 0 0 1 2.047-2.273c.727-.667 1.424-1.182 2.091-1.546l2.273 2.228a5.126 5.126 0 0 0-1.136.682 7.097 7.097 0 0 0-1.091 1.046 4.587 4.587 0 0 0-.819 1.273c-.212.455-.318.91-.318 1.364 1.121.152 2.046.576 2.773 1.273.758.667 1.137 1.561 1.137 2.683Zm10.952 0c0 1.121-.424 2.06-1.273 2.819-.849.757-1.91 1.136-3.183 1.136a4.573 4.573 0 0 1-3.228-1.273c-.91-.879-1.364-2.137-1.364-3.774 0-.97.197-1.94.591-2.91.394-.97.894-1.879 1.5-2.728a16.502 16.502 0 0 1 2.046-2.273c.728-.667 1.425-1.182 2.092-1.546l2.273 2.228a5.128 5.128 0 0 0-1.136.682 7.097 7.097 0 0 0-1.091 1.046 4.588 4.588 0 0 0-.819 1.273c-.212.455-.318.91-.318 1.364 1.121.152 2.046.576 2.773 1.273.758.667 1.137 1.561 1.137 2.683Z"
            />
        </svg>
    );
}
