import { useActiveTask } from "./useActiveTask";
import { useAgentSdk } from "./useAgentSdk";

/** Provides the canonical partner for the active task/session, if no task exists returns partner assigned to worker */
export function usePartner() {
    const activeTask = useActiveTask();
    const agentSdk = useAgentSdk();
    // TODO: This is unsafe, technically `[partner]` could be undefined, but TypeScript doesn't recognize that. That's ok, because agentSDK should
    //       always have at least one partner, but we should still have our types reflect that somehow in the future.
    const [partner] = agentSdk.getPartners();
    return activeTask?.partner ?? partner;
}
