import type { SVGProps } from "react";

export function CorrectAnswer(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M18.5 7.99902L11.5 17.499L6.5 13.499"
                stroke="#20C997"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.5 24.499C18.8513 24.499 24 19.3503 24 12.999C24 6.64775 18.8513 1.49902 12.5 1.49902C6.14873 1.49902 1 6.64775 1 12.999C1 19.3503 6.14873 24.499 12.5 24.499Z"
                stroke="#20C997"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
