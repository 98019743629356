import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { persist } from "zustand/middleware";
import type { MessageSuggestion } from "@expert/shared-types";

export interface DevicesEventPayload {
    name: string;
    count: number;
    type: string;
}

interface GaiaEventState {
    devicesEvents: DevicesEventPayload[];
    processGaiaSalesEvent: (event: MessageSuggestion) => void;
    resetEvents: () => void;
}

export const useGaiaMessageStore = createWithEqualityFn<GaiaEventState>()(
    persist(
        immer((set) => ({
            devicesEvents: [],
            processGaiaSalesEvent: ({ suggestion }: MessageSuggestion) => {
                set((state) => {
                    switch (suggestion.responseType) {
                        case "cues": {
                            if (Array.isArray(suggestion.cues)) {
                                const newCues = suggestion.cues.filter(
                                    (cue) =>
                                        !state.devicesEvents.some(
                                            (event) =>
                                                event.name === cue.name &&
                                                event.count === cue.count &&
                                                event.type === cue.type,
                                        ),
                                );

                                if (newCues.length > 0) {
                                    state.devicesEvents = [...state.devicesEvents, ...newCues];
                                }
                            }
                            break;
                        }

                        default:
                            return state;
                    }
                });
            },
            resetEvents: () => {
                set((state) => {
                    state.devicesEvents = [];
                });
            },
        })),
        { name: "gaia-sales-store", skipHydration: true },
    ),
    shallow,
);
