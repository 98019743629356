import { initLoggingTransmission } from "@expert/logging/src/utils/sendToBackend";
import { setDynatraceIdentifier } from "@expert/monitoring";
import { env } from "../../../config";

export declare class CachedUser {
    access_token: string;
    profile: {
        sub: string;
        employeeID: string | null;
        given_name: string | null;
        family_name: string | null;
        userPrincipalName: string | null;
        email: string | null;
        groups: string[];
    };
}

export class UserCache {
    private _cachedUser?: CachedUser;

    public setUser(user: CachedUser) {
        this._cachedUser = user;
        setDynatraceIdentifier({
            name: `${user.profile.given_name} ${user.profile.family_name}`,
            email: user.profile.sub,
            employeeId: user.profile.employeeID,
        });
        // TODO: Make it's more elegant. For example by emitting event
        initLoggingTransmission({ accessToken: user.access_token, collectorUrl: env.logCollectorUrl });
    }

    public get ssoAccessToken() {
        return this._cachedUser?.access_token;
    }

    public get identity() {
        return this._cachedUser?.profile.sub;
    }

    public get employeeId() {
        return this._cachedUser?.profile.employeeID ?? null;
    }

    public get userPrincipalName() {
        return this._cachedUser?.profile.userPrincipalName ?? null;
    }

    public get userEmail() {
        return this._cachedUser?.profile.email ?? null;
    }

    public get givenName() {
        return this._cachedUser?.profile.given_name ?? null;
    }

    public get familyName() {
        return this._cachedUser?.profile.family_name ?? null;
    }

    public get groups() {
        return this._cachedUser?.profile.groups ?? null;
    }

    public containsGroup(group: string) {
        return this._cachedUser?.profile.groups.includes(group);
    }
}

export const userCache = new UserCache();
