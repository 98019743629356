import type { VoiceTask } from "../../sdk";
import { MAX_CONFERENCE_PARTICIPANTS } from "../types";

/**
 * the total count of call participants (excluding the expert and including participants that have not yet picked up)
 * The primary customer is added separately, since they are not included in task.conferenceParticipants
 */
export const getConferenceCallersCount = (task: VoiceTask): number => {
    const { conferenceParticipants, customerLeftTheConference } = task;

    //? build a set of ids to account for duplicates
    const participantCallIds = new Set<string>();
    for (const { type, participantCallId } of conferenceParticipants) {
        if (type === "inactive") continue;
        participantCallIds.add(participantCallId);
    }

    return participantCallIds.size + (customerLeftTheConference ? 0 : 1);
};

/** Returns true if the conference limit has been reached */
export const isConferenceLimitReached = (task: VoiceTask): boolean =>
    getConferenceCallersCount(task) >= MAX_CONFERENCE_PARTICIPANTS;
