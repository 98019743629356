import { getLogger } from "@expert/logging";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

const logger = getLogger({
    module: "useSessionStore",
});

export type SessionSummary = string;

export type SummaryResolution = "resolved" | "not resolved" | "transferred" | null;
export interface SetSessionSummaryPayload {
    value: SessionSummary;
    autoGenerated?: boolean;
}

export interface SessionSummaryState {
    summary: SessionSummary;
    autogeneratedSummary: SessionSummary;
    resolutionStatus: SummaryResolution;
    /** true if resolution status form should be hidden from the summary modal */
    hideResolutionStatusForm: boolean;
    submitted: boolean;
    canAppend: boolean;
    setCanAppend: (value: boolean) => void;
    setSessionSummary: (payload: SetSessionSummaryPayload) => void;
    appendSessionSummary: (fragment: SessionSummary) => void;
    /** reset state to default */
    resetSessionSummary: () => void;
    /**  change resolution status to predefined value. Optionally the form can be hidden from the summary modal when calling this */
    setResolved: (wasIssueResolved: SummaryResolution, options?: { hideResolutionStatusForm?: boolean }) => void;
    setSubmitted: (value: boolean) => void;
}

export const useSessionSummaryStore = createWithEqualityFn<SessionSummaryState>()(
    immer((set) => ({
        summary: "",
        autogeneratedSummary: "",
        resolutionStatus: null,
        hideResolutionStatusForm: false,
        submitted: false,
        canAppend: true,
        setCanAppend: (canAppend: boolean) => set({ canAppend }),
        setSessionSummary: ({ value, autoGenerated }: SetSessionSummaryPayload) => {
            set((state) => {
                state.summary = value;
                autoGenerated ? (state.autogeneratedSummary = value) : null;
            });
        },
        appendSessionSummary: (fragment: SessionSummary) => {
            set((state) => {
                if (!state.canAppend) {
                    logger.warn("Cannot append to session summary, canAppend is false");
                    return;
                }

                const formatted = [state.summary, fragment].join(" ").trim();

                state.summary = formatted;
                state.autogeneratedSummary = formatted;
            });
        },
        resetSessionSummary: () =>
            set({
                summary: "",
                autogeneratedSummary: "",
                resolutionStatus: null,
                hideResolutionStatusForm: false,
                canAppend: true,
                submitted: false,
            }),
        setResolved: (wasIssueResolved: SummaryResolution, options = { hideResolutionStatusForm: false }) =>
            set({ resolutionStatus: wasIssueResolved, hideResolutionStatusForm: options.hideResolutionStatusForm }),
        setSubmitted: (submitted: boolean) => set({ submitted }),
    })),
    shallow,
);
