import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import { useEffect } from "react";
import type { MessageSuggestion } from "@expert/shared-types";
import { useGaiaMessageStore } from "../../sales-tools/home-product/stores/GaiaMessageStore";
import { getRootDispatcher } from "../../analytics";

export const useGaiaSalesMessage = () => {
    useEffect(() => {
        const subscription = gaiaWsEventBus.on("gaia_ws_message-suggestion", ({ body }: GaiaWebSocketResponse) => {
            const gaiaSalesMessage = body as MessageSuggestion;

            if (gaiaSalesMessage.suggestion.cues) {
                gaiaSalesMessage.suggestion.cues.forEach((d) => {
                    void getRootDispatcher().dispatchBusinessEvent("DeviceDetected", {
                        detectionSource: "GAIA",
                        device: d.name,
                    });
                });
                useGaiaMessageStore.getState().processGaiaSalesEvent(gaiaSalesMessage);
            }
        });
        return () => subscription();
    }, []);
};
