import { getSalesApiClient } from "../../home-product/api/api";

export interface SalesLeaderboardEntry {
    employeeId: string;
    employeeName: string;
    numSales: number;
    order: number;
}

export const getCurrentSalesLeaderboard = async (): Promise<SalesLeaderboardEntry[]> => {
    const salesApi = getSalesApiClient();
    return await salesApi.get("v2/leaderboards/sales/3002", { retry: 0 }).json<SalesLeaderboardEntry[]>();
};
