import { type Partner } from "../../shared-types";

export const MAX_SUMMARY_LENGTH = 930;

export const getTimeRemaining = (expirationTimestamp: number | undefined) => {
    return expirationTimestamp ? Math.max(expirationTimestamp - Date.now(), 0) : 0;
};

export function getPartnerTool(partner: string) {
    switch (partner) {
        case "verizon":
            return "ACSS";

        case "att":
            return "OPUS";

        default:
            console.log(`No partner tool found for this partner: ${partner}`);
    }
}

export function getNotificationText(partner: Partner, isValidAccountNumber: boolean) {
    if (partner === "att") {
        return `Please copy and paste to ${getPartnerTool(partner)}.`;
    }

    if (isValidAccountNumber) {
        return `Summary will be sent to ${getPartnerTool(partner)}`;
    }

    return `Auto-send failed. Please copy and paste to ${getPartnerTool(partner)} manually.`;
}
