import { type SVGProps } from "react";

export function BackIcon({ height = 25, width = 24, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M4.97314 12.25H19.0269"
                stroke="var(--mantine-color-dark-0)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.4643 5.375L5.18743 11.7806C5.0529 11.8993 4.97583 12.07 4.97583 12.2494C4.97583 12.4288 5.0529 12.5995 5.18743 12.7181L12.4643 19.125"
                stroke="var(--mantine-color-dark-0)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
