import type { ProactiveMessagePayload } from "@expert/shared-types";

export const isProactiveMessage = (body: Partial<ProactiveMessagePayload>) => {
    const suggestion = body.suggestion;
    if (!suggestion?.message) return false;
    return suggestion.messageType === "Proactive" && suggestion.producer === "orchestrator";
};

export const isProactiveErrorMessage = (body: Partial<ProactiveMessagePayload>) => {
    const message = body.suggestion?.message;
    if (!message) return false;
    return (
        message.toLowerCase().startsWith("internal server error") ||
        message.toLowerCase().startsWith("internal system error")
    );
};
