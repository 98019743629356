import type { SVGProps } from "react";

export function BarGraph(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.16699 2.91667C8.16699 2.27233 8.68933 1.75 9.33366 1.75H11.667C12.3113 1.75 12.8337 2.27233 12.8337 2.91667V11.0833C12.8337 11.7277 12.3113 12.25 11.667 12.25H2.33366C1.68933 12.25 1.16699 11.7277 1.16699 11.0833V7.58333C1.16699 6.939 1.68933 6.41667 2.33366 6.41667H4.66699V5.25C4.66699 4.60567 5.18933 4.08333 5.83366 4.08333H8.16699V2.91667ZM9.33366 11.0833H11.667V2.91667H9.33366V11.0833ZM8.16699 5.25H5.83366V11.0833H8.16699V5.25ZM4.66699 7.58333H2.33366V11.0833H4.66699V7.58333Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
