import { getLogger } from "@expert/logging";
import { getSessionId } from "../../../sdk";
import { getSalesApiClient } from "../../../sales-tools/home-product/api/api";
import { type Reaction } from "../../models";

const logger = getLogger({
    module: "send-reaction",
    supportTeam: "sales",
});

export const sendReaction = async (targetExpertId: string, reactionType: Reaction) => {
    const childLogger = logger.child({ targetExpertId, reactionType, sessionId: getSessionId() });
    try {
        logger.info("Sending reaction");
        const salesApi = getSalesApiClient();
        return await salesApi.post("v2/reaction", { json: { targetExpertId, reactionType } }).json<boolean>();
    } catch (err: unknown) {
        childLogger.error({ err }, "sendReaction failure");
        return false;
    }
};
