import { EditablePhoneNumber, VdnName } from "../../../../common-ui";
import { extractNumbers, removeCountryCode, tryGetByVdnDestination, tryGetByVdnNumber } from "../../../../shared-utils";
import { useContactsStateStore } from "../../../TransferCall/vdnContacts.store";

interface ParticipantInfoProps {
    mdn: string;
    customerLeft?: boolean;
}

export function ParticipantLabel({ mdn, customerLeft }: ParticipantInfoProps): JSX.Element {
    const { contacts } = useContactsStateStore();
    const vdn =
        tryGetByVdnNumber(extractNumbers(removeCountryCode(mdn)), contacts) ??
        tryGetByVdnDestination(extractNumbers(removeCountryCode(mdn)), contacts);
    return vdn ? (
        <VdnName vdn={vdn} data-testid="participant-vdn-name" />
    ) : (
        <EditablePhoneNumber mdn={mdn} customerLeft={customerLeft} data-testid="participant-editable-phone-number" />
    );
}
