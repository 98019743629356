import { type SVGProps } from "react";

export function InfoIcon({ height = 20, width = 20, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            fill="none"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M12.7293 14.0184V18.9459C12.7293 19.853 11.994 20.5884 11.0868 20.5884H3.6956C2.78847 20.5884 2.0531 19.853 2.0531 18.9459V3.3422C2.0531 2.43508 2.78847 1.69971 3.6956 1.69971H7.80184"
                stroke="#F7EDFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.7292 11.5547C15.4506 11.5547 17.6567 9.34857 17.6567 6.6272C17.6567 3.90582 15.4506 1.69971 12.7292 1.69971C10.0079 1.69971 7.80176 3.90582 7.80176 6.6272C7.80176 9.34857 10.0079 11.5547 12.7292 11.5547Z"
                stroke="#E6E6EB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.7293 9.09066V6.21629C12.7293 5.98951 12.5455 5.80566 12.3187 5.80566H11.4974"
                stroke="#F7EDFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.1134 3.75293C12.2268 3.75293 12.3187 3.84485 12.3187 3.95824C12.3187 4.07163 12.2268 4.16355 12.1134 4.16355C12 4.16355 11.9081 4.07163 11.9081 3.95824C11.9081 3.84485 12 3.75293 12.1134 3.75293"
                stroke="#F7EDFF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11.4974 9.09082H13.9612"
                stroke="#E6E6EB"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
