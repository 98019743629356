import { getFeature } from "@soluto-private/expert-workspace-feature-flagging";
import { useQuery } from "@tanstack/react-query";
import { userCache } from "../auth";

export const environment = import.meta.env.MODE;
export const useFeatureFlag = <T = boolean>(
    featureFlag: string,
    context: Record<string, unknown> = {},
    cacheTtl = 1800, // 30 mins
) => {
    const employeeId = userCache.employeeId;
    const { data, isLoading, isError } = useQuery({
        queryKey: [featureFlag, ...Object.values(context)],
        queryFn: async (): Promise<T | undefined> =>
            getFeature<T>(
                featureFlag,
                {
                    environment,
                    employeeId,
                    appName: "expert-workspace",
                    source: "expert-ui",
                    ...context,
                },
                cacheTtl,
            ),
        refetchOnMount: false,
    });
    return { data, isLoading, isError };
};
