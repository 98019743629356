import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { useEffect, useMemo } from "react";
import { useTimelineStore } from "@soluto-private/expert-workspace-timeline";
import { useReactAnalytics } from "@soluto-private/eventualize-react";
import { trackFullStoryEvent } from "@expert/monitoring";
import { type AutoCompleteSuggestionPayload } from "../types";

export const useGaiaAutoCompleteEvents = (loggerProp: Logger) => {
    const logger = useMemo(() => loggerProp.child({ module: "useGaiaAutocompleteEvents" }), [loggerProp]);
    const { dispatcher } = useReactAnalytics();

    useEffect(() => {
        const gaiaEventBusFetchAutoCompleteSuccess = gaiaWsEventBus.on(
            "gaia_ws_fetch-auto-complete-suggestion-success",
            ({ body }: GaiaWebSocketResponse) => {
                const { status, suggestions, suggestionGroupId } = (body ?? {}) as AutoCompleteSuggestionPayload;
                logger.info({ suggestions }, "GAIA | AutoComplete | websocket received autocomplete suggestion(s)");

                if (status !== 200) {
                    logger.error({ body }, "GAIA | AutoComplete | fetch autocomplete suggestions failure");
                    return;
                }

                void dispatcher.dispatchBusinessEvent("AutoComplete_SuggestionReceived", {
                    suggestionGroupId,
                    suggestions,
                });
                trackFullStoryEvent("AutoComplete_SuggestionReceived", { suggestionGroupId, suggestions });

                const parsedSuggestions = suggestions.map(({ suggestion }) => suggestion);
                const dedupedSuggestions = [...new Set(parsedSuggestions)];

                useTimelineStore.getState().setNewSuggestions(dedupedSuggestions);
            },
        );

        const gaiaEventBusFetchAutoCompleteFailure = gaiaWsEventBus.on(
            "gaia_ws_fetch-auto-complete-suggestion-error",
            (response) => {
                logger.error({ response }, "GAIA | AutoComplete | suggestion error");
            },
        );

        return () => {
            gaiaEventBusFetchAutoCompleteSuccess();
            gaiaEventBusFetchAutoCompleteFailure();
        };
    }, [dispatcher, logger]);
};
