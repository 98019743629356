import { type ContentfulClientApi } from "contentful";
import type { Language } from "../types";

export const getRecordedLegalDisclosure = async (
    contentfulClient: ContentfulClientApi<undefined>,
    productSku: string,
    language: Language,
): Promise<string | undefined> => {
    const tags =
        language === "en-US" ? [`recordedDisclosure,${productSku}`] : [`recordedDisclosureSpanish,${productSku}`];
    const asset = await contentfulClient.getAssets({
        "metadata.tags.sys.id[all]": tags,
    });
    return asset.items[0].fields.file?.url;
};
