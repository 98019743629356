import { Pill, type PillProps } from "@mantine/core";

export function GuideTag({ children, ...rest }: PillProps): JSX.Element {
    return (
        <Pill
            bg="linear-gradient(90deg, var(--mantine-color-success-4) 0.3%, #60FFFF 105.93%)"
            c="var(--mantine-color-black)"
            fw={700}
            fz={12}
            p="0 .1875rem"
            radius={2}
            size="xs"
            w="fit-content"
            {...rest}
        >
            {children}
        </Pill>
    );
}
