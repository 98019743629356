import type { ScaleEntry, PayList } from "../types";

export const trimScales = (scale?: PayList) => {
    if (!scale) {
        return [{ pay: 0, perMetricPay: 0, metric: 0 }] as ScaleEntry[];
    }
    const firstRepeat = scale.findIndex((element, index) => {
        return element.perMetricPay === scale[index + 1].perMetricPay;
    });
    const trimmedScale = scale.slice(0, firstRepeat + 1);
    return trimmedScale;
};
