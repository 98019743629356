import type { TextProps } from "@mantine/core";
import { Text } from "@mantine/core";
import { useTimer } from "react-timer-hook";
import { formatTime } from "../../shared-utils";
import styles from "./trainingModal.module.css";

type TrainingCountdownProps = TextProps & {
    timeStarted: number;
    timeExpired?: () => void;
    format?: string;
    appendUnit?: boolean;
};

export function TrainingCountdownText({
    timeStarted,
    timeExpired,
    format,
    appendUnit,
    size,
    ...other
}: TrainingCountdownProps): JSX.Element {
    const { totalSeconds } = useTimer({
        expiryTimestamp: new Date(timeStarted),
        autoStart: true,
        onExpire: () => {
            timeExpired?.();
        },
    });

    return (
        <Text className={styles.Timer} size={size ?? "sm"} span {...other}>
            {formatTime(totalSeconds, appendUnit, format)}
        </Text>
    );
}
