import { Group, Radio, Stack } from "@mantine/core";
import { usePartnerFeatureConfigured } from "../../../../partner/usePartnerConfig";
import { type CallbackFormVariant, type CallbackRadioOption } from "../../types";
import { DateTimeSelection, SecondsInput } from "./Inputs";

interface CallbackTypeSelectionProps {
    onChange: (value: CallbackRadioOption) => void;
    value: CallbackRadioOption | undefined;
    variant: CallbackFormVariant;
}

/** options to show during a session with an active ongoing call*/
export function ActiveCallOptions({ value }: { value: CallbackTypeSelectionProps["value"] }) {
    const isCallbackEnabled = usePartnerFeatureConfigured("callbackLater");
    return (
        <>
            <Radio
                label={
                    <Group gap="xs">
                        with me in
                        <SecondsInput disabled={value !== "callback-user-with-delay"} />
                        seconds
                    </Group>
                }
                fw="lighter"
                fs="sm"
                value="callback-user-with-delay"
            />
            {isCallbackEnabled ? (
                <>
                    <Radio
                        data-testid="callback-later"
                        label="with anyone"
                        fw="lighter"
                        fs="sm"
                        value="callback-to-queue-later"
                    />
                    {value === "callback-to-queue-later" && <DateTimeSelection />}
                </>
            ) : null}
        </>
    );
}

/** options to show during the wrap-up/session-summary phase of a session */
function PostCallOptions({ value }: { value: CallbackTypeSelectionProps["value"] }) {
    return (
        <>
            <Radio label="now" fw="lighter" fs="sm" value="callback-user-now" />
            <Radio
                label={
                    <Group gap="xs">
                        in
                        <SecondsInput disabled={value !== "callback-user-with-delay"} />
                    </Group>
                }
                fw="lighter"
                fs="sm"
                value="callback-user-with-delay"
            />
        </>
    );
}

/** Radio group selection component for handling callback options*/
export function CallbackTypeSelection({ onChange, value, variant = "active-call" }: CallbackTypeSelectionProps) {
    const renderOptions = () =>
        variant === "active-call" ? <ActiveCallOptions value={value} /> : <PostCallOptions value={value} />;

    return (
        <Radio.Group ml="sm" onChange={(v) => onChange(v as CallbackRadioOption)} value={value}>
            <Stack gap="lg">{renderOptions()}</Stack>
        </Radio.Group>
    );
}
