import { Container, Flex, Text } from "@mantine/core";
import { GreenQuote } from "../Icons";

export function TransitionStatements(): JSX.Element | null {
    return (
        <Flex p="16px 4px">
            <Container p={0}>
                <GreenQuote />
            </Container>
            <Text c="dark.1" size="sm" pl={12}>
                We have another plan, separate from your phone insurance, that covers eligible tech in your home.
            </Text>
        </Flex>
    );
}
