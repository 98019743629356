import { Divider, Group, List, Space, Stack, Text } from "@mantine/core";
import { MarkdownViewer } from "@expert/solve-tools";
import { type IvrInfo, type DeviceInfo } from "../types";

interface ActivationInfoProps extends DeviceInfo, Partial<IvrInfo> {
    isSupportedDeviceModel?: boolean;
    isForRestart?: boolean;
    isConversationalIVRGuideEnabled?: boolean;
}

export function ActivationInfo({
    iccid,
    imei,
    model,
    details: conversationalDetails,
    isSupportedDeviceModel,
    isForRestart,
    isConversationalIVRGuideEnabled,
}: ActivationInfoProps) {
    if (!(model ?? imei ?? iccid ?? conversationalDetails)) {
        return (
            <Group gap="0.5rem">
                <Text size="1.125rem" c="white" fw={700}>
                    Save time
                </Text>
                <Text size="1.125rem" c="white">
                    and make activations easy
                </Text>
            </Group>
        );
    }

    // Unsupported device model
    if (model && !isSupportedDeviceModel && !isConversationalIVRGuideEnabled) {
        return (
            <Stack gap="xs">
                <Group gap="0.5rem">
                    <Text c="white">Need to activate</Text>
                    <Text c="white" fw={700}>
                        {model}
                    </Text>
                </Group>
                <Text size="sm">
                    The activation flow only supports <strong>iPhones and Samsung phones</strong>, but more devices are
                    coming soon!
                </Text>
            </Stack>
        );
    }

    const hasDetails = !!iccid || !!imei || !!conversationalDetails;
    const title = `${conversationalDetails ? "Need" : "May need"} to activate replacement`;

    return (
        <Stack gap="xs">
            <Group gap="0.5rem">
                {isForRestart ? (
                    <Text size="1.125rem">
                        Restart the activation using the <strong>updated</strong> phone number?
                    </Text>
                ) : (
                    <Text size="1.125rem">
                        {title} <strong>{model ?? "device"}</strong>
                    </Text>
                )}
            </Group>
            {hasDetails && (
                <Stack gap="xs">
                    <Divider color="primary.4" />
                    <Text size="sm" fw={700}>
                        Details
                    </Text>
                    {conversationalDetails && <MarkdownViewer content={conversationalDetails} />}
                    <List size="sm" spacing="0.375rem" pl="sm">
                        {isForRestart && model && <List.Item>{model}</List.Item>}
                        {imei && <List.Item>IMEI: {imei}</List.Item>}
                        {iccid && <List.Item>ICCID: {iccid}</List.Item>}
                    </List>
                </Stack>
            )}
            <Space h="0.25rem" />
        </Stack>
    );
}
