import { Flex, Group, Stack } from "@mantine/core";
import PQueue from "p-queue";
import { useEffect } from "react";
import useAsync from "react-use/lib/useAsync";
import { getLogger } from "@expert/logging";
import { useGlobalNotification } from "../../common-ui";
import { sdkEventBus, useAgentSdk, useCallbackState, type VoiceTask } from "../../sdk";
import { CallbackInfoBanner, ScheduleCallbackForm } from "../ScheduleCallback";
import { TransferCall } from "../TransferCall/TransferCall";
import { updateExistingCallback } from "../eventHandlers";
import { verifyAndPlayWhisper } from "../utils/whisperUtils";
import { useContactsStateStore } from "../TransferCall/vdnContacts.store";
import classes from "./CallControls.module.css";
import { CallControlsSwitch } from "./CallControlsSwitch";
import { CallDetailsSelector } from "./CallDetailsSelector";
import { useControlsStore } from "./controls.store";

const BUFFER_INTERVAL = 300;
const dialQueue = new PQueue({ concurrency: 1, intervalCap: 1, interval: BUFFER_INTERVAL });

interface CallControlsProps {
    voiceTask: VoiceTask;
}

const logger = getLogger({ module: "CallControls" });

export function CallControls({ voiceTask }: CallControlsProps): JSX.Element {
    const agentSdk = useAgentSdk();
    const callbackState = useCallbackState();
    const globalNotification = useGlobalNotification();

    const {
        clearPanelsState,
        scheduleCallbackPanelOpen,
        dialpadInput,
        transferCallActive,
        dialpadNotification,
        setIsConference,
        setLoadingExistingCallback,
        setDialpadNotification,
        resetState: resetControlsStoreState,
    } = useControlsStore();

    const { getAndSetContacts } = useContactsStateStore();

    useAsync(async () => {
        try {
            await getAndSetContacts(voiceTask.partner);
            logger.info("Contacts successfully set");
        } catch (error) {
            logger.error("Error getting contacts:", error);
        }
    }, []);

    useEffect(() => {
        if (["wrapping", "completed", "cancelled"].includes(voiceTask.status)) {
            clearPanelsState();
            setIsConference(false);

            if (dialpadNotification) {
                globalNotification.clearNotification(dialpadNotification);
                setDialpadNotification(undefined);
            }
        }

        // Whenever the main task is in a pending state, that means we're accepting a call
        // and it has to be the first call in this session (since a conference can't have been started yet).
        // If that's the case, then we reset the call controls state here so the call controls always
        // start in the default state at the beginning of a call
        if (voiceTask.status === "pending") {
            resetControlsStoreState();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voiceTask.status]);

    useEffect(() => {
        if (dialpadInput.length > 0) {
            const dial = dialpadInput[dialpadInput.length - 1];
            void dialQueue.add(() => agentSdk.sendDtmfDigits(dial));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialpadInput]);

    useEffect(() => {
        const unsubCallAccepted = sdkEventBus.on("call_accepted", async (newVoiceTask: VoiceTask) => {
            verifyAndPlayWhisper(newVoiceTask.whisper);
            setLoadingExistingCallback(true);
            await updateExistingCallback(newVoiceTask);
            setLoadingExistingCallback(false);
        });

        return () => {
            unsubCallAccepted();
        };
    }, [setLoadingExistingCallback]);

    useEffect(() => {
        if (voiceTask.conferenceParticipants.some(({ type }) => type !== "inactive")) {
            clearPanelsState();
            setIsConference(true);
        } else {
            setIsConference(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voiceTask.conferenceParticipants]);

    return (
        <Stack data-testid="active-task">
            <Group className={classes.controlsRow}>
                <CallDetailsSelector task={voiceTask} />
                <Flex flex="1" justify="flex-end" miw="fit-content">
                    <Group align="center" data-testid={`call-controls-group-${voiceTask.status}`} justify="flex-end">
                        <CallControlsSwitch task={voiceTask} />
                    </Group>
                </Flex>
            </Group>
            {callbackState && voiceTask.status === "assigned" ? <CallbackInfoBanner /> : null}
            {scheduleCallbackPanelOpen ? <ScheduleCallbackForm /> : null}
            {transferCallActive ? <TransferCall voiceTask={voiceTask} /> : null}
        </Stack>
    );
}
