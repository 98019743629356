import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { sdkEventBus } from "../../sdk";
import { type TrainingStep } from "./utilities";

// Ensure we reset our state after the session ends
sdkEventBus.on("session_changed", () => {
    useTrainingStateStore.getState().resetState();
});

interface TrainingState {
    trainingStep: TrainingStep;
    expirationTimestamp?: number;
}
interface TrainingActions {
    resetState: () => void;
    setTrainingStep: (trainingStep: TrainingStep) => void;
    setExpirationTimestamp: (expirationTimestamp: number) => void;
}

type TrainingStateStore = TrainingState & TrainingActions;

const defaultState: TrainingState = {
    trainingStep: "starting",
    expirationTimestamp: undefined,
};

export const useTrainingStateStore = create<TrainingStateStore>()(
    persist(
        devtools(
            (set) => ({
                ...defaultState,

                setTrainingStep: (step: TrainingStep) => set({ trainingStep: step }, false, "setTrainingStep"),

                setExpirationTimestamp: (timestamp: number) =>
                    set({ expirationTimestamp: timestamp }, false, "setExpirationTimestamp"),

                resetState: () => set(defaultState, false, "resetState"),
            }),
            { enabled: import.meta.env.MODE !== "production", store: "training", name: "workspace/training-modal" },
        ),
        {
            name: "training-store",
        },
    ),
);
