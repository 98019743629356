import { Input, ScrollArea, Stack, Text } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { RadioCard } from "../../../common-ui";
import type { CustomerDetailsPayload } from "../../../customer-info";
import classes from "./CustomerVerificationUpdated.module.css";

enum CustomerType {
    Primary = "Primary",
    Authorized = "Authorized",
    Custom = "Someone else",
}

interface CustomerProps {
    name: string;
    type: CustomerType;
}

interface CustomerNamesSectionProps {
    customer?: CustomerDetailsPayload;
    onOptionSelect: (input?: string) => void;
    onMdnSave: boolean;
}

const SOMEONE_ELSE = "Someone else";

export function CustomerNamesSection({ customer, onOptionSelect, onMdnSave }: CustomerNamesSectionProps): JSX.Element {
    const [selected, setSelected] = useState("");
    const focusRef = useRef<HTMLInputElement>(null);
    const onRadioButtonSelected = (name: string) => {
        onOptionSelect(undefined);
        setSelected(name);
        if (name === SOMEONE_ELSE) {
            focusRef.current?.focus();
        }
        if (name !== SOMEONE_ELSE) {
            onOptionSelect(name);
        }
    };
    useEffect(() => {
        setSelected("");
    }, []);
    const customerProps: CustomerProps[] = [];
    const onInputChange = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        onOptionSelect(value);
    };

    if (customer?.primaryAccountHolder?.fullName !== undefined) {
        customerProps.push({ name: customer.primaryAccountHolder.fullName, type: CustomerType.Primary });
        customer.authorizedUsers?.forEach((user) => {
            if (user.fullName) {
                customerProps.push({ name: user.fullName, type: CustomerType.Authorized });
            }
        });
        customerProps.push({ name: SOMEONE_ELSE, type: CustomerType.Custom });
    }

    return (
        <>
            <Text c="dark.1" size="18px" mb="1rem" mt="2rem">
                What’s their name?
            </Text>
            {customerProps.length > 0 && (
                <ScrollArea type="auto" h={customerProps.length > 2 ? 160 : 120} scrollbarSize={5}>
                    <Stack gap={0} mb="16px">
                        {customerProps.map((item) => {
                            return (
                                <RadioCard
                                    disabled={onMdnSave}
                                    className={`${selected === item.name ? classes.radioSelected : classes.radio} fs-mask`}
                                    color="var(--mantine-color-dark-5)"
                                    onClick={() => {
                                        onRadioButtonSelected(item.name);
                                    }}
                                    selected={selected === item.name}
                                    key={item.name}
                                    label={item.name.toLowerCase()}
                                    value={item.name}
                                    analytics={{
                                        actionId: "CustomerNameSelected",
                                    }}
                                />
                            );
                        })}
                    </Stack>
                </ScrollArea>
            )}
            {(selected as CustomerType) === CustomerType.Custom || customerProps.length === 0 ? (
                <Input
                    ref={focusRef}
                    id="customer-name-input"
                    disabled={onMdnSave}
                    mt="0px"
                    mb="0px"
                    size="lg"
                    onChange={onInputChange}
                    className="fs-mask"
                    classNames={{ wrapper: classes.styledInputWrapper, input: classes.styledInput }}
                />
            ) : null}
        </>
    );
}
