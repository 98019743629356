import { type VoiceTask } from "../../sdk";
import { PendingCallBar, StandardCallBar } from "./CallDetails";

interface CallBarProps {
    task: VoiceTask;
}

export function CallDetailsSelector({ task }: CallBarProps): JSX.Element {
    if (task.status === "pending" || (task.status === "assigned" && (!task.conferenceId || !task.conferenceStarted))) {
        return <PendingCallBar task={task} />;
    }

    return <StandardCallBar task={task} />;
}
