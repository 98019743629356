import type { GaiaWebSocketResponse } from "@expert/gaia";
import { gaiaWsEventBus } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { useEffect, useMemo } from "react";
import type { ReactiveMessagePayload, ProactiveMessagePayload, GaiaErrorMessage } from "@expert/shared-types";
import { addErrorMessage, handleIncomingMessage } from "@soluto-private/expert-workspace-timeline";
import { triggerProactiveSound, triggerTabNotification } from "@expert/proactive-notifications";
import { useFeatureFlag } from "../../../shared-utils";
import { features } from "../../utils";
import { isProactiveErrorMessage, isProactiveMessage } from "./validators";

export const useGaiaMessageEvents = (loggerProp: Logger) => {
    const { data: isProactiveEnabled = features.isProactiveEnabled } = useFeatureFlag("isProactiveEnabled");
    const logger = useMemo(() => loggerProp.child({ module: "useGaiaMessageEvents" }), [loggerProp]);

    useEffect(() => {
        // Reactive Message Handling
        const gaiaEventBusMessageSuccess = gaiaWsEventBus.on(
            "gaia_ws_orchestrator-invoke-success",
            ({ body }: GaiaWebSocketResponse) => {
                handleIncomingMessage(body as ReactiveMessagePayload, logger);
            },
        );

        // Proactive Message Handling
        const gaiaEventBusProactiveMessageReceived = gaiaWsEventBus.on(
            "gaia_ws_message-suggestion",
            ({ body }: GaiaWebSocketResponse) => {
                //eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                const gaiaSuggestion = body as unknown as ProactiveMessagePayload;

                // All messages are coming through this event (reactive messages + proactive messages)
                if (!isProactiveEnabled || !isProactiveMessage(gaiaSuggestion)) return;

                // TODO: error messages should not return with messageType = proactive
                // working with gaia team to resolve this
                if (isProactiveErrorMessage(gaiaSuggestion)) {
                    logger.error(
                        {
                            originalError: gaiaSuggestion.suggestion.message,
                        },
                        "GAIA websocket proactive error message response",
                    );
                    return;
                }

                handleIncomingMessage(gaiaSuggestion, logger);
                triggerTabNotification("Expert Workspace", isProactiveEnabled);
                triggerProactiveSound(isProactiveEnabled);
            },
        );

        const gaiaEventBusOrchestratorFailure = gaiaWsEventBus.on("gaia_ws_orchestrator-invoke-error", (response) => {
            addErrorMessage("botFailure");
            const { message } = response as unknown as GaiaErrorMessage;
            logger.error(
                {
                    originalError: message,
                },
                "GAIA websocket error message response",
            );
        });

        return () => {
            gaiaEventBusMessageSuccess();
            gaiaEventBusOrchestratorFailure();
            gaiaEventBusProactiveMessageReceived();
        };
    }, [isProactiveEnabled, logger]);
};
