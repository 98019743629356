// TODO: remove it once we map eSim (instead of checking regex)
export function isiPhone14OrNewer(value?: string) {
    if (!value) return false;

    const iPhoneNumberRegEx = "(?:iphone )(\\d+)";
    const result = new RegExp(iPhoneNumberRegEx).exec(value.toLowerCase());
    if (!result?.[1]) return false;

    const iPhoneNumber = Number(result[1]);
    return iPhoneNumber >= 14;
}
