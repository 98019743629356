import { Button, Flex, Group, Skeleton, Text, Textarea } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useCallback } from "react";
import { withPolymorphicClickAnalytics } from "../../analytics";
import { RadioCard } from "../../common-ui";
import type { VoiceTask } from "../../sdk";
import { isVoiceTask, useAgentStore, useMostRecentTask, usePartner } from "../../sdk";
import type { SummaryResolution } from "../store/useSessionSummaryStore";
import { useSessionSummaryStore } from "../store/useSessionSummaryStore";
import { validatePhoneNumber } from "../../shared-utils";
import { getAppConfigSingleton } from "../../config/appConfigSingleton";
import { CustomerConfirmationStatusCard } from "./CustomerConfirmationStatusCard";
import classes from "./EditSessionSummaryModal.module.css";
import { SendSummaryNotification } from "./SendSummaryNotification";
import { getNotificationText, MAX_SUMMARY_LENGTH } from "./utils";

interface SessionSummaryFormProps {
    endSession: (reason: "AgentTerminatedWrapping" | "WrappingTimeExpired") => Promise<void>;
    loading: boolean;
    validAccountNumber: boolean;
    setView: (view: "edit-summary" | "schedule-callback") => void;
}

const CallCustomerButton = withPolymorphicClickAnalytics(Button, "Click");
const SendSummaryButton = withPolymorphicClickAnalytics(Button, "Click");

export function SessionSummaryForm({
    endSession,
    validAccountNumber = false,
    loading,
    setView,
}: SessionSummaryFormProps): JSX.Element | null {
    const appConfig = getAppConfigSingleton();
    const mostRecentTask = useMostRecentTask();
    const { height } = useViewportSize();
    const { pendingActivity } = useAgentStore();
    const partner = usePartner();

    const { setSessionSummary, setResolved, hideResolutionStatusForm, summary, resolutionStatus } =
        useSessionSummaryStore();

    const handleResolution = useCallback(
        (resolution: SummaryResolution) => {
            setResolved(resolution);
        },
        [setResolved],
    );

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setSessionSummary({ value: event.target.value });
        },
        [setSessionSummary],
    );

    async function handleEndSessionClick() {
        await endSession("AgentTerminatedWrapping");
    }

    function handleScheduleCallbackClick() {
        setView("schedule-callback");
    }

    const shouldHideResolutionForm = hideResolutionStatusForm && resolutionStatus !== null;

    const requiresManualCopy = appConfig.partnersConfig[partner].requiresManualCopy;

    const endSessionLabel = validAccountNumber && !requiresManualCopy ? "Send session summary" : "Copy summary";

    return (
        <>
            {isVoiceTask(mostRecentTask) && validatePhoneNumber(mostRecentTask.mdn).valid ? (
                <CustomerConfirmationStatusCard
                    asurionCallId={mostRecentTask.asurionCallId}
                    mdn={mostRecentTask.mdn}
                    reservationSid={
                        (
                            mostRecentTask as VoiceTask & {
                                config?: { reservation?: { sid: string } };
                            }
                        ).config?.reservation?.sid
                    }
                />
            ) : null}
            {!shouldHideResolutionForm && (
                <>
                    <Text size="sm" mb=".5rem">
                        Was the customer’s issue resolved?
                    </Text>
                    <Group mb="1.6rem">
                        <RadioCard
                            id="resolved-radio-button"
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedYes",
                            }}
                            color="var(--mantine-color-dark-5)"
                            label="Yes"
                            onClick={() => handleResolution("resolved")}
                            p="0.25rem 0.75rem"
                            selected={resolutionStatus === "resolved"}
                            data-testid="session-summary-problem-solved-yes"
                        />
                        <RadioCard
                            id="not-resolved-radio-button"
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedNo",
                            }}
                            color="var(--mantine-color-dark-5)"
                            label="No"
                            p="0.25rem 0.75rem"
                            onClick={() => handleResolution("not resolved")}
                            selected={resolutionStatus === "not resolved"}
                            data-testid="session-summary-problem-solved-no"
                        />
                        <RadioCard
                            id="transfer-radio-button"
                            analytics={{
                                actionId: "SessionSummaryModal_ProblemSolvedTransferred",
                            }}
                            p="0.25rem 0.75rem"
                            color="var(--mantine-color-dark-5)"
                            label="Transferred"
                            onClick={() => handleResolution("transferred")}
                            selected={resolutionStatus === "transferred"}
                            data-testid="session-summary-problem-solved-transferred"
                        />
                    </Group>
                </>
            )}
            <Text size="sm" mb=".5rem">
                Notes
            </Text>
            {loading ? (
                <Flex direction="column" align="center" h="237">
                    <Skeleton mb="0.5rem" h="15%" />
                    <Skeleton mb="0.5rem" h="15%" />
                    <Skeleton mb="0.5rem" h="15%" />
                    <Skeleton mb="0.5rem" h="15%" />
                </Flex>
            ) : (
                <Flex className={classes.modalTextareaContainer} direction="column">
                    <>
                        <Textarea
                            className="fs-mask"
                            classNames={{
                                input: classes.modalTextarea,
                            }}
                            maxLength={MAX_SUMMARY_LENGTH}
                            mr="0.125rem"
                            mt="0.25rem"
                            mb="0.3rem"
                            onChange={handleChange}
                            placeholder="Enter session summary here..."
                            rows={height >= 650 ? 7 : 4}
                            value={summary}
                            data-testid="session-summary-text-input"
                        />
                        <SendSummaryNotification
                            validAccountNumber={validAccountNumber}
                            summary={summary}
                            notificationText={getNotificationText(partner, validAccountNumber)}
                        />
                    </>
                </Flex>
            )}
            <Flex id="modal-actions" justify="flex-end" pt="md" gap="xs">
                <CallCustomerButton
                    analytics={{
                        actionId: "SessionSummaryModal_CallCustomerButton",
                    }}
                    color="primary.3"
                    onClick={handleScheduleCallbackClick}
                    variant="outline"
                >
                    <Text fw="lighter" fz="sm">
                        Schedule callback
                    </Text>
                </CallCustomerButton>
                <SendSummaryButton
                    analytics={{
                        actionId: "SessionSummaryModal_SendButton",
                    }}
                    className={classes.sendSummaryButton}
                    aria-label={endSessionLabel}
                    disabled={loading || !summary || resolutionStatus === null}
                    fz="sm"
                    onClick={handleEndSessionClick}
                    data-testid="call-controls-end-session"
                >
                    {pendingActivity === "Offline" ? "End session and log off" : "End session"}
                </SendSummaryButton>
            </Flex>
        </>
    );
}
