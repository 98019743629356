import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface TroubleshootState {
    isTroubleshooting: boolean;
}
interface TroubleshootActions {
    setIsTroubleshooting: (troubleshooting: boolean) => void;
}

type TroubleshootStateStore = TroubleshootState & TroubleshootActions;

const defaultState: TroubleshootState = {
    isTroubleshooting: false,
};

export const useTroubleshootingStore = create<TroubleshootStateStore>()(
    persist(
        devtools((set) => ({
            ...defaultState,

            setIsTroubleshooting: (isTroubleshooting: boolean) =>
                set({ isTroubleshooting }, false, "setIsTroubleshooting"),
            resetState: () => set(defaultState, false, "resetState"),
        })),
        {
            name: "troubleshooting-store",
        },
    ),
);
