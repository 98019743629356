import type { SVGProps } from "react";

export function CircleCashIcon({ height = 24, width = 24, fill, ...rest }: SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            {...rest}
        >
            <g fill={fill} clipPath="url(#a)">
                <path d="M13.068.048c.736.064 1.468.198 2.177.397l-.539 1.926a10.028 10.028 0 0 0-1.813-.331l.175-1.992ZM11.045 2.046 10.857.054c-.736.07-1.468.208-2.176.411l.553 1.922a9.976 9.976 0 0 1 1.81-.34ZM4.592 5.283l-1.48-1.344a12.111 12.111 0 0 0-1.33 1.768l1.701 1.051c.324-.524.697-1.02 1.109-1.475ZM13 19h-2v-2H8v-2h5a1.001 1.001 0 0 0 0-2h-2c-1.654 0-3-1.346-3-3s1.346-3 3-3V5h2v2h3v2h-5a1.001 1.001 0 0 0 0 2h2c1.654 0 3 1.346 3 3s-1.346 3-3 3v2ZM.797 7.69l1.867.72a9.827 9.827 0 0 0-.5 1.773l-1.967-.36c.133-.728.335-1.445.6-2.133ZM0 12.026l2-.005c.002.618.059 1.236.172 1.838l-1.965.369A12.086 12.086 0 0 1 0 12.026ZM20.838 3.884c.5.543.951 1.135 1.342 1.759l-1.696 1.062a10.064 10.064 0 0 0-1.119-1.467l1.473-1.354ZM21.824 10.122l1.965-.374a12.086 12.086 0 0 0-.613-2.126l-1.863.73c.224.57.396 1.166.511 1.77ZM7.518 3.058l-.9-1.787c-.657.332-1.288.727-1.876 1.172l1.21 1.592a10.043 10.043 0 0 1 1.566-.977ZM.816 16.357l1.863-.727c.222.572.5 1.126.825 1.649l-1.697 1.057a11.997 11.997 0 0 1-.991-1.98ZM18.031 19.978l1.207 1.595a12.21 12.21 0 0 0 1.634-1.491l-1.479-1.348c-.414.455-.872.873-1.362 1.244ZM16.426 3.03l.887-1.792c.662.326 1.295.717 1.885 1.16l-1.201 1.6a9.984 9.984 0 0 0-1.571-.967ZM22 12c0 .62-.057 1.236-.168 1.838l1.967.364c.133-.72.201-1.462.201-2.202v-.046L22 12ZM22.206 18.315l-1.7-1.054a9.969 9.969 0 0 0 .823-1.651l1.864.724a11.919 11.919 0 0 1-.987 1.98ZM3.145 20.099a12.02 12.02 0 0 0 1.637 1.488l1.204-1.596c-.49-.37-.95-.789-1.366-1.243l-1.475 1.351ZM8.73 23.549a11.974 11.974 0 0 1-2.066-.798l.89-1.791a9.848 9.848 0 0 0 1.72.664l-.544 1.925ZM14.746 21.62l.548 1.922a11.94 11.94 0 0 0 2.063-.802l-.893-1.789c-.55.275-1.128.5-1.718.668ZM10.907 23.951l.18-1.992a9.985 9.985 0 0 0 1.846-.003l.185 1.993a12.152 12.152 0 0 1-2.211.002Z" />
            </g>
            <defs>
                <clipPath id="a">
                    <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
