import Player from "react-lottie-player/dist/LottiePlayerLight";
import { useHomeProductSessionStore } from "../home-product";
import { SaleProgress } from "../home-product/types";
import animationData from "./animations/Confetti.json";

export function ConfettiAnimation() {
    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);

    return (
        <Player
            animationData={animationData}
            play={homeProduct && homeProduct.saleProgress === SaleProgress.Success}
            speed={0.5}
            loop={false}
        />
    );
}
