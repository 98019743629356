import { useEffect } from "react";
import { useSolveGuideStore, type OpenGuideSource, type CloseGuideSource } from "@expert/solve-tools";
import { useDeviceActivationStore } from "../store";
import { type Session, type usePartner } from "../../sdk";
import { sdkEventBus } from "../../sdk/agent/eventBus";
import { isActivationCall } from "./isActivationCall";

interface UseAutomaticActivationGuide {
    isActivationGuideEnabled: boolean;
    isPhase1ConversationalIVR: boolean;
    isCustomerSession: boolean;
    partner: ReturnType<typeof usePartner>;
    supportNumberCalled?: string;
    isCustomerVerified: boolean;
    readonly isGuideOpen: boolean;
    readonly openGuide: (source: OpenGuideSource) => void;
    readonly closeGuide: (source: CloseGuideSource, previousSession?: Session) => void;
}

export const useAutomaticActivationGuide = ({
    isActivationGuideEnabled,
    isPhase1ConversationalIVR,
    isCustomerSession,
    isCustomerVerified,
    partner,
    supportNumberCalled,
    isGuideOpen,
    openGuide,
    closeGuide,
}: UseAutomaticActivationGuide) => {
    const { disableAutomaticOpen } = useSolveGuideStore();
    const {
        includesDetails: { includesDeviceReplacementDetails, includesIVRDetails },
    } = useDeviceActivationStore();

    const isActivationSupportNumberCalled = isActivationCall(partner, supportNumberCalled);

    useEffect(() => {
        const sessionChangedEvent = sdkEventBus.on("session_changed", ({ previousSession }) => {
            if (isGuideOpen) closeGuide("auto", previousSession);

            // reset stores _after_ closing guide to ensure CloseGuide event has expected values
            useDeviceActivationStore.getState().reset();
            useSolveGuideStore.getState().reset();
        });
        return () => {
            sessionChangedEvent();
        };
    }, [closeGuide, isGuideOpen]);

    useEffect(() => {
        if (!isActivationGuideEnabled) return;
        if (!isCustomerSession) return;
        if (disableAutomaticOpen) return;

        if (!isCustomerVerified && !isPhase1ConversationalIVR) return;
        if (isPhase1ConversationalIVR && !includesIVRDetails) return;
        if (!includesIVRDetails && !isActivationSupportNumberCalled && !includesDeviceReplacementDetails) return;

        if (!isGuideOpen) openGuide("auto");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        disableAutomaticOpen,
        isActivationGuideEnabled,
        isPhase1ConversationalIVR,
        isCustomerSession,
        isCustomerVerified,
        isActivationSupportNumberCalled,
        includesDeviceReplacementDetails,
        includesIVRDetails,
    ]);
};
