import type { ContentfulClientApi } from "contentful";
import { hashId } from "../crypto/hashId";

/*
 * Takes in a sessionID to deterministically fetch an avatar.
 * As long as the sessionID persists on refresh, the same avatar will be tied to the same customer
 */
export const getAvatar = async (
    contentfulClient: ContentfulClientApi<undefined>,
    sessionId: string,
): Promise<string> => {
    const assets = await contentfulClient.getAssets({ "metadata.tags.sys.id[in]": ["exWoAvatar"] });
    const hashed = await hashId(sessionId, assets.total);
    return assets.items[hashed - 1].fields.file?.url ?? "/assets/avatar-1.webp";
};
