import { Button, Chip, Divider, Flex, Group, Text } from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { ListeningAnimation } from "../home-product/components/Icons";
import { useAnalytics } from "../../analytics";
import { isVoiceTask, useMostRecentTask } from "../../sdk";
import { useGaiaMessageStore } from "../home-product";
import classes from "./DetectedDevicesInfo.module.css";
import { DeviceMoneyIcon } from "./Icons/DeviceMoneyIcon";
import { DeviceCheckMark } from "./Icons/DeviceCheckMark";

export interface ProductData {
    productType: string;
    detected: boolean;
    isDetectedOnPreviousCall: boolean;
    price: number;
}

interface DetectedDevicesInfoProps {
    previousDevices: string[] | undefined;
}

export function DetectedDevicesInfo({ previousDevices }: DetectedDevicesInfoProps): JSX.Element | null {
    const { dispatcher } = useAnalytics();
    const [defaultItemLimit, setDefaultItemLimit] = useState<number>(4);
    const [itemLimit, setItemLimit] = useState<number | undefined>(defaultItemLimit);
    const deviceEvents = useGaiaMessageStore((state) => state.devicesEvents);
    const updatePreviousDetectedDevices = (previousDevicesData: string[] | undefined, deviceData: ProductData[]) => {
        const prevDevices = previousDevicesData?.map((device) => device.toLowerCase());
        if (prevDevices && prevDevices.length > 0) {
            return deviceData.map((item) => {
                if (prevDevices.includes(item.productType.toLowerCase())) {
                    return {
                        ...item,
                        isDetectedOnPreviousCall: true,
                        detected: true,
                    };
                }
                return item;
            });
        }
        return deviceData;
    };
    const [deviceDetectionData, setDeviceDetectionData] = useState<ProductData[]>(
        updatePreviousDetectedDevices(previousDevices, [
            { productType: "Tablet", detected: false, price: 301, isDetectedOnPreviousCall: false },
            { productType: "TV", detected: false, price: 480, isDetectedOnPreviousCall: false },
            { productType: "Laptop", detected: false, price: 594, isDetectedOnPreviousCall: false },
            { productType: "Gaming system", detected: false, price: 414, isDetectedOnPreviousCall: false },
            { productType: "Smart watch", detected: false, price: 264, isDetectedOnPreviousCall: false },
            { productType: "Bluetooth speaker", detected: false, price: 102, isDetectedOnPreviousCall: false },
            { productType: "Headphones", detected: false, price: 200, isDetectedOnPreviousCall: false },
            { productType: "Security camera", detected: false, price: 221, isDetectedOnPreviousCall: false },
            { productType: "Printer", detected: false, price: 138, isDetectedOnPreviousCall: false },
            { productType: "Streaming device", detected: false, price: 88, isDetectedOnPreviousCall: false },
            { productType: "Computer", detected: false, price: 761, isDetectedOnPreviousCall: false },
            { productType: "Keyboard", detected: false, price: 50, isDetectedOnPreviousCall: false },
            { productType: "Router", detected: false, price: 150, isDetectedOnPreviousCall: false },
            { productType: "Modem", detected: false, price: 150, isDetectedOnPreviousCall: false },
            { productType: "Computer Monitor", detected: false, price: 200, isDetectedOnPreviousCall: false },
        ]),
    );

    const activeTask = useMostRecentTask();
    if (activeTask && !isVoiceTask(activeTask)) {
        throw new Error("Unsupported task channel. Device Info should not be rendered without a voice task");
    }

    const sendDeviceDetectionMessage = (action: string, device: string) => {
        void dispatcher.dispatchBusinessEvent("Device", {
            device,
            action,
        });
    };

    const calculateTotalValueOfDevices = () => {
        let totalValue = 0;
        let totalNumberOfDetectedDevices = 0;
        deviceDetectionData.forEach((device) => {
            if (device.detected && typeof device.price === "number") {
                totalValue += device.price;
                totalNumberOfDetectedDevices += 1;
            }
        });
        if (totalNumberOfDetectedDevices >= 4) {
            if (itemLimit !== undefined) setItemLimit(totalNumberOfDetectedDevices);
            setDefaultItemLimit(totalNumberOfDetectedDevices);
        }
        return totalValue;
    };

    const handleListeningText = () => {
        const hasPreviousDetections = previousDevices && previousDevices.length > 0;
        const hasCurrentDetections = deviceDetectionData.some(
            (device) => device.detected && !device.isDetectedOnPreviousCall,
        );

        let listeningText = "Listening for devices...";

        if (hasPreviousDetections && hasCurrentDetections) {
            listeningText = "Detected from previous and current call...";
        } else if (hasCurrentDetections) {
            listeningText = "Detected from current call...";
        } else if (hasPreviousDetections) {
            listeningText = "Detected from previous call...";
        }

        return listeningText;
    };

    const totalValueOfDevices = useMemo(calculateTotalValueOfDevices, [deviceDetectionData, itemLimit]);
    const deviceListeningText = useMemo(handleListeningText, [previousDevices, deviceDetectionData]);

    useEffect(() => {
        if (deviceEvents.length <= 0) return;
        const updatedDetectionData = deviceDetectionData.map((device) => {
            const located = deviceEvents.find(
                (item) =>
                    item.name.toLowerCase() === device.productType ||
                    item.name.toLowerCase().includes(device.productType.toLowerCase()),
            );
            if (located && !device.isDetectedOnPreviousCall) {
                device.detected = true;
            }
            return device;
        });
        setDeviceDetectionData(updatedDetectionData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceEvents]);

    return (
        <Flex id="detected-devices-container" className={classes.text} direction="column">
            <Divider size="sm" color="dark.4" />
            <Flex mt="sm" align="center">
                <ListeningAnimation />
                <Text size="0.75rem" c="dark.2" ml="0.75rem">
                    {deviceListeningText}
                </Text>
            </Flex>
            <Flex mt="0.75rem" className={classes.chipContainer} direction="row">
                <Chip.Group
                    value={deviceDetectionData.filter((d) => d.detected).map((device) => device.productType)}
                    multiple
                >
                    <Group gap={5}>
                        {deviceDetectionData
                            .sort((a, b) => {
                                if (a.isDetectedOnPreviousCall && !b.isDetectedOnPreviousCall) {
                                    return -1;
                                }
                                if (!a.isDetectedOnPreviousCall && b.isDetectedOnPreviousCall) {
                                    return 1;
                                }

                                if (a.detected && !b.detected) {
                                    return -1;
                                }
                                if (!a.detected && b.detected) {
                                    return 1;
                                }

                                return 0;
                            })
                            .slice(0, itemLimit)
                            .map((entry) => (
                                <Chip
                                    id={`DeviceChip-${entry.productType}`}
                                    onClick={() => {
                                        if (entry.isDetectedOnPreviousCall) return;
                                        if (entry.detected) {
                                            void dispatcher.dispatchBusinessEvent("DeviceChipClicked", {
                                                device: entry.productType,
                                                selectionAction: entry.detected,
                                            });
                                        }
                                        const updatedDeviceDetection = deviceDetectionData.map((item) => {
                                            if (item.productType === entry.productType) {
                                                if (item.detected) {
                                                    sendDeviceDetectionMessage("remove", item.productType);
                                                } else sendDeviceDetectionMessage("add", item.productType);
                                                return {
                                                    ...item,
                                                    detected: !item.detected,
                                                };
                                            }
                                            return item;
                                        });

                                        setDeviceDetectionData(updatedDeviceDetection);
                                    }}
                                    icon={
                                        entry.isDetectedOnPreviousCall ? (
                                            <DeviceCheckMark fill="var(--mantine-color-dark-1)" />
                                        ) : (
                                            <DeviceCheckMark fill="var(--mantine-color-grey-9)" />
                                        )
                                    }
                                    variant="transparent"
                                    size="xs"
                                    key={entry.productType}
                                    value={entry.productType}
                                    classNames={{ label: classes.chip }}
                                    disabled={entry.isDetectedOnPreviousCall}
                                >
                                    {entry.productType}
                                </Chip>
                            ))}
                        <Button
                            styles={{
                                root: {
                                    paddingLeft: 6,
                                    paddingRight: 6,
                                    height: 22,
                                },
                            }}
                            variant="filled"
                            color="dark.4"
                            className={itemLimit ? classes.chipButton : classes.showLessButton}
                            bg="dark.5"
                            mt="0.25rem"
                            onClick={() => {
                                void dispatcher.dispatchBusinessEvent("ShowMoreDeviceChipsClicked");
                                setItemLimit(itemLimit ? undefined : defaultItemLimit);
                            }}
                        >
                            {itemLimit ? "+" : "^"}
                        </Button>
                    </Group>
                </Chip.Group>
            </Flex>
            {totalValueOfDevices > 0 ? (
                <Flex mt="sm" align="center">
                    <DeviceMoneyIcon />
                    <Text size="0.875rem" ml="0.75rem">
                        ${totalValueOfDevices}+ in devices
                    </Text>
                </Flex>
            ) : null}
        </Flex>
    );
}
