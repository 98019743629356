import { Flex, Text } from "@mantine/core";
import { useVerifiedCustomerStore } from "../../../customer-info";
import { ShieldIcon } from "./ShieldIcon";
import { getDurationText } from "./utils";

export function CustomerPlanInfo(): JSX.Element {
    const customer = useVerifiedCustomerStore((state) => state.customer);

    let lengthOfTime;
    if (customer?.purchaseDate) {
        lengthOfTime = getDurationText(customer.purchaseDate, new Date());
    }
    return (
        <Flex gap="4px" w="max-content">
            <Text c="dark.2" size="sm" pr="4px">
                {`Mobile protection for ${lengthOfTime}`}
            </Text>
            <ShieldIcon />
        </Flex>
    );
}
