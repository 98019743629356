import type { SVGProps } from "react";

export function CaretDown({ height = 8, width = 12, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" {...rest}>
            <path
                fill={fill ?? "#A839F3"}
                clipRule="evenodd"
                d="m6 7.414 5.707-5.707L10.293.293 6 4.586 1.707.293.293 1.707 6 7.414Z"
                fillRule="evenodd"
            />
        </svg>
    );
}
