import { Flex, ScrollArea } from "@mantine/core";
import { useState } from "react";
import { useFeatureFlag } from "../../../../shared-utils";
import { useHomeProductSessionStore } from "../../stores";
import { TextLegalDisclosure } from "../../../disclosures/views/TextLegalDisclosure";
import { RecordedLegalDisclosures } from "../../../disclosures/views/RecordedLegalDisclosures";
import styles from "./StepThreeContent.module.css";
import { EnrollSection } from "./EnrollSection";

export function StepThreeContent(): JSX.Element | null {
    const [disclosureChecked, setDisclosureChecked] = useState(false);
    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);
    const { data: isRecordedAudioDisclosureEnabled, isLoading: isLoadingRecordedAudioDisclosureEnabled } =
        useFeatureFlag("EWP-isRecordedAudioDisclosureEnabled");
    if (!homeProduct || isLoadingRecordedAudioDisclosureEnabled) return null;

    if (isRecordedAudioDisclosureEnabled)
        return (
            <>
                <RecordedLegalDisclosures product={homeProduct} />
                <EnrollSection
                    disclosureChecked={disclosureChecked}
                    isEnrollButtonEnabled={false}
                    product={homeProduct}
                />
            </>
        );

    return (
        <Flex w="100%" direction="column">
            <>
                <ScrollArea className={styles.scrollArea}>
                    <TextLegalDisclosure
                        productSku={homeProduct.productSku}
                        isDisclosureChecked={disclosureChecked}
                        onDisclosureCheckedUpdated={(newStatus) => {
                            setDisclosureChecked(newStatus);
                        }}
                    />
                </ScrollArea>
                <EnrollSection disclosureChecked={disclosureChecked} product={homeProduct} />
            </>
        </Flex>
    );
}
