import { useWindowEvent } from "@mantine/hooks";
import { useEffect, useReducer, useRef } from "react";

export const sequence = Object.freeze([
    "ArrowUp",
    "ArrowUp",
    "ArrowDown",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "ArrowLeft",
    "ArrowRight",
    "b",
    "a",
] as const);

function reducer(prevState: { indexes: readonly [0, ...number[]] }, action: { key: string }): typeof prevState {
    const indexes = prevState.indexes.filter((index) => action.key === sequence[index]).map((index) => index + 1);
    return { indexes: [0, ...indexes] };
}

/**
 * Calls {@link callback} whenever the user enters the [Konami Code](https://en.wikipedia.org/wiki/Konami_Code) using
 * keyboard input.
 *
 * Note: This is not exposed as a hook because doing so causes the consuming component and its children to re-render
 * each time a key is pressed.
 */
export default function KonamiCode({ callback }: { callback: () => void }) {
    const [{ indexes }, dispatch] = useReducer(reducer, { indexes: [0] });

    // use and update a ref to `callback` so that the following useEffect doesn't run each time it changes
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    useEffect(() => {
        for (const index of indexes) {
            if (index < sequence.length) continue;
            callbackRef.current();
        }
    }, [indexes]);

    useWindowEvent("keydown", dispatch);

    return null;
}
