import { Button, Checkbox, Group, Modal, Stack } from "@mantine/core";
import { useState } from "react";
import { truncateAnalyticsText, useReactAnalytics } from "@soluto-private/eventualize-react";

interface GuideCloseConfirmationProps {
    isOpen: boolean;
    guideId: string;
    title: string;
    options: string[];
    onClose: () => void;
    onCancel: () => void;
}

function buttonCssVarOverrides() {
    return () => {
        return {
            root: {
                "--button-bg": "var(--mantine-color-primary-6)",
                "--button-hover": "var(--mantine-color-primary-7)",
            },
        };
    };
}
export function GuideCloseConfirmation({
    isOpen,
    guideId,
    title,
    options,
    onClose,
    onCancel,
}: GuideCloseConfirmationProps) {
    const { dispatcher } = useReactAnalytics();

    const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<string[]>([]);

    const handleSubmit = () => {
        try {
            void dispatcher.dispatchBusinessEvent(`Click_Guide_SubmitAndCloseGuide`, {
                button: "SubmitFeedbackAndCloseGuide",
                guideId,
                feedbackReasons: selectedFeedbackOptions,
            });
            void dispatcher.dispatchBusinessEvent("SubmitFeedback", {
                feedbackCategory: `Solve Guide - ${guideId}`,
                feedbackText: truncateAnalyticsText(
                    `Closed guide for the following reason(s): ${selectedFeedbackOptions.join(", ")}`,
                ),
            });
            onClose();
        } catch (error) {
            onCancel();
        }
    };

    const handleCancel = () => {
        void dispatcher.dispatchBusinessEvent(`Click_Guide_CancelCloseGuide`, { button: "CancelCloseGuide" });
        onCancel();
    };

    const handleClose = () => {
        void dispatcher.dispatchBusinessEvent(`Click_Guide_ConfirmCloseGuide`, { button: "ConfirmCloseGuide" });
        onClose();
    };

    return (
        <Modal.Root
            opened={isOpen}
            onClose={() => {
                /* onClose is handled through the close/cancel/submit buttons below */
            }}
            zIndex="var(--mantine-priority-highest)"
            centered
        >
            <Modal.Content>
                <Modal.Header styles={{ header: { alignItems: "unset" } }}>
                    <Modal.Title p="1rem" pb="0rem">
                        {title}
                    </Modal.Title>
                    <Modal.CloseButton onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <Stack gap="1.25rem">
                        <Stack gap="1.25rem">
                            <Checkbox.Group value={selectedFeedbackOptions} onChange={setSelectedFeedbackOptions}>
                                {options.map((option) => (
                                    <Checkbox value={option} label={option} key={option} p="0.5rem" />
                                ))}
                            </Checkbox.Group>
                        </Stack>
                        <Group justify="flex-end">
                            <Button
                                bg="transparent"
                                color="dark.1"
                                data-testid="cancel-feedback-button"
                                onClick={handleCancel}
                                size="md"
                                td="underline"
                                variant="subtle"
                            >
                                Cancel
                            </Button>
                            <Button
                                size="md"
                                onClick={handleSubmit}
                                data-testid="submit-and-close-button"
                                variant="filled"
                                vars={buttonCssVarOverrides()}
                            >
                                Submit and close
                            </Button>
                        </Group>
                    </Stack>
                </Modal.Body>
            </Modal.Content>
        </Modal.Root>
    );
}
