import { Dialog, Group, Overlay, Text } from "@mantine/core";
import { useElementSize, useLocalStorage, useViewportSize } from "@mantine/hooks";
import { forwardRef, useRef, useState } from "react";
import Draggable from "react-draggable";

interface AgentSdkContainerProps {
    name: string;
    opened: boolean;
    onClose: VoidFunction;
}

/** A draggable container for working with Agent SDK HTML containers (e.g. amazon-connect-streams). */
export default forwardRef<HTMLDivElement, AgentSdkContainerProps>(function AgentSdkContainer(
    { name, opened, onClose },
    ref,
) {
    const nodeRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [positionOffset, setPositionOffset] = useLocalStorage<{ x: number; y: number }>({
        key: `agent-sdk-container-position-${name}`,
        defaultValue: { x: 0.125, y: 0.5 },
    });
    const elementSize = useElementSize();
    const viewportSize = useViewportSize();
    const width = viewportSize.width - elementSize.width;
    const height = viewportSize.height - elementSize.height;

    return (
        <Dialog
            opened={opened}
            withCloseButton
            keepMounted
            onClose={onClose}
            position={{ top: 0, left: 0 }}
            ref={elementSize.ref}
            renderRoot={(props) => (
                <Draggable
                    nodeRef={nodeRef}
                    bounds="body"
                    handle=".handle"
                    position={{ x: positionOffset.x * width, y: positionOffset.y * height }}
                    onStart={() => setIsDragging(true)}
                    onDrag={(_e, { x, y }) => setPositionOffset({ x: x / width, y: y / height })}
                    onStop={() => setIsDragging(false)}
                >
                    <div {...props} ref={nodeRef} />
                </Draggable>
            )}
            zIndex="var(--mantine-priority-highest)"
            portalProps={{ style: { pointerEvents: "none" } }}
            style={{ pointerEvents: "auto" }}
        >
            <Text className="handle" size="sm" mb="xs" fw={500} style={{ cursor: "move" }}>
                {name}
            </Text>
            <Group align="flex-end" h="50vh" ref={ref} />
            {/* render a transparent overlay while dragging to avoid mouse movements being captured by the iframe and interfering with dragging */}
            {isDragging ? <Overlay color="#000" backgroundOpacity={0} /> : null}
        </Dialog>
    );
});
