import { type SVGProps } from "react";

export function RestartIcon({ height = 25, width = 25, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                d="M4.14771 18.7018L4.1477 5.79759"
                stroke="var(--mantine-color-dark-0)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6.60303 12.2498H20.0789"
                stroke="var(--mantine-color-dark-0)"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.4073 5.20996L5.86012 11.6866C5.69868 11.829 5.6062 12.0339 5.6062 12.2491C5.6062 12.4644 5.69868 12.6692 5.86012 12.8116L13.4073 19.3845"
                stroke="var(--mantine-color-dark-0)"
                strokeWidth="1.35"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
