import { Stack } from "@mantine/core";
import { useMemo } from "react";
import { useShallow } from "zustand/react/shallow";
import { usePartnerFeatureConfigured } from "../../partner/usePartnerConfig";
import { useAgentStore, useCallbackState, type VoiceTask } from "../../sdk";
import { classNames, isNullish } from "../../shared-utils";
import { CallControls } from "./CallControls";
import classes from "./CallControlsManager.module.css";
import { CallbackCallBar } from "./CallDetails";
import { ConferencePanel } from "./ConferencePanel";
import { useControlsStore } from "./controls.store";
import { Dialpad } from "./Dialpad";
import { IdleNoCallControls } from "./NoCallControls";
import { WhisperPill } from "./utility-components";
import { WrappingCallControls } from "./WrappingCallControls";

export function CallControlsManager({ voiceTask }: { voiceTask: VoiceTask | null }): JSX.Element {
    const callbackState = useCallbackState();
    const { isConference, conferencePanelOpen, dialpadOpen } = useControlsStore();
    const isOutboundEnabled = usePartnerFeatureConfigured("outboundCall");
    const agentActivity = useAgentStore(useShallow((state) => state.activity));

    const hasCallbackWaiting = useMemo(() => {
        return (
            !isNullish(callbackState?.callbackDelay) &&
            callbackState.scheduledFor &&
            callbackState.callbackType === "CallbackNow"
        );
    }, [callbackState]);

    const noActiveCall = !voiceTask && !hasCallbackWaiting;
    const inWrapping = agentActivity === "Wrapping" || voiceTask?.status === "wrapping";
    const activeConference = voiceTask && isConference && conferencePanelOpen;

    return (
        <Stack className={classes.controlsContainer}>
            {voiceTask && voiceTask.whisper ? <WhisperPill whisper={voiceTask.whisper} /> : null}
            <Stack
                className={classNames(
                    noActiveCall && !isOutboundEnabled && !inWrapping ? null : classes.controlsWrapper,
                    noActiveCall && isOutboundEnabled ? classes.controlsOffCall : null,
                    !noActiveCall ? classes.controlsOnCall : null,
                    activeConference ? classes.noBottomLeftRadius : null,
                )}
                data-testid="controls-wrapper"
            >
                {voiceTask ? <CallControls voiceTask={voiceTask} /> : null}
                {isOutboundEnabled && noActiveCall && !inWrapping ? <IdleNoCallControls /> : null}
                {!voiceTask && agentActivity === "Callback Pending" && hasCallbackWaiting ? <CallbackCallBar /> : null}
                {noActiveCall && inWrapping ? <WrappingCallControls /> : null}
            </Stack>
            <Stack className={classes.dropdownControlsWrapper}>
                {dialpadOpen ? <Dialpad /> : null}
                {activeConference ? <ConferencePanel /> : null}
            </Stack>
        </Stack>
    );
}
