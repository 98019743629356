import { devtools, persist } from "zustand/middleware";
import { createWithEqualityFn } from "zustand/traditional";
import type { Customer, DeviceReplacement } from "../api";

export interface CustomerState {
    customer?: Customer;
    deviceReplacement?: DeviceReplacement;
    setCustomer: (customer?: Partial<Customer & { mdn: string }>, deviceReplacement?: DeviceReplacement) => void;
    resetCustomer: () => void;
    reset: () => void;
}

export const useVerifiedCustomerStore = createWithEqualityFn<CustomerState>()(
    persist(
        devtools(
            (set) => ({
                customer: undefined,
                deviceReplacement: undefined,
                setCustomer: (customer?: Partial<Customer & { mdn: string }>, deviceReplacement?: DeviceReplacement) =>
                    set({ customer, deviceReplacement }, false, "setCustomer"),
                resetCustomer: () => set({ deviceReplacement: undefined, customer: undefined }, false, "resetCustomer"),
                reset: () => set({ deviceReplacement: undefined, customer: undefined }, false, "reset"),
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "verified-customer-store",
                name: "workspace/customer-info",
            },
        ),
        { name: "verified-customer-store", skipHydration: true },
    ),
);
