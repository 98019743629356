import { createContext, useEffect, useState } from "react";
import { createAgentSDK, type CreateSdkParams } from "../../../utils/createAgentSdk";
import { updateDispatcherPartner } from "../../../analytics";
import type { AgentSdk } from "../agentSdk";
import { type ServiceProvider } from "../../../shared-types/serviceProvider";
import { type OrchestratorAgentSdk } from "../orchestrator/orchestratorAgentSdk";
import { EvlFailedToConnectError } from "../../errorTypes";

export const AgentSdkContext = createContext<AgentSdk | undefined>(undefined);

export type AgentSdkProviderCreator = (params: CreateSdkParams) => Promise<AgentSdk>;

export interface AgentSdkLoaderProps {
    token: string | null;
    employeeNum: string | null;
    hdxEnabled: boolean;
    useSmartEVL: boolean;
    userFriendlyName?: string;
    serviceProviders: ServiceProvider[];
    createContainer: (name: string) => Promise<HTMLElement>;
}

export type AgentSdkFailure = "none" | "general" | "evl-failed";

export interface AgentSdkLoaderResult {
    failureType: AgentSdkFailure;
    errorMessage?: string;
    loadingMessage?: string;
    sdk?: AgentSdk;
}

export function useAgentSdkLoader({
    token,
    employeeNum,
    hdxEnabled,
    useSmartEVL,
    userFriendlyName,
    serviceProviders,
    createContainer,
}: AgentSdkLoaderProps): AgentSdkLoaderResult {
    const [isReady, setIsReady] = useState(false);
    const [errorInitializingMsg, setErrorInitializingMsg] = useState<string | undefined>(undefined);
    const [agentSdk, setAgentSdk] = useState<AgentSdk | undefined>(undefined);
    const [failureType, setFailureType] = useState<AgentSdkFailure>("none");

    useEffect(() => {
        const fetchAgentSdk = async (): Promise<{
            sdk?: OrchestratorAgentSdk;
            errorInitializing?: string;
            failureType: AgentSdkFailure;
        }> => {
            if (!token) throw new Error("missing required token");

            try {
                const sdkRes = await createAgentSDK({
                    createContainer,
                    employeeNum,
                    token,
                    hdxEnabled,
                    useSmartEVL,
                    userFriendlyName,
                    serviceProviders,
                });

                return { sdk: sdkRes, failureType: "none" };
            } catch (ex) {
                if (ex instanceof EvlFailedToConnectError) {
                    return { errorInitializing: ex.message, failureType: "evl-failed" };
                }

                return {
                    errorInitializing: ex instanceof Error ? ex.message : JSON.stringify(ex),
                    failureType: "general",
                };
            }
        };

        let ignore = false;
        if (!agentSdk) {
            setIsReady(false);

            fetchAgentSdk()
                .then((res) => {
                    if (!ignore) {
                        if (res.sdk) {
                            setAgentSdk(res.sdk);
                            setFailureType(res.failureType);
                            setIsReady(true);
                        } else if (res.errorInitializing) {
                            setFailureType(res.failureType);
                            setErrorInitializingMsg(res.errorInitializing);
                        }
                    }
                })
                .catch((ex: unknown) => {
                    if (!ignore) {
                        setErrorInitializingMsg(ex instanceof Error ? ex.message : JSON.stringify(ex));
                    }
                });
        }

        return () => {
            ignore = true;
        };
    }, [agentSdk, hdxEnabled, employeeNum, token, userFriendlyName, serviceProviders, createContainer, useSmartEVL]);

    useEffect(() => {
        if (!agentSdk || !isReady || !!errorInitializingMsg) {
            return;
        }

        const [partner] = agentSdk.getPartners();
        updateDispatcherPartner(partner);
    }, [agentSdk, isReady, errorInitializingMsg]);

    // NOTE: This error message can be set via an async callback in the constructor. That way
    //       if any errors are thrown during an async initialization, we can catch them and display
    //       the message here
    if (failureType !== "none") {
        return { errorMessage: errorInitializingMsg, failureType };
    }

    if (!agentSdk || !isReady) {
        const message = hdxEnabled
            ? "Getting things ready... Checking whether you are in a Citrix environment"
            : "Getting things ready... make sure you’re connected to EVL.";
        return { loadingMessage: message, failureType: "none" };
    }

    return { sdk: agentSdk, failureType: "none" };
}
