import { useQuery } from "@tanstack/react-query";
import { getCurrentSalesLeaderboard } from "../api/getCurrentSalesLeaderboard";

export const useSalesLeaderboard = (isEnabled = true) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [`sales-leaderboard`],
        queryFn: async () => getCurrentSalesLeaderboard(),
        retry: 0,
        gcTime: 0,
        enabled: isEnabled,
    });
    return { data, isLoading, isError };
};
