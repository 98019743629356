import { getLogger } from "@expert/logging";
import { getRootDispatcher } from "../../../analytics";
import type { EligibleProduct } from "../stores";
import { type EligibilityRequestV2 } from "../types";
import { getSessionId } from "../../../sdk";
import { getSalesApiClient } from "./api";

const logger = getLogger({
    module: "eligibility",
    supportTeam: "sales",
});

export const getEligibilityV2 = async (eligibility: EligibilityRequestV2): Promise<EligibleProduct[]> => {
    if (!eligibility.customerMdn) return [];
    const childLogger = logger.child({ sessionId: getSessionId() });
    try {
        const salesApi = getSalesApiClient();

        const eligibleProducts = await salesApi
            .post("v2/eligibility", {
                json: eligibility,
                retry: 0, // retries already done via @tanstack/react-query
            })
            .json<EligibleProduct[]>();

        eligibleProducts.forEach((result) => {
            let dispatcher = getRootDispatcher().withExtra({
                productSku: result.productSku,
                productOfferId: result.id,
                eligibilityStatus: result.status,
            });

            if (eligibility.reservationSid) {
                dispatcher = dispatcher.withIdentity("ReservationSid", eligibility.reservationSid);
            }

            void dispatcher.dispatchBusinessEvent("CustomerValidated");
            void dispatcher.dispatchAction("ApiResult", "EligibilityStatus");
        });
        return eligibleProducts;
    } catch (err: unknown) {
        childLogger.error({ err }, `eligibility API call failed`);
        throw err;
    }
};
