export function WrenchIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.8327 7.49966C15.8327 5.03633 14.2702 2.82799 11.9443 2.00549L10.8327 1.61133V6.66633C10.8327 7.12716 10.4585 7.49966 9.99935 7.49966C9.54018 7.49966 9.16602 7.12716 9.16602 6.66633V1.61133L8.05435 2.00549C5.72852 2.82799 4.16602 5.03633 4.16602 7.49966C4.16602 9.82133 5.53185 11.823 7.49935 12.7622V18.333H9.16602V13.333H10.8327V18.333H12.4993V12.7622C14.4668 11.823 15.8327 9.82049 15.8327 7.49966ZM9.99935 11.6663C7.70185 11.6663 5.83268 9.79716 5.83268 7.49966C5.83268 6.17049 6.47102 4.94549 7.49935 4.17216V6.66633C7.49935 8.04466 8.62102 9.16633 9.99935 9.16633C11.3777 9.16633 12.4993 8.04466 12.4993 6.66633V4.17133C13.5277 4.94466 14.166 6.16966 14.166 7.49966C14.166 9.79633 12.2968 11.6663 9.99935 11.6663Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
