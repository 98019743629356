import type { BaseNotificationType, CelebrationNotificationType } from "../models";
import { CelebrationNotification } from "../CelebrationNotification/CelebrationNotification";

export const buildRightNotifications = (payload: BaseNotificationType, onClose: () => void) => {
    switch (payload.type) {
        case "Celebration":
            return (
                <CelebrationNotification
                    onClose={onClose}
                    key={payload.id}
                    {...(payload as CelebrationNotificationType).props}
                />
            );
        default:
            return undefined;
    }
};
