import type { QueueTrafficInfo } from "../models";
import { getSessionId } from "../sdk";
import { api } from "../shared-utils";

export const updateQueueVolume = async (updatedQueues: QueueTrafficInfo[]): Promise<unknown> => {
    return api.post(`api/traffic-control/v1/queues`, {
        body: JSON.stringify(updatedQueues),
        headers: {
            "x-ewp-correlation-id": getSessionId(),
        },
    });
};
