import { type SVGProps } from "react";

export function CancelCall(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" {...props}>
            <path
                stroke="#FFF5F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M5.995 7.675a1.333 1.333 0 0 0 0-1.886L4.579 4.375a1.333 1.333 0 0 0-1.885 0l-.775.776a2 2 0 0 0-.252 2.516 31.005 31.005 0 0 0 8.666 8.666 2 2 0 0 0 2.517-.252l.776-.776c.52-.52.52-1.364 0-1.885l-1.415-1.413a1.333 1.333 0 0 0-1.886 0l-.471.47a31.629 31.629 0 0 1-4.333-4.332l.474-.47Z"
                clipRule="evenodd"
            />
            <path
                stroke="#FFF5F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12.665 9.334a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                clipRule="evenodd"
            />
            <path
                stroke="#FFF5F5"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M14.079 3.92 11.25 6.748M14.079 6.748 11.25 3.92"
            />
        </svg>
    );
}
