import type { Task } from "../task";
import type { VoiceTask } from "./voiceTask";

export function isVoiceTask(task?: Task): task is VoiceTask {
    return !!task && task.type === "voice";
}

export function assertVoiceTask(task: Task, message = "task is not a voice task"): asserts task is VoiceTask {
    if (isVoiceTask(task)) return;
    throw new Error(message);
}

export function isOutboundVoiceTask(task?: Task): task is VoiceTask {
    if (!isVoiceTask(task)) return false;

    return task.callDirection === "outbound";
}
