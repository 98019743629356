import type { Logger } from "@expert/logging";
import { useGaiaAutoCompleteEvents } from "./useGaiaAutoCompleteEvents";
import { useGaiaMessageEvents } from "./useGaiaMessageEvents";
import { useSdkEventBus } from "./useSdkEventBus";
import { useSolveSession } from "./useSolveSession";

export const useSetupSubscriptions = (logger: Logger) => {
    useGaiaMessageEvents(logger);
    useGaiaAutoCompleteEvents(logger);
    useSolveSession(logger);
    useSdkEventBus(logger);
};
