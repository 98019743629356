import { IconButton, Plus } from "../../../common-ui";
import { useControlsStore } from "../controls.store";

interface TransferButtonProps {
    disabled?: boolean;
}

export function ToggleTransferPanelButton({ disabled = false, ...props }: TransferButtonProps): JSX.Element {
    const { toggleTransferActive, transferCallActive } = useControlsStore();
    const tooltipLabel = !disabled ? "Add and transfer" : "Max participants reached";

    return (
        <IconButton
            active={transferCallActive}
            analytics={{
                actionId: "TransferCallButton",
            }}
            data-testid="call-controls-transfer"
            disabled={disabled}
            onClick={toggleTransferActive}
            radius="xl"
            size="35"
            aria-label="Add and transfer participants"
            tooltip={tooltipLabel}
            {...props}
        >
            <Plus
                height={17}
                stroke={transferCallActive ? "var(--mantine-color-dark-7)" : "var(--mantine-color-gray-0)"}
            />
        </IconButton>
    );
}
