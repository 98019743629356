import { Checkbox, Flex, Mark, Text } from "@mantine/core";
import { useProductInfo } from "@expert/contentful";
import { useAnalytics } from "../../../analytics";
import { usePartner } from "../../../sdk";
import classes from "./TextLegalDisclosure.module.css";

interface LegalDisclosureProps {
    productSku: string;
    onDisclosureCheckedUpdated: (newStatus: boolean) => void;
    isDisclosureChecked: boolean;
}

export function TextLegalDisclosure({
    productSku,
    onDisclosureCheckedUpdated,
    isDisclosureChecked,
}: LegalDisclosureProps): JSX.Element {
    const partner = usePartner();
    const { dispatcher } = useAnalytics();
    const { productInfo, error } = useProductInfo(partner, [productSku]);
    if (error) {
        return (
            <Text ta="center" m="3rem">
                We ran into an issue. Legal disclosures are not available right now.
            </Text>
        );
    }

    return (
        <>
            <Flex direction="column" align="center" data-testid="home-flow-product-name">
                <Text fw="400" size="md" mt="sm">
                    {productInfo?.[productSku].displayName}
                </Text>
                <Text c="var(--mantine-color-success-5)" size="md" fw="700">
                    legal disclosures
                </Text>
            </Flex>
            <Flex className={classes.legalDisclosureContainer}>
                <Text className={classes.legalDisclosureText}>
                    {productInfo?.[productSku].legalDisclosure}
                    <Mark className={classes.highlightedText}>May I complete your enrollment today?</Mark>
                </Text>
                <Checkbox
                    checked={isDisclosureChecked}
                    className={classes.checkbox}
                    data-testid="disclosure-checkbox"
                    label="I’ve read the disclosures verbatim"
                    onChange={(e) => {
                        void dispatcher.dispatchAction("ButtonClick", "DisclosureCheckbox");
                        onDisclosureCheckedUpdated(e.target.checked);
                    }}
                />
            </Flex>
        </>
    );
}
