import { useLocalStorage } from "@mantine/hooks";
import { type PartnerProgram } from "../../shared-types";

export const useCustomerProgram = () => {
    const [customerProgram, setCustomerProgram] = useLocalStorage<PartnerProgram | undefined>({
        key: "customer-program",
        defaultValue: undefined,
    });

    return { customerProgram, setCustomerProgram };
};
