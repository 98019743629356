import confettiReaction from "./CelebrationNotification/animations/confetti-reaction.json";
import fireReaction from "./CelebrationNotification/animations/fire-reaction.json";
import heartsReaction from "./CelebrationNotification/animations/hearts-reaction.json";

export interface BaseNotificationType {
    type: "SaleMadeNotification" | "Celebration";
    id: string;
}

export type Reaction = "Confetti" | "Fire" | "Hearts";

export interface SaleMadeNotificationType extends BaseNotificationType {
    props: {
        seller: {
            employeeName: string;
            employeeId: string;
            rank: number;
            teamSize: number;
        };
    };
}

export interface CelebrationNotificationType extends BaseNotificationType {
    props: {
        sender: string;
        reactionType: Reaction;
    };
}

export const approvedReactions: { id: Reaction; value: string; animationData: unknown }[] = [
    { id: "Confetti", value: "🎉", animationData: confettiReaction },
    { id: "Fire", value: "🔥", animationData: fireReaction },
    { id: "Hearts", value: "❤️", animationData: heartsReaction },
];
