import { getLogger } from "@expert/logging";
import { useEffect, useState } from "react";
import { type AppConfig } from "../types";
import { api } from "../../shared-utils";

const logger = getLogger({ module: "useAppConfig" });

interface UseAppConfigOutput {
    loading: boolean;
    error: Error | null;
    appConfig?: AppConfig;
}

const contextLogger = getLogger({ module: "getAppConfig" });

async function getAppConfig(): Promise<AppConfig> {
    try {
        return await api.get("api/app-config").json<AppConfig>();
    } catch (err) {
        contextLogger.error({ err });
        throw err;
    }
}

/** Can ONLY be used under the <AuthProvider> scope
 * Retrieves the app configuration (currently specific for service provider, like TwilioAppConfig)
 */
export function useAppConfig(): UseAppConfigOutput {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [appConfig, setAppConfig] = useState<AppConfig>();

    useEffect(() => {
        const getConfig = async () => {
            const config = await getAppConfig();
            logger.debug("AppConfig received");

            setAppConfig(config);
            setLoading(false);
        };

        // TODO: More elegant error handling/reporting when we decide how we want this to bubble up to user space
        getConfig().catch((err: unknown) => {
            setError(err as Error);
            logger.error(err as Error);
            setLoading(false);
        });
    }, []);

    return { loading, error, appConfig };
}
