import { Popover, Stack } from "@mantine/core";
import { useActiveVoiceTask } from "../../../sdk";
import styles from "./ConferencePanel.module.css";
import { ConferenceParticipantControls, PrimaryParticipantControls } from "./Participant";

export function ConferencePanel() {
    const task = useActiveVoiceTask();

    const participants = task.conferenceParticipants.filter(({ type }) => type !== "inactive");

    const primaryParticipantRejoined = task.customerLeftTheConference
        ? participants.find(({ participantName }) => participantName === task.callerId)
        : undefined;

    const filteredParticipants = primaryParticipantRejoined
        ? participants.filter(({ participantName }) => participantName !== task.callerId)
        : participants;

    return (
        <Popover zIndex="var(--mantine-priority-high)">
            <Stack data-testid="conference-panel" gap="xs" bg="dark.9" classNames={{ root: styles.containerRoot }}>
                {primaryParticipantRejoined ? (
                    <ConferenceParticipantControls
                        key={primaryParticipantRejoined.participantCallId}
                        participant={primaryParticipantRejoined}
                        isPrimary
                    />
                ) : (
                    <PrimaryParticipantControls />
                )}
                {filteredParticipants.map((participant) => (
                    <ConferenceParticipantControls
                        key={participant.participantCallId}
                        participant={participant}
                        isPrimary={participant.participantCallId === primaryParticipantRejoined?.participantCallId}
                    />
                ))}
            </Stack>
        </Popover>
    );
}
