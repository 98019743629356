import { useQuery } from "@tanstack/react-query";
import { getRecordedLegalDisclosure } from "../api";
import { useContentfulClient } from "../ContentfulClientProvider";
import type { Language } from "../types";

export const useRecordedLegalDisclosure = (productSku: string, language: Language = "en-US") => {
    const contentfulClient = useContentfulClient();
    const { data } = useQuery({
        queryKey: [`recorded-disclosure-${productSku}-${language}`],
        queryFn: async () => await getRecordedLegalDisclosure(contentfulClient, productSku, language),
    });

    if (data) {
        return data;
    }

    return null;
};
