import { getLogger } from "@expert/logging";
import { HTTPError } from "ky";
import { getRootDispatcher } from "../../../analytics";
import type { Declination } from "../types";
import { getSessionId } from "../../../sdk";
import { getSalesApiClient } from "./api";

const logger = getLogger({
    module: "declination",
    supportTeam: "sales",
});

export const postDeclination = async (declination: Declination) => {
    const childLogger = logger.child({ productOfferId: declination.productOfferId, sessionId: getSessionId() });
    try {
        const salesApi = getSalesApiClient();
        const result = await salesApi.post("v2/declination", { json: declination }).json<boolean>();

        void getRootDispatcher().dispatchBusinessEvent("PostDeclination", {
            result,
            StatusOrigin: "ASP",
        });
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            void getRootDispatcher().dispatchBusinessEvent("DeclinationError", {
                Status: "LAMBDA_ERROR",
                Error: err,
            });
        }
        childLogger.error({ err }, "Declination failure");
        return false;
    }
};
