import { Card, Flex } from "@mantine/core";
import classes from "../PayDashboard.module.css";
import { formatUpdateTime } from "../utils";

interface UpdatedTimeProps {
    updatedTime: string;
}

export function UpdatedTime({ updatedTime }: UpdatedTimeProps): JSX.Element {
    const updateTime = updatedTime ? formatUpdateTime(updatedTime) : ": unknown update time";
    return (
        <>
            <Flex pr={4} align="center">
                <Card p="0" w="8px" h="8px" className={classes.dot} radius="xl" />
            </Flex>
            <strong>Last updated:</strong>
            <Flex pl={4}>{updateTime}</Flex>
        </>
    );
}
