import { Flex, SegmentedControl } from "@mantine/core";
import { getRootDispatcher } from "../../../analytics";
import classes from "./ChartHeader.module.css";

interface ChartHeaderProps {
    isSales: boolean;
    value: string;
    setValue: React.Dispatch<React.SetStateAction<string>>;
}

export function ChartHeader({ isSales, value, setValue }: ChartHeaderProps): JSX.Element {
    const toggle = (e: React.MouseEvent<HTMLDivElement>) => {
        const target = e.target as HTMLTextAreaElement;
        //the click event actually fires twice, once for the label once for the input
        //this means the toggle just resets itself instead of changing unless you filter for only one of the events.
        //might want to change the regex to look at something else but for now this works.
        if (/input/.exec(target.className)) {
            void getRootDispatcher().dispatchBusinessEvent("PayDashViewToggled", {
                view: isSales ? "Surveys" : "Sales",
            });
            if (isSales) {
                setValue("Surveys");
            } else setValue("Sales");
        }
    };
    return (
        <Flex justify="space-between" align="center">
            <Flex>{`Projected ${isSales ? " VHDP sales" : "survey"} pay`}</Flex>
            <SegmentedControl
                value={value}
                //didn't want to use the onChange because that only changes if you
                //click on the "correct" side instead of anywhere in the button
                onClick={(e) => toggle(e)}
                color="asurion"
                data={[
                    { label: "Sales", value: "Sales" },
                    { label: "Surveys", value: "Surveys" },
                ]}
                classNames={{
                    root: classes.segmentedRoot,
                    control: classes.segmentedControl,
                }}
            />
        </Flex>
    );
}
