import { Menu, Text } from "@mantine/core";
import { useState } from "react";
import { GuideMenuButton } from "./GuideMenuButton";

export interface GuideMenuItem {
    label: string;
    actionId: string;
}
interface GuideMenuProps {
    defaultValue?: string;
    label: string;
    items: GuideMenuItem[];
    actionId: string;
    validationError?: string;
    onClick: (selectedItem: GuideMenuItem) => void;
}

export function GuideMenu({ label, defaultValue, items, actionId, validationError, onClick }: GuideMenuProps) {
    const [selectedItem, setSelectedItem] = useState<GuideMenuItem | undefined>(undefined);
    const [menuOpened, setMenuOpened] = useState(false);

    const menuItems = items.map((item) => (
        <Menu.Item
            data-testid={`guide-menu-${actionId}-${item.actionId}`}
            key={`guide-menu-${actionId}-${item.actionId}`}
            onClick={() => {
                setSelectedItem(item);
                onClick(item);
            }}
        >
            <Text size="sm">{item.label}</Text>
        </Menu.Item>
    ));

    return (
        <Menu closeOnClickOutside position="bottom-start" opened={menuOpened} onChange={setMenuOpened}>
            <Menu.Target>
                <GuideMenuButton
                    label={validationError ?? selectedItem?.label ?? defaultValue ?? label}
                    menuOpened={menuOpened}
                    validationError={validationError}
                />
            </Menu.Target>
            <Menu.Dropdown maw="17.5rem">{menuItems}</Menu.Dropdown>
        </Menu>
    );
}
