import { getLogger } from "@expert/logging";
import { HTTPError } from "ky";
import { getRootDispatcher } from "../../../../analytics";
import { type Answer, type Question } from "../../types";
import { getEducationApi } from "./api";

const logger = getLogger({
    module: "questionnaire",
    supportTeam: "sales",
});

export const getQuestionnaire = async (): Promise<Question> => {
    const dispatcher = getRootDispatcher().withExtra({ module: "questionnaire" });
    try {
        const apiClient = getEducationApi();
        const result = await apiClient.get(`v1/questionnaire/vhdp`, { retry: 0 }).json<Question>();
        return result;
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            if (err.response.status === 404) {
                void dispatcher.dispatchAction("getQuestionnaire", "Expert has no questions left");
            }
            logger.error(`getQuestionnaire API call failed with status: ${err.response.status}`);
            throw err;
        }
        logger.error({ err }, "getQuestionnaire API call failed");
        throw err;
    }
};

export const postAnswer = async (question: Answer): Promise<void> => {
    try {
        const apiClient = getEducationApi();
        await apiClient.post(`v1/questionnaire/vhdp`, { json: question });
    } catch (err: unknown) {
        logger.error({ err }, "postQuestionnaire API call failed");
        throw err;
    }
};
