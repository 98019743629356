import { Button, Flex, Text } from "@mantine/core";
import { useState } from "react";
import { useAnalytics, withPolymorphicClickAnalytics } from "../../../../analytics";
import { getSessionId, isVoiceTask, useMostRecentTask, usePartner } from "../../../../sdk";
import { postDeclination } from "../../api";
import type { EligibleProduct } from "../../stores";
import { useHomeProductSessionStore } from "../../stores";
import { useIntegratedFlowStore } from "../../stores/useIntegratedFlowStore";
import { SaleProgress } from "../../types";
import { RightArrowIcon } from "../Icons";
import styles from "./StepTwoFooter.module.css";

interface EnrollSectionProps {
    product: EligibleProduct;
}

export function StepTwoFooter({ product }: EnrollSectionProps): JSX.Element {
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const [declining, setDeclining] = useState(false);

    const updateProduct = useHomeProductSessionStore((state) => state.updateProduct);
    const integratedStep = useIntegratedFlowStore((state) => state.integratedStep);
    const updateIntegratedStep = useIntegratedFlowStore((state) => state.updateIntegratedStep);

    const { dispatcher } = useAnalytics();
    const mostRecentTask = useMostRecentTask();
    const partner = usePartner();
    if (!isVoiceTask(mostRecentTask)) {
        throw new Error("Active task is not voice task");
    }

    const decline = async () => {
        try {
            const sessionId = getSessionId();
            await postDeclination({
                callId: sessionId,
                sessionId,
                partner,
                productOfferId: product.id,
            });
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
            setDeclining(false);
        } catch (e) {
            setDeclining(false);
        }
    };

    return (
        <Flex className={styles.footerBox}>
            <AnalyticsButton
                analytics={{
                    actionId: "EnrollmentDeclineButton",
                    eventData: {
                        nextStep: integratedStep,
                    },
                }}
                color="white"
                data-testid="enrollment-decline-button"
                loading={declining}
                onClick={() => {
                    setDeclining(true);
                    void decline();
                }}
                size="md"
                variant="transparent"
            >
                <Text td="underline" size="sm">
                    Decline
                </Text>
            </AnalyticsButton>
            <Button
                disabled={declining}
                className={styles.actionText}
                color="white"
                variant="outline"
                data-testid="continue-to-disclosures"
                rightSection={<RightArrowIcon />}
                onClick={() => {
                    void dispatcher
                        .withExtra({ nextStep: integratedStep })
                        .dispatchBusinessEvent("ButtonClicked_ContinueToDisclosures");
                    updateIntegratedStep("LegalDisclosureStep");
                }}
            >
                Continue to disclosures
            </Button>
        </Flex>
    );
}
