import { Button, Divider, Menu, Text } from "@mantine/core";
import { withClickAnalytics } from "@soluto-private/eventualize-react";
import { useMemo, useState } from "react";
import { CaretDown, CaretUp, CheckMark, CloseX, DoubleCheckMark } from "../../../common-ui";
import { usePartnerFeatureConfigured } from "../../../partner/usePartnerConfig";
import { type CallbackState, type VoiceTask, useAgentSdk, useCallbackState } from "../../../sdk";
import type { SummaryResolution } from "../../../session-summary/store/useSessionSummaryStore";
import { useSessionSummaryStore } from "../../../session-summary/store/useSessionSummaryStore";
import { useAsyncLoading, useFeatureFlag } from "../../../shared-utils";
import { handleEndCall } from "../../eventHandlers";
import { useControlsStore, withControlsLock } from "../controls.store";
import { useCustomerProgram } from "../../../customer-info";

/* Conditions for transfer to survey:
    1. Does not have a callback scheduled
    2. Does not have an active conference
    3. Customer has not already left after conference
*/
const shouldTransferToSurvey = (
    voiceTask: VoiceTask,
    callbackState: CallbackState | null,
    isConference: boolean,
    conferencePanelOpen: boolean,
    resolutionStatus: SummaryResolution,
): boolean => {
    const hasCallback = !!callbackState;
    const activeConference = isConference && conferencePanelOpen;
    return !(
        hasCallback ||
        activeConference ||
        voiceTask.customerLeftTheConference ||
        resolutionStatus === "not resolved"
    );
};

const AnalyticButton = withClickAnalytics(Button, "Click");
const AnalyticMenuItem = withClickAnalytics(Menu.Item, "Click");

/**
 * Button used to hangup a call for everyone
 *
 * in certain situations a dropdown menu is shown to allow the agent to select a resolution
 */
export function EndCallButton({ task }: { task: VoiceTask }): JSX.Element | null {
    const agentSdk = useAgentSdk();
    const callbackState = useCallbackState();
    const { controlLock, isConference, conferencePanelOpen } = useControlsStore();
    const [opened, setOpened] = useState(false);

    const anyParticipantOnHold = useMemo(() => {
        const conferenceParticipantIsOnHold =
            task.conferenceParticipants.find((participant) => participant.type === "active" && participant.hold) !==
            undefined;
        const primaryParticipantIsOnHold = task.holdState.onHold;

        return conferenceParticipantIsOnHold || primaryParticipantIsOnHold;
    }, [task.conferenceParticipants, task.holdState]);

    const { customerProgram } = useCustomerProgram();

    const isClaimEligibleProgram = customerProgram?.claimEligible ?? false;

    const partnerSurveyEnabled = usePartnerFeatureConfigured("surveyEnabled");
    const { data: isSurveyEnabled } = useFeatureFlag("isSurveyEnabled");
    const isSurveyEligible = (resolutionStatus: SummaryResolution) =>
        isSurveyEnabled && partnerSurveyEnabled
            ? shouldTransferToSurvey(task, callbackState, isConference, conferencePanelOpen, resolutionStatus)
            : false;

    const { setResolved } = useSessionSummaryStore();

    const [isLoading, resolveAndEndCall] = useAsyncLoading(
        (
            resolutionStatus: SummaryResolution = "not resolved",
            hideResolutionStatusForm = false,
            claimFiled = false,
        ) => {
            return withControlsLock(async () => {
                resolutionStatus && setResolved(resolutionStatus, { hideResolutionStatusForm });
                if (isSurveyEligible(resolutionStatus) && customerProgram) {
                    const surveyMeta = claimFiled
                        ? {
                              program: `${customerProgram.name}-retail`,
                              surveyId: customerProgram.survey.claimsSurveyId,
                          }
                        : {
                              program: customerProgram.name,
                              surveyId: customerProgram.survey.generalSurveyId,
                          };

                    await agentSdk.sendToSurvey(task, surveyMeta.program, surveyMeta.surveyId);
                } else {
                    await handleEndCall(agentSdk, task);
                }
            })();
        },
    );

    const noAcceptedCall = !task.conferenceId || !task.conferenceStarted;

    if (noAcceptedCall) {
        // ? redundant check. consider removing and trusting the developer to use this component correctly
        throw new Error("EndCall button should never be displayed for a call that has not been accepted.");
    }

    /** Menu resolution shouldn't be shown when callbacks are scheduled or a conference is active */
    const showResolutionMenu = !callbackState;

    return showResolutionMenu ? (
        <Menu position="bottom-end" opened={opened} shadow="md" onChange={setOpened}>
            <Menu.Target>
                <AnalyticButton
                    analytics={{ actionId: "HangupButton_MenuDropdownToggle" }}
                    aria-label="End Call"
                    data-testid="call-controls-hangup-menu"
                    disabled={isLoading || task.status !== "assigned" || controlLock || anyParticipantOnHold}
                    loading={isLoading || controlLock}
                    radius="xl"
                    rightSection={
                        opened ? (
                            <CaretUp fill="var(--mantine-color-white)" />
                        ) : (
                            <CaretDown fill="var(--mantine-color-white)" />
                        )
                    }
                    size="sm"
                    color={opened ? "red.8" : "red.9"}
                >
                    <Text size="sm">End call</Text>
                </AnalyticButton>
            </Menu.Target>
            <Menu.Dropdown bd="none" bg="dark.7">
                <AnalyticMenuItem
                    analytics={{ actionId: "HangupButton_IssueResolved" }}
                    data-testid="call-controls-hangup-menu-resolved"
                    py="xs"
                    onClick={() => resolveAndEndCall("resolved", true)}
                    leftSection={<CheckMark width={24} fill="var(--mantine-color-dark-2)" />}
                >
                    <Text size="sm">Issue resolved</Text>
                </AnalyticMenuItem>
                {isClaimEligibleProgram && (
                    <>
                        <AnalyticMenuItem
                            analytics={{ actionId: "HangupButton_IssueResolvedClaimFiled" }}
                            data-testid="call-controls-hangup-menu-claim-filed"
                            py="xs"
                            onClick={() => resolveAndEndCall("resolved", true, true)}
                            leftSection={<DoubleCheckMark width={24} fill="var(--mantine-color-dark-2)" />}
                        >
                            <Text size="sm">Resolved, claim filed</Text>
                        </AnalyticMenuItem>
                        <Divider color="dark.4" mt={5} mb={5} />
                    </>
                )}

                <AnalyticMenuItem
                    analytics={{ actionId: "HangupButton_IssueNotResolved" }}
                    data-testid="call-controls-hangup-menu-not-resolved"
                    py="xs"
                    onClick={() => resolveAndEndCall("not resolved", true)}
                    leftSection={<CloseX height={24} fill="var(--mantine-color-dark-2)" />}
                >
                    <Text size="sm">Issue not resolved</Text>
                </AnalyticMenuItem>
            </Menu.Dropdown>
        </Menu>
    ) : (
        <AnalyticButton
            analytics={{ actionId: "HangupButton" }}
            aria-label="End Call"
            data-testid="call-controls-hangup"
            disabled={isLoading || task.status !== "assigned" || controlLock || anyParticipantOnHold}
            loading={isLoading || controlLock}
            onClick={() => resolveAndEndCall("not resolved")}
            radius="xl"
            size="sm"
            color="red.9"
        >
            <Text size="sm">End call</Text>
        </AnalyticButton>
    );
}
