export function MegaphoneIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.333 2V2.798L2.66634 5.16933V4.66667H1.33301V10H2.66634V9.49733L3.33301 9.646V12C3.33301 12.306 3.54167 12.572 3.83767 12.6473L6.50434 13.314C6.55834 13.3267 6.61234 13.3333 6.66634 13.3333C6.87301 13.3333 7.07101 13.2367 7.19901 13.0667L8.84634 10.8713L13.333 11.8673V12.6667H14.6663V2H13.333ZM6.39834 11.9127L4.66634 11.4793V9.94133L7.41767 10.5533L6.39834 11.9127ZM2.66634 8.13133V6.53533L13.333 4.164V10.5027L2.66634 8.13133Z"
                fill="#E6E6EB"
            />
        </svg>
    );
}
