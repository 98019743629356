import { devtools, persist } from "zustand/middleware";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { type EligibilityStatus, type SaleProgress } from "../types";

export interface EligibleProduct {
    id: string;
    productSku: string;
    status: EligibilityStatus;
    saleProgress: SaleProgress;
    isPrimaryProduct: boolean;
    confirmationCodePattern?: string;
}

interface HomeProductSessionStore {
    homeProduct?: EligibleProduct;
    updateProduct: (product?: EligibleProduct) => void;
    resetSaleSession: () => void;
}

export const useHomeProductSessionStore = createWithEqualityFn<HomeProductSessionStore>()(
    persist(
        devtools(
            (set) => ({
                homeProduct: undefined,
                updateProduct: (product) => set({ homeProduct: product }, false, "updateProduct"),
                resetSaleSession: () => set({ homeProduct: undefined }, false, "resetSaleSession"),
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "home-product-store",
                name: "workspace/sales-tools",
            },
        ),
        { name: "home-product-store", skipHydration: true },
    ),
    shallow,
);
