import { type WorkspaceFeatures, useFeatures } from "@expert/features";
import { type SolveGuidedFlow } from "@expert/solve-tools";
import { getDeviceActivationGuide } from "../DeviceActivationGuide";
import { useDeviceActivationStore } from "../store";

export const useDeviceActivationGuide = (): SolveGuidedFlow => {
    const {
        features: { isConversationalIVRGuideEnabled },
    } = useFeatures<WorkspaceFeatures>();

    const guideInfo = useDeviceActivationStore();

    const guide = getDeviceActivationGuide({
        ...guideInfo,
        isConversationalIVRGuideEnabled,
    });

    return guide;
};
