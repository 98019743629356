import {
    Accordion,
    Affix,
    Badge,
    Card,
    Code,
    Collapse,
    Flex,
    Indicator,
    ScrollArea,
    Text,
    Tooltip,
} from "@mantine/core";
import { useHotkeys } from "@mantine/hooks";
import { useState } from "react";
import { FlexSpacer } from "../../common-ui";
import { useSessionStore } from "./session.store";
import type { Session, SessionChangeReason } from "./types";

interface DebugSessionProps {
    session: Session;
    index?: number;
}

const reasonIcons = {
    InboundCall: { icon: "↓", color: "green" },
    AdHocOutboundCall: { icon: "↑", color: "green" },
    CallCompletedWithoutCallback: { icon: "✓", color: "green" },
    CallRejected: { icon: "✖", color: "red" },
    CallCancelled: { icon: "✖", color: "red" },
    CallAlreadyExists: { icon: "!", color: "red" },
    CallUnexpectedlyEnded: { icon: "!", color: "red" },
    ForcedSessionEnd: { icon: "✖", color: "orange" },
    InitialSession: { icon: "?", color: "green" },
    IncomingTraining: { icon: "↓", color: "green" },
    TechnicalIssues: { icon: "⚠", color: "red" },
} as const satisfies Record<SessionChangeReason, { icon: string; color: string }>;

export function SessionHeader({ session, index }: DebugSessionProps) {
    const slicedId = session.id.slice(0, 8);

    const startIcon = reasonIcons[session.metadata.startReason].icon;
    const statColor = reasonIcons[session.metadata.startReason].color;

    const endIcon = session.metadata.endReason ? reasonIcons[session.metadata.endReason].icon : "...";
    const endColor = session.metadata.endReason ? reasonIcons[session.metadata.endReason].color : "blue";

    return (
        <Flex align="center" gap="xs">
            <Text fw={500} size="xs">
                ({index})
            </Text>
            <Tooltip label={session.metadata.startReason}>
                <Text c={statColor}>{startIcon}</Text>
            </Tooltip>
            →
            <Tooltip label={session.metadata.endReason}>
                <Text c={endColor}>{endIcon}</Text>
            </Tooltip>
            <Text fw={500} size="xs">
                <Code fz="xs">{slicedId}...</Code>
            </Text>
            <FlexSpacer />
            <Badge autoContrast color={session.kind === "with-customer" ? "blue" : "orange"} size="xs">
                {session.kind}
            </Badge>
            {session.metadata.status === "active" ? (
                <Badge color="green" size="xs" mr="xs">
                    Active
                </Badge>
            ) : (
                <Badge color="red" size="xs" mr="xs">
                    Inactive
                </Badge>
            )}
        </Flex>
    );
}

export function SessionCardContent({ session }: DebugSessionProps) {
    const [open, setOpen] = useState(false);

    function toggle() {
        setOpen(!open);
    }

    return (
        <Card shadow="sm" padding="sm" radius="md" withBorder onClick={toggle}>
            <Card.Section withBorder inheritPadding />
            <Card.Section inheritPadding p="sm">
                <Code block>{JSON.stringify(session, jsonReplacer, 2)}</Code>
            </Card.Section>
        </Card>
    );
}

export function SessionsDebugView() {
    const sessionHistory = useSessionStore().sessions;

    const indexAssociations = sessionHistory.reduce<Record<string, number>>((acc, session, index) => {
        acc[session.id] = index;
        return acc;
    }, {});

    const items = [...sessionHistory].reverse().map((session) => (
        <Accordion.Item value={session.id} key={session.id} mt="xs" mr="md">
            <Indicator label={session.tasks.length} size={16} color="blue">
                <Accordion.Control>
                    <SessionHeader session={session as Session} index={indexAssociations[session.id]} />
                </Accordion.Control>
                <Accordion.Panel my="xs" py="xs">
                    <SessionCardContent session={session as Session} />
                </Accordion.Panel>
            </Indicator>
        </Accordion.Item>
    ));

    return (
        <Card shadow="sm" padding="xs" radius="md" withBorder bg="dark" pr="0">
            <Accordion chevronPosition="right" variant="separated">
                <ScrollArea h={250}>{items}</ScrollArea>
            </Accordion>
        </Card>
    );
}

/** Placeholder to house the sessions dev tooling, should be moved to a more shared location once we have more dev tooling */
export function DevDebugTools() {
    useHotkeys([
        [
            "ctrl+K",
            () => {
                setSessionsOpen(!sessionsOpen);
            },
        ],
        [
            "mod+K",
            () => {
                setSessionsOpen(!sessionsOpen);
            },
        ],
    ]);
    const [sessionsOpen, setSessionsOpen] = useState(false);

    return (
        <Affix position={{ bottom: 20, left: 20 }}>
            <Collapse in={sessionsOpen}>{sessionsOpen ? <SessionsDebugView /> : null}</Collapse>
        </Affix>
    );
}

function jsonReplacer(key: string, value: unknown) {
    // Twilio data has a LOT of private members that may hold sensitive information like our auth token
    // These are all prefixed with an underscore, so we can filter them out here
    if (key.startsWith("_")) return undefined;

    return value;
}
