import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

export interface DisclosureStepStoreProps {
    step: DisclosureSteps | null;
    recordedDisclosureEndTime: number | null;
    updateStep: (step: DisclosureSteps) => void;
    setRecordedDisclosureEndTime: (date: Date) => void;
    resetCurrentStepStore: () => void;
}

export type DisclosureSteps = "DisclosureTypeSelectionStep" | "TextDisclosureStep" | "SmsDisclosureStep";

export const useDisclosureStepStore = create<DisclosureStepStoreProps>()(
    persist(
        devtools(
            (set) => ({
                step: null,
                recordedDisclosureEndTime: null,
                updateStep: (step: DisclosureSteps) => set({ step }, false, "updateStep"),
                resetCurrentStepStore: () =>
                    set({ step: null, recordedDisclosureEndTime: null }, false, "resetCurrentStepStore"),
                setRecordedDisclosureEndTime: (date) =>
                    set(() => ({ recordedDisclosureEndTime: date.getTime() }), false, "setRecordedDisclosureEndTime"),
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "disclosure-step-store",
                name: "workspace/sales-tools",
            },
        ),
        {
            skipHydration: true,
            name: "disclosure-step-store",
        },
    ),
);
