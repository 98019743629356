import { Select, Text } from "@mantine/core";
import { useGetPartnerPrograms } from "../../../partner/usePartnerConfig";
import { useCustomerProgram } from "../../../customer-info";
import classes from "./CustomerProgramSelection.module.css";

export function CustomerProgramSelection(): JSX.Element {
    const { customerProgram, setCustomerProgram } = useCustomerProgram();

    const programs = useGetPartnerPrograms();

    const programList: string[] = programs.map((program) => program.name);

    const findAndSetProgram = (programName: string | null) => {
        const program = programs.find((pgm) => pgm.name === programName);
        if (program) {
            setCustomerProgram(program);
        }
    };
    const handleOptionSelect = (value: string | null) => {
        findAndSetProgram(value);
    };

    return (
        <>
            <Text c="dark.1" size="18px" mb="1rem" mt="2rem">
                What plan does the customer have?
            </Text>
            <Select
                allowDeselect={false}
                radius="xs"
                placeholder="Choose a program"
                classNames={{
                    input: classes.styledInput,
                    options: classes.optionsWrapper,
                    option: classes.option,
                    dropdown: classes.dropdown,
                }}
                comboboxProps={{ withinPortal: false }}
                size="lg"
                data={programList}
                onChange={(_value) => handleOptionSelect(_value)}
                value={customerProgram?.name}
                withCheckIcon={false}
            />
        </>
    );
}
