import type { VoiceTask } from "../../sdk";
import { AssignedCallControls, CancelledCallControls, CompletedCallControls, PendingCallControls } from "./Status";

export function CallControlsSwitch({ task }: { task: VoiceTask }) {
    const renderControls = () => {
        /** if a call is answered yet or not, twilio conference different form our workspace definition of a conference
         *
         * ! for some reason the task status can be pending while the call is actually answered... and visa versa. checking noAcceptedCall handles this case !
         */
        const noAcceptedCall = !task.conferenceId || !task.conferenceStarted;

        switch (task.status) {
            case "pending":
                return noAcceptedCall ? <PendingCallControls task={task} /> : null;
            case "assigned":
                // the timer from the above pending controls persists if pending controls are rendered here too!
                return noAcceptedCall ? <PendingCallControls task={task} /> : <AssignedCallControls task={task} />;
            case "wrapping":
                // We don't have any controls during wrapping, we have the session summary modal
                return null;
            case "completed":
                return <CompletedCallControls />;
            case "cancelled":
                return <CancelledCallControls />;
            default:
                return null;
        }
    };
    return <>{renderControls()}</>;
}
