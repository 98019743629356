import { getLogger } from "@expert/logging";
import { Flex, Group, Image, Stack } from "@mantine/core";
import { useEffect } from "react";
import LostAstronaut from "../assets/image/LostAstronaut.svg";
import classes from "./GlobalError.module.css";

type WorkspaceFullScreenErrorProps = React.PropsWithChildren<{ error: string }>;

export function WorkspaceFullScreenError({ children, error }: WorkspaceFullScreenErrorProps) {
    const logger = getLogger({ module: "workspaceFullScreenError" });

    useEffect(() => {
        logger.error(error);
    }, [error, logger]);

    return (
        <Flex justify="center" align="center" h="100vh" p="xl" w="100vw" className={classes.mainFlexContainer}>
            <Stack maw={500}>{children}</Stack>
            <Group className={classes.asset}>
                <Image src={LostAstronaut} alt="Lost astronaut" />
            </Group>
        </Flex>
    );
}
