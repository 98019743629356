import { useEffect } from "react";
import { IconButton } from "../../../common-ui";
import { Mute } from "../../../common-ui/assets/image/Mute";
import { Unmute } from "../../../common-ui/assets/image/Unmute";
import { useAgentSdk } from "../../../sdk";
import type { VoiceTaskProps } from "../../types";
import { useControlsStore } from "../controls.store";

export function MuteButton({ task }: VoiceTaskProps): JSX.Element {
    const agentSdk = useAgentSdk();
    const { isMuted, toggleMute } = useControlsStore();

    const muteToggleIcon = isMuted ? <Unmute height={19} /> : <Mute height={19} />;
    const muteToggleText = isMuted ? "Unmute" : "Mute";

    const handleClick = () => {
        void agentSdk.muteCall(task, !task.isMuted);
        toggleMute(!isMuted);
    };

    useEffect(() => {
        if (task.isMuted !== isMuted) {
            toggleMute(task.isMuted);
        }
    }, [isMuted, task.isMuted, toggleMute]);

    return (
        <IconButton
            active={isMuted}
            analytics={{
                actionId: "MuteButton",
            }}
            aria-label={muteToggleText}
            data-testid={`call-controls-${isMuted ? "unmute" : "mute"}`}
            onClick={handleClick}
            radius="xl"
            size="35"
            tooltip={muteToggleText}
        >
            {muteToggleIcon}
        </IconButton>
    );
}
