import { Button, type ButtonProps, type PolymorphicComponentProps, Tooltip } from "@mantine/core";

export interface IconButtonProps extends PolymorphicComponentProps<"button", ButtonProps> {
    tooltip: string;
}
export function GuideNavButton({ children, tooltip, ...rest }: IconButtonProps) {
    return (
        <Tooltip label={tooltip}>
            <Button variant="transparent" p="0.25rem" h="1.5rem" {...rest}>
                {children}
            </Button>
        </Tooltip>
    );
}
