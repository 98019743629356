import type { QueueTrafficInfo } from "../models";
import { getSessionId } from "../sdk";
import { api } from "../shared-utils";

export const getAllQueueVolumes = async (): Promise<QueueTrafficInfo[]> => {
    const response = await api.get(`api/traffic-control/v1/queues`, {
        headers: {
            "x-ewp-correlation-id": getSessionId(),
        },
    });

    return response.json<QueueTrafficInfo[]>();
};
