import { useEffect, useMemo } from "react";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useGaiaWebsocket } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { sdkEventBus, type VoiceTask } from "../../../sdk";
import { type NotifyCallContextPayload } from "../types";

interface ParseCallEventProps {
    event: VoiceTask;
    eventType: "start" | "end";
}

export const useSdkEventBus = (loggerProp: Logger) => {
    const websocketObj = useGaiaWebsocket();
    const logger = useMemo(() => loggerProp.child({ module: "useSdkEventBus" }), [loggerProp]);
    const {
        features: { isAutoCompleteEnabled },
    } = useFeatures<TimelineFeatures>();

    useEffect(() => {
        const callAcceptedEvent = sdkEventBus.on("call_accepted", (event) => {
            logger.info({ event }, "sdkEventBus | call accepted");

            if (isAutoCompleteEnabled && websocketObj && event.type === "voice") {
                const payload = parseCallEvent({ event, eventType: "start" }, logger);
                if (payload) {
                    websocketObj.sendJsonMessage(payload);
                    logger.info({ payload }, "notify call context sent to gaia");
                }
            }
        });

        const callDisconnectedEvent = sdkEventBus.on("task_completed", ({ task, reason }) => {
            logger.info({ task, reason }, "sdkEventBus | task_completed");

            if (isAutoCompleteEnabled && websocketObj && task.type === "voice") {
                const payload = parseCallEvent({ event: task as VoiceTask, eventType: "end" }, logger);
                if (payload) {
                    websocketObj.sendJsonMessage(payload);
                    logger.info({ payload }, "notify call context sent to gaia");
                }
            }
        });

        return () => {
            callAcceptedEvent();
            callDisconnectedEvent();
        };
    }, [websocketObj, isAutoCompleteEnabled, logger]);
};

const parseCallEvent = (
    { event, eventType }: ParseCallEventProps,
    logger: Logger,
): NotifyCallContextPayload | undefined => {
    const { agentCallId, sessionId } = event;
    const callSid = agentCallId;

    if (!callSid || !sessionId) {
        logger.error({ event }, "callSid or sessionId is missing");
        return;
    }

    return {
        action: "notify-call-context",
        eventType,
        callSid,
        sessionId: callSid,
        sessionGroupId: sessionId,
        timestamp: new Date().toISOString(),
    };
};
