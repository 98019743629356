import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export type SideBarTabs = "Assist" | "My stats";

interface SideBarStoreProps {
    selectedTab: SideBarTabs;
    setSelectedTab: (selectedTab: SideBarTabs) => void;
    reset: () => void;
}

const defaultTab = "Assist";

export const useSideBarStore = createWithEqualityFn<SideBarStoreProps>()(
    (set) => ({
        selectedTab: defaultTab,
        setSelectedTab: (selectedTab) => set({ selectedTab }),
        reset: () => set({ selectedTab: defaultTab }),
    }),
    shallow,
);
