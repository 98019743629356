import { AGENT_ACTIVITIES, type AgentActivity, isUnselectableActivity } from "../types";
import { useAsyncLoading } from "../../../shared-utils";
import { useAgentStore } from "../store";
import { useAgentSdk } from "./useAgentSdk";
import { useActiveTask } from "./useActiveTask";

export function useAgentActivity() {
    const agentSdk = useAgentSdk();
    const activeTask = useActiveTask();
    const { activity, setPendingActivity } = useAgentStore();

    async function setNewAgentActivity(localActivity: AgentActivity) {
        if (!!activeTask?.isAgentBusy(activity) || (isUnselectableActivity(activity) && activity !== "Default")) {
            setPendingActivity(localActivity);
        } else {
            await setAgentActivity(localActivity);
        }
    }
    const [loadingSetAgentActivity, setAgentActivity] = useAsyncLoading(async (selectedActivity: AgentActivity) => {
        await agentSdk.setAgentActivity(selectedActivity);
    });

    return { loadingSetAgentActivity, setNewAgentActivity, AGENT_ACTIVITIES };
}
