import { getAppConfigSingleton } from "../config/appConfigSingleton";
import { useActiveTask, useAgentSdk } from "../sdk";
import { type FeatureName } from "../shared-types";

export function usePartnerFeatureConfigured(featureName: FeatureName) {
    const taskPartner = useActiveTask()?.partner;
    const [agentPartner] = useAgentSdk().getPartners();
    const partnerConfig = getAppConfigSingleton().partnersConfig;

    // Task gets priority over agent partner
    const partnerConfigInfo = partnerConfig[taskPartner ?? agentPartner];
    return partnerConfigInfo.features[featureName];
}

export function useGetPartnerPrograms() {
    const taskPartner = useActiveTask()?.partner;
    const [agentPartner] = useAgentSdk().getPartners();
    const partnerConfig = getAppConfigSingleton().partnersConfig;

    // Task gets priority over agent partner
    const partnerConfigInfo = partnerConfig[taskPartner ?? agentPartner];
    return partnerConfigInfo.programs;
}
