import type { SVGProps } from "react";

export function CheckMark(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg fill="none" height="100%" viewBox="0 0 20 21" width="100%" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.2561 6.1792C17.5815 6.50463 17.5815 7.03227 17.2561 7.35771L8.92275 15.691C8.59732 16.0165 8.06968 16.0165 7.74424 15.691L3.57757 11.5244C3.25214 11.1989 3.25214 10.6713 3.57757 10.3459C3.90301 10.0204 4.43065 10.0204 4.75609 10.3459L8.3335 13.9233L16.0776 6.1792C16.403 5.85376 16.9306 5.85376 17.2561 6.1792Z"
                fill={props.fill ?? "var(--mantine-color-text)"}
            />
        </svg>
    );
}
