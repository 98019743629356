import { env } from "../../../../../config";
import { getSessionId } from "../../../../sdk";
import { api } from "../../../../shared-utils";

export const getEducationApi = () => {
    return api.extend(() => ({
        prefixUrl: env.educationBaseApiUrl,
        hooks: {
            beforeRequest: [
                (request) => {
                    request.headers.set("x-ewp-correlation-id", getSessionId());
                },
            ],
        },
    }));
};
