import { Flex, Modal, Paper, Text } from "@mantine/core";
import React from "react";
import { useProductInfo } from "@expert/contentful";
import { usePartner } from "../../../sdk";

export function DisclosureModal({
    opened,
    close,
    productSku,
    language,
}: {
    opened: boolean;
    close: () => void;
    productSku: string;
    language: "en-US" | "es-US";
}): JSX.Element {
    const partner = usePartner();
    const { productInfo, error } = useProductInfo(partner, [productSku], language);
    if (error) {
        return (
            <Text ta="center" m="3rem">
                We ran into an issue. Legal disclosures are not available right now.
            </Text>
        );
    }
    return (
        <Modal
            centered
            size="md"
            opened={opened}
            onClose={close}
            title={`${productInfo?.[productSku].displayName} legal disclosures`}
        >
            <Text size="sm">{productInfo?.[productSku].legalDisclosure}</Text>
            <Paper ml="1rem" radius="sm" bg="red.0">
                <Flex mt="2rem" p="0.5rem 1rem">
                    <Flex ml="1rem" direction="column">
                        <Text mt="6px" fw={600} size="sm" c="red.9">
                            Remember, you’re still required to play the audio clip
                        </Text>
                        <Text mt="0.5rem" c="dark.5" size="sm">
                            You can use this text to help answer the customer’s questions but you still need to play the
                            disclosure recording.
                        </Text>
                    </Flex>
                </Flex>
            </Paper>
        </Modal>
    );
}
