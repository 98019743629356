import { Flex, Paper, Text } from "@mantine/core";
import { useState } from "react";
import { getRootDispatcher } from "../../../analytics";
import { type ScaleEntry } from "../types";
import classes from "./StaticTooltip.module.css";

interface ChartTooltipProps {
    label: string;
    payload: Record<string, ScaleEntry>[] | undefined;
    currentValue: number;
    isSales: boolean;
}

export function DynamicTooltip({ label, payload, currentValue, isSales }: ChartTooltipProps) {
    const [priorValue, setPriorValue] = useState(0);
    if (!payload || payload.length === 0) return null;
    const targetValue = payload[0].payload.metric - currentValue;
    if (targetValue <= 0) return null;
    if (priorValue !== payload[0].payload.metric) {
        void getRootDispatcher().dispatchBusinessEvent("EarningsProjectionChecked", {
            view: isSales ? "Sales" : "Survey",
            value: payload[0].payload.metric,
            difference: targetValue,
        });
        setPriorValue(payload[0].payload.metric);
    }
    const headerString = isSales ? `MAKE ${targetValue} MORE SALES` : `GET ${targetValue} MORE SURVEYS`;
    const line1 = isSales ? "Net VHDP enrolls" : "Surveys";
    const line2 = isSales ? "Per enroll" : "Per survey";
    return (
        <Paper radius="sm" bg="dark.1" shadow="md" w="134px" px={2} py={7}>
            <Flex direction="column" align="center">
                <Text fw={700} fz="12px" lh="120%" c="dark.6" mb="8px">
                    {headerString}
                </Text>
                <Flex direction="column" align="center" w="108px">
                    <Flex className={classes.labelLine}>
                        <Text key="NetLabel" c="dark.6" fz="xs">
                            {line1}
                        </Text>
                        <Text key="NetValue" c="primary.7" fz="12px">
                            {label}
                        </Text>
                    </Flex>
                    <Flex className={classes.labelLine}>
                        <Text key="PerLabel" c="dark.6" fz="xs">
                            {line2}
                        </Text>
                        <Text key="PerValue" c="primary.7" fz="12px">
                            {`₱${payload[0].payload.pay ? payload[0].payload.perMetricPay : 0}`}
                        </Text>
                    </Flex>
                    <Flex className={classes.labelLine}>
                        <Text key="PayoutLabel" c="dark.6" fz="xs">
                            Payout
                        </Text>
                        <Text key="Payout Value" c="primary.7" fz="12px">
                            {`₱${payload[0].payload.pay}`}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </Paper>
    );
}
