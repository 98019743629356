import { env, getServiceProviderAccessToken } from "../auth";
import { OrchestratorAgentSdk } from "../sdk/agent/orchestrator/orchestratorAgentSdk";
import { type ServiceProvider } from "../shared-types";
import { type AgentSdkBase } from "../sdk/agent/agentSdkBase";
import { getAmazonConnectLoginUrl } from "./amazon-connect";

export interface CreateSdkParams {
    createContainer: (name: string) => Promise<HTMLElement>;
    token: string;
    employeeNum: string | null;
    hdxEnabled: boolean;
    useSmartEVL: boolean;
    serviceProviders: ServiceProvider[];
    userFriendlyName?: string;
}

type AgentSdkFactory = {
    [key in ServiceProvider]: (createSdkParams: CreateSdkParams) => Promise<AgentSdkBase>;
};

function createAmazonConnectAgentSdkFactory({
    displayName,
    instanceAlias,
    instanceId,
    region = "us-east-1",
}: {
    displayName: string;
    instanceAlias: string;
    instanceId: string;
    region?: "us-east-1" | "us-west-2";
}) {
    return async function create({ createContainer }: CreateSdkParams) {
        const contactControlPanelUrl = `https://${instanceAlias}.my.connect.aws/connect/ccp-v2`;
        const baseTargetUrl = `https://${region}.console.aws.amazon.com/connect/federate/${instanceId}`;
        const { default: AmazonConnectAgentSDK } = await import("../amazon-connect/AmazonConnectAgentSDK");
        const container = await createContainer(`Amazon Connect Contact Control Panel: ${displayName}`);
        const loginUrl = getAmazonConnectLoginUrl(env.oidcSsoUrl, baseTargetUrl);
        return await AmazonConnectAgentSDK.create(container, contactControlPanelUrl, { loginUrl });
    };
}

const isProd = import.meta.env.MODE === "production";
const agentSdkFactory: AgentSdkFactory = {
    AMAZON_CONNECT_CCTS_ASURION: createAmazonConnectAgentSdkFactory({
        displayName: "Contact Center Technology Services—Asurion",
        ...(isProd
            ? { instanceAlias: "ccts-otherclients-production", instanceId: "d0e562a4-b038-4b32-882b-301734ac07e8" }
            : { instanceAlias: "ccts-otherclients", instanceId: "d154214e-a5d3-408c-aca7-cb4b4f41e169" }),
    }),

    AMAZON_CONNECT_CC_DR: createAmazonConnectAgentSdkFactory({
        displayName: "Contact Center—Disaster Recovery",
        ...(isProd
            ? { instanceAlias: "contactcenter-prod", instanceId: "be74e14d-f625-4701-9792-4347ee4b40c7" }
            : { instanceAlias: "contactcenter-dev", instanceId: "b5a8b2c7-365d-42ef-90ca-6a50fafdec9d" }),
        region: "us-west-2",
    }),

    AMAZON_CONNECT_SSO_SPIKE: createAmazonConnectAgentSdkFactory({
        displayName: "SSO Spike",
        instanceAlias: "expert-workspace-sso-spike",
        instanceId: "34b8c3c7-9cdb-4728-b46b-2bfeaf176daf",
    }),

    TWILIO: async ({ token, employeeNum, hdxEnabled, useSmartEVL, userFriendlyName }) => {
        const { TwilioAgentSDK } = await import("../twilio/twilioAgentSdk");

        return await TwilioAgentSDK.create(
            token,
            () => getServiceProviderAccessToken(userFriendlyName),
            employeeNum,
            hdxEnabled,
            useSmartEVL,
        );
    },
};

export async function createAgentSDK(params: CreateSdkParams) {
    if (!params.token) {
        throw new Error("No token provided");
    }

    const agentSdks = await Promise.all(
        params.serviceProviders.map((serviceName) => agentSdkFactory[serviceName](params)),
    );

    return new OrchestratorAgentSdk(agentSdks);
}
