import type { ContentfulClientApi, EntryFieldTypes, EntrySkeletonType } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import type { ReactNode } from "react";

export type ProductInfoMap = Record<
    string,
    { displayName: string; legalDisclosure: ReactNode; logo: string | undefined }
>;

export interface ProductInfo {
    displayName: string;
    legalDisclosure: ReactNode;
    logo: string | undefined;
}

interface ProductInfoFields {
    productSku: EntryFieldTypes.Symbol;
    displayName: EntryFieldTypes.Symbol;
    legalDisclosure: EntryFieldTypes.RichText;
    logo: EntryFieldTypes.AssetLink;
}

interface SalesPartnerConfigFields {
    partner: EntryFieldTypes.Symbol;
    products: EntryFieldTypes.Array<EntryFieldTypes.EntryLink<EntrySkeletonType<ProductInfoFields, "productInfo">>>;
}

export const getProductInfo = async (
    partner: string,
    contentfulClient: ContentfulClientApi<undefined>,
    productSkuList: string[],
    language: string,
): Promise<Record<string, ProductInfo>> => {
    const productInfo = await contentfulClient.withoutUnresolvableLinks.getEntries<
        EntrySkeletonType<SalesPartnerConfigFields, "salesPartnerConfig">
    >({
        content_type: "salesPartnerConfig",
        locale: language,
        "fields.partner": partner,
    });

    if (!productInfo.items.length) {
        return {};
    }
    const fields = productInfo.items[0].fields;
    const filteredList = fields.products
        .map((item) => item?.fields)
        .filter((item) => item && productSkuList.includes(item.productSku));

    const productInfoMap: Record<string, ProductInfo> = {};

    filteredList.forEach((item) => {
        if (item) {
            productInfoMap[item.productSku] = {
                displayName: item.displayName,
                legalDisclosure: documentToReactComponents(item.legalDisclosure),
                logo: item.logo?.fields.file?.url,
            };
        }
    });

    return productInfoMap;
};
