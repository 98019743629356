import { getLogger } from "@expert/logging";
import { Text, Tooltip } from "@mantine/core";
import { useEffect } from "react";
import { useStopwatch } from "../../../shared-utils";
import type { TaskProps } from "../../types";
import { useSession } from "../../../sdk";
import classes from "./CallDuration.module.css";

const logger = getLogger({ module: "CallDuration" });

export function CallDuration({ task }: TaskProps): JSX.Element {
    const taskAssignedAt = task.assignedAt;
    const { startedTimestamp, kind } = useSession();
    const formattedTime = useStopwatch(taskAssignedAt?.getTime());
    const formattedSessionDuration = useStopwatch(startedTimestamp);

    useEffect(() => {
        if (task.status !== "assigned" && kind !== "with-customer") {
            return;
        }
        if (!taskAssignedAt) {
            logger.warn(
                {
                    task,
                    callTime: formattedTime,
                },
                "UNDEFINED TASK ASSIGNMENT DATE",
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps -- we don't want formattedTime to trigger this effect every second
    }, [task.status, taskAssignedAt]);

    return (
        <Tooltip
            label={`Total ${kind === "with-customer" ? formattedSessionDuration : "--"}`}
            p="sm"
            withArrow
            fz="xs"
            bg="dark.5"
            ff="monospace"
            position="bottom"
        >
            <Text className={classes.timerText} component="span" size="sm" ff="monospace">
                {formattedTime}
            </Text>
        </Tooltip>
    );
}
