import { trackAppcuesEvent } from "@expert/monitoring";
import { Container, Flex } from "@mantine/core";
import { useEffect, useMemo } from "react";
import { LoadingAnimation } from "../../../sales-tools/home-product/components/Icons";
import { isVoiceTask, useMostRecentTask } from "../../../sdk";
import { useFeatureFlag } from "../../../shared-utils";
import { CustomerVerificationFlowV3 } from "../customer-verification";
import classes from "./CustomerPanel.module.css";
import { CustomerProfile } from "./CustomerProfile";

export function CustomerPanel(): JSX.Element {
    const mostRecentTask = useMostRecentTask();
    const mostRecentVoiceTask = useMemo(() => {
        return isVoiceTask(mostRecentTask) ? mostRecentTask : null;
    }, [mostRecentTask]);
    const { data: blacklistedNumbers, isLoading: isLoadingBlacklistedNumbers } =
        useFeatureFlag<string[]>("EWP-isBlacklistedNumber");

    // Reset the offer status received flag when the component mounts
    useEffect(() => {
        trackAppcuesEvent("CustomerPanelVisible", {});
    }, []);

    if (isLoadingBlacklistedNumbers) {
        return (
            <Container maw="160px">
                <LoadingAnimation />
            </Container>
        );
    }

    return (
        <Flex className={classes.customerPanel}>
            <Flex className={classes.responsivePanel}>
                <Flex className={classes.topSection}>
                    <CustomerProfile />
                </Flex>
                {mostRecentVoiceTask ? (
                    <CustomerVerificationFlowV3
                        mdnFromTask={
                            blacklistedNumbers?.includes(mostRecentVoiceTask.mdn) ? "" : mostRecentVoiceTask.mdn
                        }
                    />
                ) : null}
            </Flex>
        </Flex>
    );
}
