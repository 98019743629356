import { type Partner } from "../../shared-types";

export enum EligibilityStatus {
    Ineligible = "INELIGIBLE",
    Eligible = "ELIGIBLE",
    Pending = "PENDING_ENROLLMENT",
    Enrolled = "ALREADY_ENROLLED",
    Unknown = "UNKNOWN",
}

export enum EnrollmentStatus {
    Success = "SUCCESS",
    Error = "ERROR",
    Failure = "FAILURE",
}

export enum SaleProgress {
    InProgress = "INPROGESS",
    Success = "SUCCESS",
    Failure = "FAILURE",
    Declined = "DECLINED",
}

export interface SaleStatusStyle {
    bgColor: string;
    fontColor: string;
}

export const StatusStyles: Record<string, SaleStatusStyle> = {
    Success: {
        bgColor: "var(--mantine-color-success-5)",
        fontColor: "var(--mantine-color-dark-9)",
    },
    Neutral: {
        bgColor: "var(--mantine-color-dark-2)",
        fontColor: "white",
    },
    Error: {
        bgColor: "var(--mantine-color-red-8)",
        fontColor: "white",
    },
};

export interface SalesOpportunity {
    callId: string;
    sessionId: string;
    partner: Partner;
}
export interface EligibilityRequestV2 {
    partner: Partner;
    sessionId: string;
    customerMdn?: string;
    countryCallingCode?: string;
    reservationSid?: string;
    asurionCallId?: string;
}
export interface Enrollment extends Required<SalesOpportunity> {
    productOfferId: string;
    confirmationCode?: string;
}

export interface Declination extends Required<SalesOpportunity> {
    productOfferId: string;
    reason?: string;
}
export interface EnrollmentResponse {
    enrollmentStatus: EnrollmentStatus;
    productSku: string;
}
