function getTargetResource(baseTargetUrl: string | URL) {
    const url = new URL(baseTargetUrl);
    if (location.protocol === "https") url.searchParams.set("destination", location.href);
    return url.toString();
}

export function getAmazonConnectLoginUrl(baseUrl: string | URL, baseTargetUrl: string | URL) {
    const url = new URL("/idp/startSSO.ping", baseUrl);
    url.searchParams.set("PartnerSpId", "urn:amazon:webservices");
    url.searchParams.set("TargetResource", getTargetResource(baseTargetUrl));
    return url.toString();
}
