export const serviceProviders = Object.freeze([
    "AMAZON_CONNECT_CCTS_ASURION",
    "AMAZON_CONNECT_CC_DR",
    "AMAZON_CONNECT_SSO_SPIKE",
    "TWILIO",
] as const);

export type ServiceProvider = (typeof serviceProviders)[number];

export function isServiceProvider(value: unknown): value is ServiceProvider {
    return typeof value === "string" && (serviceProviders as readonly string[]).includes(value);
}

export function assertServiceProvider(value: unknown): asserts value is ServiceProvider {
    if (isServiceProvider(value)) return;
    throw new Error(`value is not a ServiceProvider: ${value}`);
}
