import { Group, Text } from "@mantine/core";
import { PhoneNumber } from "../../common-ui";
import { useSessionActiveCallerId } from "../../sdk";

export function WrappingCallControls() {
    const sessionMdn = useSessionActiveCallerId();

    return (
        <Group gap="xs">
            {sessionMdn ? (
                <Text className="fs-mask" fw={700} mr="sm" size="md">
                    <PhoneNumber mdn={sessionMdn} />
                </Text>
            ) : null}
        </Group>
    );
}
