import { ContentfulClientProvider } from "@expert/contentful";
import { GlobalStyles, GlobalThemeProvider } from "@expert/theme";
import { initAppcues, initDynatrace, initFullStory } from "@expert/monitoring";
import { RootAnalyticsProvider } from "@soluto-private/eventualize-react";
import { FeedbackContextProvider } from "@soluto-private/expert-workspace-feedback";
import { defaultShouldDehydrateQuery, QueryClient } from "@tanstack/react-query";
import { createClient } from "contentful";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { getLogger } from "@expert/logging";
import { env } from "../config";
import { transformDispatcher } from "./analytics";
import { GlobalNotificationProvider, ToastProvider } from "./common-ui";
import { router } from "./router";
import { enhanceAnalytics } from "./utils";

const logger = getLogger({ module: "App" });
window.addEventListener("unhandledrejection", ({ reason }: { reason: unknown }) => {
    logger.error({ err: reason }, "unhandledrejection");
});

const queryClient = new QueryClient({ defaultOptions: { queries: { gcTime: 1000 * 60 * 60 /* 1 hour */ } } });

const persister = createAsyncStoragePersister({
    storage: AsyncStorage,
});

const contentfulClient = createClient(env.contentful);

export default function App(): JSX.Element {
    useEffect(() => {
        transformDispatcher((dispatcher) => {
            return dispatcher.withEnhancer(enhanceAnalytics);
        });
        initFullStory({
            orgId: env.fullstoryOrgId,
            isDevMode: !env.isProdMode,
        });
        initDynatrace({
            isEnabled: env.dynatraceEnabled,
            appMonitoringUrl: env.dynatraceAppMonitoringUrl,
        });
        initAppcues({
            isEnabled: env.appcuesEnabled,
            url: env.appcuesUrl,
            appName: env.appName,
        });
    }, []);

    return (
        <GlobalThemeProvider>
            <GlobalStyles />
            <RootAnalyticsProvider>
                <ContentfulClientProvider client={contentfulClient}>
                    <PersistQueryClientProvider
                        client={queryClient}
                        persistOptions={{
                            buster: env.commitHash,
                            maxAge: 1000 * 60 * 60, //1 hour
                            persister,
                            dehydrateOptions: {
                                shouldDehydrateQuery: (query) => {
                                    return !!query.options.meta?.shouldPersist && defaultShouldDehydrateQuery(query);
                                },
                            },
                        }}
                    >
                        <GlobalNotificationProvider>
                            <ToastProvider>
                                <FeedbackContextProvider expertFeedbackUrl={env.expertFeedbackUrl}>
                                    <RouterProvider router={router} />
                                </FeedbackContextProvider>
                            </ToastProvider>
                        </GlobalNotificationProvider>
                    </PersistQueryClientProvider>
                </ContentfulClientProvider>
            </RootAnalyticsProvider>
        </GlobalThemeProvider>
    );
}
