import { getLogger } from "@expert/logging";
import { Button } from "@mantine/core";
import { withClickAnalytics } from "@soluto-private/eventualize-react";
import { useEffect } from "react";
import type { VoiceTask } from "../../../sdk";
import { useAgentSdk } from "../../../sdk";
import { useAsyncLoading } from "../../../shared-utils";
import { useControlsStore, withControlsLock } from "../controls.store";

const logger = getLogger({ module: "LeaveButton" });

/** Button used to leave a conference without ending the call for everyone else */
export function LeaveCallButton({ task }: { task: VoiceTask }): JSX.Element {
    const agentSdk = useAgentSdk();
    const { controlLock, toggleConferencePanelOpen } = useControlsStore();
    const conferenceInProgress =
        task.conferenceStarted && task.conferenceParticipants.every(({ type }) => type === "inactive");

    const [isLoadingLeaveCall, leaveCall] = useAsyncLoading(
        withControlsLock(async () => {
            toggleConferencePanelOpen();
            // Ensure that everyone is off of hold before disengaging

            // Primary customer
            if (task.holdState.onHold) {
                await agentSdk.resumeCall(task);
            }

            const activeConferenceParticipants = task.conferenceParticipants.filter(({ type }) => type === "active");

            if (activeConferenceParticipants.length === 0) {
                logger.error(task.toLog(), "No conference participants found when leaving call");
                return;
            }

            // Additional participants
            const [{ participantName }] = activeConferenceParticipants; // TODO: it works now, but we need to find participant by name or call id
            if (participantName) {
                await agentSdk.updateParticipant(task, participantName, {
                    endConferenceOnExit: false,
                    hold: false,
                });
            }
            await agentSdk.leaveConference(task);
        }),
    );

    const AnalyticButton = withClickAnalytics(Button, "Click");

    useEffect(() => {
        logger.info(
            { ...task.toLog(), isLoadingLeaveCall, controlLock, conferenceInProgress },
            "Leave button is disabled",
        );
    }, [conferenceInProgress, controlLock, isLoadingLeaveCall, task]);

    return (
        <AnalyticButton
            analytics={{
                actionId: "LeaveCallButton",
            }}
            aria-label="Leave Call"
            data-testid="call-controls-leave"
            disabled={isLoadingLeaveCall || task.status !== "assigned" || controlLock || conferenceInProgress}
            loading={isLoadingLeaveCall}
            onClick={leaveCall}
            radius="xl"
            size="sm"
            color="red.9"
        >
            Leave
        </AnalyticButton>
    );
}
