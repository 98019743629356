import { useQuery } from "@tanstack/react-query";
import { getPayDashboard } from "./getDashboardData";

export const useDashboardData = () => {
    const { data, isLoading, isError } = useQuery({
        queryKey: ["PayDashboardData"],
        queryFn: async () => getPayDashboard(),
        staleTime: 28800000, //8 hours
        gcTime: 28800000,
        retry: 1,
    });
    return { data, isLoading, isError };
};
