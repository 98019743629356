import { useCustomerAvatar } from "@expert/contentful";
import { Button, Group, Stack, Text } from "@mantine/core";
import { useState } from "react";
import { useTimer } from "react-timer-hook";
import { useAgentSdk, useSession } from "../../../../sdk";
import { clearCallbacksState } from "../../../eventHandlers";
import { CancelCallbackButton } from "../../Buttons";
import { useAsyncLoading } from "../../../../shared-utils";
import { PhoneNumber } from "../../../../common-ui";
import sharedStyles from "../../sharedStyles.module.css";
import classes from "./CallbackCallBar.module.css";
import { CallbackCallBarBody } from "./CallbackCallBarBody";

export function CallbackCallBar(): JSX.Element {
    const session = useSession();
    const agentSdk = useAgentSdk();
    const callbackState = session.callbackState;

    const [errorCalling, setErrorCalling] = useState(false);
    const [isCalling, handleCallCustomerBack] = useAsyncLoading(async () => {
        setErrorCalling(false);
        let tries = 0;

        for (tries = 0; tries < 3; tries++) {
            try {
                await agentSdk.callCustomerBack(session);
                return;
            } catch (error) {
                console.error("Error calling customer back", error);
                await new Promise((resolve) => {
                    setTimeout(resolve, 1000);
                });
            }
        }

        setErrorCalling(true);
    });

    const [loading, cancelCallback] = useAsyncLoading(async () => {
        await agentSdk.startWrapup("CallbackCancelled");
        clearCallbacksState();
    });

    const customerAvatarUrl = useCustomerAvatar(session.id);

    if (!callbackState) {
        throw new Error("CallbackCallBar should not be rendered without a callback state");
    }

    const expiryTimestamp = new Date(callbackState.scheduledFor);
    const { totalSeconds, isRunning, start } = useTimer({
        expiryTimestamp,
        autoStart: false,
        onExpire: () => {
            void handleCallCustomerBack();
        },
    });

    if (callbackState.scheduledFor && !isRunning && callbackState.scheduledFor > 0) {
        start();
    } else if (callbackState.scheduledFor === 0) {
        void handleCallCustomerBack();
    }

    const mdnText = callbackState.callbackMDN;

    function retry() {
        void handleCallCustomerBack();
    }

    const isCallingState =
        isCalling ||
        (callbackState.callbackType === "CallbackNow" && (callbackState.callbackDelay === 0 || totalSeconds === 0));

    return (
        <Group p={0}>
            {isCallingState ? (
                <Stack gap="0" p={0}>
                    <Text fw={300} size="xs" className={sharedStyles.animatedEllipses}>
                        Calling
                    </Text>
                    <Text className="fs-mask" fw={700} mr="sm" size="md">
                        <PhoneNumber mdn={mdnText} />
                    </Text>
                </Stack>
            ) : (
                <>
                    <Group align="center" flex="1">
                        {customerAvatarUrl ? (
                            <img
                                className={classes.profilePicture}
                                src={customerAvatarUrl}
                                alt="customer profile avatar"
                            />
                        ) : null}

                        <CallbackCallBarBody
                            mdnText={mdnText}
                            secondsTillCall={totalSeconds}
                            errorCalling={errorCalling}
                        />
                    </Group>
                    <Group gap="sm" justify="flex-end" grow={false} flex="0">
                        {errorCalling ? (
                            <Button color="primary" onClick={retry}>
                                Retry
                            </Button>
                        ) : (
                            <CancelCallbackButton
                                onClick={cancelCallback}
                                loading={loading}
                                disabled={totalSeconds < 4}
                            />
                        )}
                    </Group>
                </>
            )}
        </Group>
    );
}
