import { Center, Flex, Text, Tooltip } from "@mantine/core";
import { type SalesChecklistBehaviors } from "@expert/shared-types";
import { WrenchIcon, ChatIcon, UncheckedRadioIcon, GreenCheckMark } from "../Icons";
import { useOfferChecklistStore } from "../../stores/useOfferChecklistStore";
import { type Customer } from "../../../../customer-info";
import { type Partner } from "../../../../shared-types";
import { usePartner } from "../../../../sdk";
import classes from "./PlanHighlights.module.css";

const behaviorMapping = (
    partner: Partner,
): { displayString: string; key: SalesChecklistBehaviors; category: string }[] => [
    {
        displayString: "Repair, replacement, or reimbursement for home tech",
        key: "PROTECTION",
        category: "Protection",
    },
    {
        displayString: `Covers eligible products-both ${partner} and non-${partner}`,
        key: "NON_VERIZON_DEVICES",
        category: "Protection",
    },
    {
        displayString: "Covers future home tech purchased—no receipts needed",
        key: "FUTURE_DEVICES",
        category: "Protection",
    },
    {
        displayString: "24/7 premium tech support",
        key: "TECH_SUPPORT",
        category: "TechSupport",
    },
    {
        displayString: "In-home installations and in-store tech services",
        key: "IN_HOME_INSTALLATION",
        category: "TechSupport",
    },
];

interface DynamicPlanHighlightsProps {
    customerTechSavvyRating: Customer["techSavvyRating"];
}

export function DynamicPlanHighlights({ customerTechSavvyRating }: DynamicPlanHighlightsProps): JSX.Element | null {
    const { data } = useOfferChecklistStore();
    const partner = usePartner();
    const renderItems = (category: string, limit: number) => {
        const shouldShowCheckMark = data.IN_HOME_INSTALLATION || data.IN_STORE_SERVICES;
        return behaviorMapping(partner)
            .filter((item) => item.category === category)
            .slice(0, limit)
            .map((item) => {
                let rowClassName = classes.none;
                let rowIcon = <UncheckedRadioIcon />;
                if (item.displayString === "In-home installations and in-store tech services" && shouldShowCheckMark) {
                    rowClassName = classes.rowChecked;
                    rowIcon = <GreenCheckMark />;
                } else if (data[item.key]) {
                    rowClassName = classes.rowChecked;
                    rowIcon = <GreenCheckMark />;
                }

                return (
                    <Flex className={classes.root} key={item.key} align={Center}>
                        <Tooltip
                            events={{ hover: !data[item.key], focus: !data[item.key], touch: !data[item.key] }}
                            label="We’ll check it off once you mention it"
                            position="left"
                            zIndex="var(--mantine-priority-high)"
                        >
                            <Flex pt="5px" pb="5px" className={rowClassName} direction="row">
                                {rowIcon}
                                <Text lh="16px" className={classes.label}>
                                    {item.displayString}
                                </Text>
                            </Flex>
                        </Tooltip>
                    </Flex>
                );
            });
    };

    const isHighTech = customerTechSavvyRating === "High-Tech";
    const borderColor = isHighTech ? "1px solid success.3" : "1px solid primary.2";
    const textColor = isHighTech ? "success.3" : "primary.2";
    const icon = isHighTech ? <WrenchIcon /> : <ChatIcon />;
    const secondCategory = isHighTech ? "Tech support" : "Protection";

    return (
        <>
            <Flex align="start" direction="column" className={classes.mainBorder} bd={borderColor}>
                <Flex direction="column" gap={2}>
                    <Flex mt={1} mb={2} fz={12} gap={4} lh="14px" fw={700} c={textColor}>
                        Customer is {customerTechSavvyRating === "High-Tech" ? "" : "less"} tech savvy
                    </Flex>
                    <Flex lh="18px" fz={16} fw={700} mb={1} mt={2} c="dark.1">
                        Emphasize {customerTechSavvyRating === "High-Tech" ? "protection" : "tech support"}
                        <Flex mr={4} justify={Center} />
                        {icon}
                    </Flex>

                    <Flex direction="column">
                        {isHighTech ? renderItems("Protection", 3) : renderItems("TechSupport", 2)}
                    </Flex>
                </Flex>
            </Flex>
            <Flex direction="column" pt={16}>
                <Text size="14px" fw={700}>
                    {secondCategory}
                </Text>
                {isHighTech ? renderItems("TechSupport", 1) : renderItems("Protection", 2)}
            </Flex>
        </>
    );
}
