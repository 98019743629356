import { Container, Flex, ScrollArea } from "@mantine/core";
import { useHomeProductSessionStore } from "../../stores";
import { SaleProgress } from "../../types";
import { TransitionStatements } from "../TransitionStatements/TransitionStatements";
import { StaticPlanHighlights } from "../PlanHighlights/StaticPlanHighlights";
import { DynamicPlanHighlights } from "../PlanHighlights/DynamicPlanHighlights";
import { useVerifiedCustomerStore } from "../../../../customer-info";
import classes from "./StepTwoContent.module.css";
import { StepTwoFooter } from "./StepTwoFooter";

export function StepTwoContent(): JSX.Element | null {
    const homeProduct = useHomeProductSessionStore((state) => state.homeProduct);
    const customerTechSavvyRating = useVerifiedCustomerStore((state) => state.customer?.techSavvyRating);
    if (!homeProduct) return null;
    const renderPlanHighlights = () => {
        if (customerTechSavvyRating === undefined || customerTechSavvyRating === "No-data") {
            return <StaticPlanHighlights />;
        }

        return <DynamicPlanHighlights customerTechSavvyRating={customerTechSavvyRating} />;
    };
    return (
        <>
            {homeProduct.saleProgress === SaleProgress.InProgress ? (
                <Flex className={classes.offerRoot}>
                    <ScrollArea className={classes.scrollArea}>
                        <Container id="transition-statements-container" mt="0.5rem" mb="0.5rem">
                            <TransitionStatements />
                        </Container>
                        <Container id="plan-highlights-container" p="0 1.5rem 0 1.5rem">
                            {renderPlanHighlights()}
                        </Container>
                    </ScrollArea>
                    <StepTwoFooter product={homeProduct} />
                </Flex>
            ) : null}
        </>
    );
}
