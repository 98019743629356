import { getLogger } from "@expert/logging";
import { getRootDispatcher } from "../../../analytics";
import { formatSip } from "../../../shared-utils";
import { isVoiceTask } from "../voice/typeGuards";
import type { InactiveConferenceParticipantStatus, PendingConferenceParticipantStatus } from "../voice/conferenceTypes";
import { inactiveConferenceParticipantStatuses, pendingConferenceParticipantStatuses } from "../voice/conferenceTypes";
import { useSessionStore } from "../../sessions/session.store";
import type { ConferenceParticipantCallEvent } from "./conferenceEventTypes";

const logger = getLogger({
    module: "callEventsHandler",
});

export function callEventsHandler(event: ConferenceParticipantCallEvent) {
    const localLogger = logger.child({
        action: "callEventsHandler",
        taskId: event.taskId,
        participantCallId: event.callId,
        callStatus: event.callStatus,
    });
    localLogger.trace("Call Events Handler | Execution started");
    const task = useSessionStore.getState().getTask(event.taskId);

    // We might receive conference events after the task was completed
    if (!task || !isVoiceTask(task)) {
        localLogger.debug(
            "Call Events Handler | Receive call event for the task that is not in the store or is not a voice task",
        );
        return;
    }
    const taskLogger = localLogger.child(task.toLog());

    const analyticsDispatcher = getRootDispatcher()
        .withExtra({
            partner: task.partner,
        })
        .withIdentities({
            ConferenceId: task.conferenceId,
            AsurionCallId: task.asurionCallId,
            AgentCallId: task.agentCallId,
            CallSid: task.agentCallId,
            CustomerCallId: task.customerCallId,
            TaskSid: task.id,
            SessionId: task.sessionId,
        });

    const outboundCallSucceeded =
        event.callStatus === "in-progress" && task.callDirection === "outbound" && event.participantName === "EXPERT";

    const conferenceCallSucceeded = event.callStatus === "in-progress" && event.participantName !== "EXPERT";

    const expertNotAnswered = event.callStatus === "no-answer" && event.participantName === "EXPERT";

    if (expertNotAnswered) {
        taskLogger.debug("Call Events Handler | ExpertNoAnswer");
        return;
    }
    if (outboundCallSucceeded || conferenceCallSucceeded) {
        // Outbound calls use an mdn input to create a new task, thus pass the task.mdn to destination
        // Conference calls use participant.Name to create new participant thus pass participantName to destination
        const destination = formatSip(outboundCallSucceeded ? task.callerId : event.participantName);

        void analyticsDispatcher
            .dispatchBusinessEvent("OutboundCallSucceeded", {
                callStatus: event.callStatus,
                destination,
                participantCallId: event.callId,
            })
            .then(() => {
                taskLogger.info(
                    { analyticsEventName: "OutboundCallSucceeded" },
                    "Call Events Handler | OutboundCallSucceeded analytics dispatched",
                );
            });
    }

    if (
        event.callStatus === "canceled" ||
        event.callStatus === "failed" ||
        event.callStatus === "busy" ||
        event.callStatus === "no-answer"
    ) {
        void analyticsDispatcher
            .dispatchBusinessEvent("OutboundCallFailed", {
                callStatus: event.callStatus,
                destination: event.participantName,
                participantCallId: event.callId,
            })
            .then(() => {
                taskLogger.info(
                    { analyticsEventName: "OutboundCallFailed" },
                    "Call Events Handler | OutboundCallFailed analytics dispatched",
                );
            });
    }

    // todo: don't query `conferenceParticipants` multiple times by `type`
    const activeConferenceParticipant = task.conferenceParticipants.find(
        (p) => p.type === "active" && p.participantCallId === event.callId,
    );
    if (activeConferenceParticipant) {
        taskLogger.debug(
            "Call Events Handler | Receive call event for the participant that is a part of the voice task conference, skipping",
        );
        return;
    }

    if (event.participantName === "EXPERT") {
        taskLogger.debug("Call Events Handler | Agent call event received");
        return;
    }

    const pendingParticipant = task.conferenceParticipants.find(
        (p) => p.type === "pending" && p.participantCallId === event.callId,
    );
    const inactiveParticipant = task.conferenceParticipants.find(
        (p) => p.type === "inactive" && p.participantCallId === event.callId,
    );
    if (inactiveParticipant) return;
    if (pendingParticipant) {
        // Call changed from pending to inactive. Call changes from pending to active are handled by conference events
        if ((inactiveConferenceParticipantStatuses as readonly string[]).includes(event.callStatus)) {
            useSessionStore.getState().upsertConferenceParticipant(event.taskId, {
                type: "inactive",
                participantName: event.participantName,
                participantCallId: event.callId,
                status: event.callStatus as InactiveConferenceParticipantStatus,
            });
            taskLogger.info(
                `Call Events Handler | Participant ${pendingParticipant.participantCallId} removed from the store`,
            );
            return;
        }

        // TODO: Skip according to hierarchy
        if (event.callStatus !== pendingParticipant.status) {
            useSessionStore.getState().upsertConferenceParticipant(event.taskId, {
                type: "pending",
                participantName: event.participantName,
                participantCallId: event.callId,
                status: event.callStatus as PendingConferenceParticipantStatus,
            });
            taskLogger.info(
                `Call Events Handler | Participant ${pendingParticipant.participantCallId} status updated to ${event.callStatus}`,
            );
        }
    } else if (pendingConferenceParticipantStatuses.includes(event.callStatus as PendingConferenceParticipantStatus)) {
        useSessionStore.getState().upsertConferenceParticipant(task.id, {
            type: "pending",
            participantName: event.participantName,
            participantCallId: event.callId,
            status: event.callStatus as PendingConferenceParticipantStatus,
        });
        taskLogger.info(`Call Events Handler | Participant ${event.callId} added to pending participants`);
    }

    taskLogger.debug("Call Events Handler | Received call event for the task that is not handled by any conditions");
    taskLogger.trace("Call Events Handler | Execution ended");
}
