import { type ReadonlyDeep } from "type-fest";
import { type AppConfig } from "./types";

let appConfigSingleton: AppConfig | undefined;

export const getAppConfigSingleton = function () {
    if (!appConfigSingleton) throw new Error("Tried to get appConfigSingleton when it’s undefined.");
    return appConfigSingleton;
};

function deepFreeze<T>(object: NonNullable<T>): ReadonlyDeep<T> {
    // Freeze properties before freezing self
    for (const key of Reflect.ownKeys(object)) {
        const value: unknown = object[key as keyof typeof object];
        if ((value && typeof value === "object") || typeof value === "function") {
            deepFreeze(value);
        }
    }

    return Object.freeze(object) as ReadonlyDeep<T>;
}

export const freezeAppConfigSingleton = function (appConfig: AppConfig) {
    appConfigSingleton = appConfig;
    deepFreeze(appConfigSingleton);
};
