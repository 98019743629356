export type ConferenceParticipantType = "pending" | "active" | "inactive";

export const pendingConferenceParticipantStatuses = ["queued", "initiated", "ringing"] as const;
export const activeConferenceParticipantStatuses = ["in-progress"] as const;
export const inactiveConferenceParticipantStatuses = ["completed", "canceled", "failed", "busy", "no-answer"] as const;

// this magic incantation will create a union from that array
export type PendingConferenceParticipantStatus = (typeof pendingConferenceParticipantStatuses)[number];
export type ActiveConferenceParticipantStatus = (typeof activeConferenceParticipantStatuses)[number];
export type InactiveConferenceParticipantStatus = (typeof inactiveConferenceParticipantStatuses)[number];

type HoldState = { since: number } | undefined;

interface ConferenceParticipantBase {
    participantCallId: string;
    participantName: string;
    muted?: boolean;
    hold?: HoldState;
}

export interface PendingConferenceParticipant extends ConferenceParticipantBase {
    type: "pending";
    status: PendingConferenceParticipantStatus;
}

export interface ActiveConferenceParticipant extends ConferenceParticipantBase {
    type: "active";
    status: ActiveConferenceParticipantStatus;
    hold: HoldState;
}

export interface InactiveConferenceParticipant extends ConferenceParticipantBase {
    type: "inactive";
    status: InactiveConferenceParticipantStatus;
}

export type ConferenceParticipant =
    | PendingConferenceParticipant
    | ActiveConferenceParticipant
    | InactiveConferenceParticipant;

export interface UpdateParticipantOptions {
    hold?: boolean;
    endConferenceOnExit?: boolean;
}
