import { Group } from "@mantine/core";
import { BackIcon, CloseIcon, RestartIcon } from "../assets";
import { GuideNavButton } from "./GuideNavButton";

interface GuideNavigationProps {
    hasPreviousSteps: boolean;
    onBack: VoidFunction;
    onRestart: VoidFunction;
    onClose: VoidFunction;
}

export function GuideNavigation({ hasPreviousSteps, onBack, onRestart, onClose }: GuideNavigationProps) {
    return (
        <Group justify="flex-end" gap="0rem">
            {hasPreviousSteps ? (
                <>
                    <GuideNavButton key="guide-navigation-button-back" tooltip="Go back" onClick={onBack}>
                        <BackIcon />
                    </GuideNavButton>
                    <GuideNavButton key="guide-navigation-button-restart" tooltip="Restart guide" onClick={onRestart}>
                        <RestartIcon />
                    </GuideNavButton>
                </>
            ) : null}
            <GuideNavButton key="guide-navigation-button-close" tooltip="Close guide" onClick={onClose}>
                <CloseIcon />
            </GuideNavButton>
        </Group>
    );
}
