import { type Partner } from "./partner";

export const GLOBAL_CODE_LENGTH = 1;
export const PREFIX_LENGTH = 3;
export const TFN_LENGTH = 10;
export const US = "+1";
export const VDN_LENGTH = 7;
export const VDN_PREFIX = "555"; //Internal VDN's are prefixed by 555
export const VDN_PREFIX_LENGTH = 3;

export const MDN_MASK = "+{1} (000) 000-0000";
export const VDN_MASK = "000-0000";
export const NO_MASK = "";

export const PHONE_MAX_LENGTH = MDN_MASK.length - 2;
export const VDN_MAX_LENGTH = VDN_MASK.length;

export interface Contact {
    name: string;
    number: string;
    vdn: string;
    group?: Group;
    partner?: Partner | "NOT FOUND";
    isInternal?: boolean;
    // Internal numbers are SIP's that can only be accessed through the Asurion network
}

// We receive data from the CCTS mapping table and normalize it to the
// format we need for EDS
export interface CctsContact {
    friendlyName: string;
    destination: string;
    vdn: string;
    partner?: Partner;
    group?: Group;
}

export interface ContactGroup {
    group: Group;
    items: string[];
}

export type Group = "VHDP" | "5G" | "Claims / Warranty" | "Tech Support" | "Customer Service" | "AT&T" | "Unlisted";

export const TRANSFER_OPTIONS: Contact[] = [
    {
        group: "VHDP",
        vdn: "1820158",
        name: "VHDP Support English",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820158`,
    },
    {
        group: "VHDP",
        vdn: "1820159",
        name: "VHDP Support Spanish",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820159`,
    },
    {
        group: "VHDP",
        vdn: "1820481",
        name: "VHDP Saves Desk English",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820481`,
    },
    {
        group: "VHDP",
        vdn: "1820482",
        name: "VHDP Saves Desk Spanish",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820482`,
    },
    {
        group: "VHDP",
        vdn: "1820144",
        name: "VHDP Spanish",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820144`,
    },
    {
        group: "VHDP",
        vdn: "1820150",
        name: "Verizon Home Device Protect English",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820150`,
    },
    {
        group: "VHDP",
        vdn: "1820153",
        name: "Connected Home+ Support",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820153`,
    },

    {
        group: "5G",
        vdn: "1820147",
        name: "5G Support English",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820147`,
    },
    {
        group: "5G",
        vdn: "1820148",
        name: "5G Support Spanish",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820148`,
    },
    {
        group: "Claims / Warranty",
        vdn: "1820169",
        name: "Verizon Handset Claims English",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820169`,
    },
    {
        group: "Claims / Warranty",
        vdn: "1820171",
        name: "Verizon Handset Claims Spanish",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820171`,
    },
    {
        group: "Claims / Warranty",
        vdn: "1820269",
        name: "Device Replacement Warranty English (DRW)",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820269`,
    },
    {
        group: "Claims / Warranty",
        vdn: "1820273",
        name: "Device Replacement Warranty Spanish (DRW)",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820273`,
    },
    {
        group: "Tech Support",
        vdn: "8662717730",
        name: "Tier 2",
        isInternal: false,
        partner: "asurion",
        number: "8662717730",
    },
    {
        group: "Tech Support",
        vdn: "8662991974",
        name: "Asurion Internal IT",
        isInternal: false,
        partner: "asurion",
        number: "8662991974",
    },
    {
        group: "Tech Support",
        vdn: "1820126",
        name: "Tech Coach Spanish",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820126`,
    },
    {
        group: "Tech Support",
        vdn: "1820124",
        name: "Verizon Tech Coach English",
        isInternal: true,
        partner: "verizon",
        number: `${VDN_PREFIX}1820124`,
    },
    {
        group: "Tech Support",
        vdn: "1825368",
        name: "Tech Lead",
        isInternal: true,
        partner: "asurion",
        number: `${VDN_PREFIX}1825368`,
    },
    {
        group: "Tech Support",
        vdn: "1825369",
        name: "CST",
        isInternal: true,
        partner: "asurion",
        number: `${VDN_PREFIX}1825369`,
    },

    {
        group: "Customer Service",
        vdn: "8009220204",
        name: "Verizon Customer Service",
        isInternal: false,
        partner: "verizon",
        number: "8009220204",
    },
    {
        group: "AT&T",
        vdn: "8889089179",
        name: "AT&T Support",
        isInternal: false,
        partner: "att",
        number: "8889089179",
    },
    {
        group: "AT&T",
        vdn: "8006947466",
        name: "Apple Certified",
        isInternal: false,
        partner: "att",
        number: "8006947466",
    },
    {
        group: "AT&T",
        vdn: "8009019878",
        name: "ATT Prepaid Customer Care",
        isInternal: false,
        partner: "att",
        number: "8009019878",
    },
    {
        group: "AT&T",
        vdn: "1972337",
        name: "MPP Claims (ENG)",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820467`,
    },
    {
        group: "AT&T",
        vdn: "1972360",
        name: "MPP Claims Spanish",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820473`,
    },
    {
        group: "AT&T",
        vdn: "1972333",
        name: "MDPP Claims",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820469`,
    },
    {
        group: "AT&T",
        vdn: "1972335",
        name: "MDPP Claims Spanish",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820471`,
    },
    {
        group: "AT&T",
        vdn: "1982731",
        name: "SUR Tech",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820105`,
    },
    {
        group: "AT&T",
        vdn: "1982733",
        name: "SUR Tech Spanish",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820107`,
    },
    {
        group: "AT&T",
        vdn: "1982732",
        name: "SUR MDPP Tech",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820106`,
    },
    {
        group: "AT&T",
        vdn: "1982734",
        name: "SUR MDPP Tech Spanish",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820108`,
    },
    {
        group: "AT&T",
        vdn: "8772552377",
        name: "BlackBerry Support",
        isInternal: false,
        partner: "att",
        number: "8772552377",
    },
    {
        group: "AT&T",
        vdn: "8889874357",
        name: "Samsung Customer Support",
        isInternal: false,
        partner: "att",
        number: "8889874357",
    },
    {
        group: "AT&T",
        vdn: "8003316456",
        name: "Motorola Customer Support",
        isInternal: false,
        partner: "att",
        number: "8003316456",
    },
    {
        group: "AT&T",
        vdn: "8007345870",
        name: "Motorola Android Support",
        isInternal: false,
        partner: "att",
        number: "8007345870",
    },
    {
        group: "AT&T",
        vdn: "8007345875",
        name: "Motorola Android Warranty Support",
        isInternal: false,
        partner: "att",
        number: "8007345875",
    },
    {
        group: "AT&T",
        vdn: "1972595",
        name: "Prepaid Enrollments/Claims ENG",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820112`,
    },
    {
        group: "AT&T",
        vdn: "1972596",
        name: "Prepaid Enrollments/Claims SPA",
        isInternal: true,
        partner: "att",
        number: `${VDN_PREFIX}1820113`,
    },
];

export const vdnToPartner: ReadonlyMap<string, Partner> = new Map<string, Partner>([
    ["1871473", "att"],
    ["1985556", "att"],
    ["1972291", "att"],
    ["1972292", "att"],
    ["1972677", "att"],
    ["6690178", "att"],
    ["1972302", "att"],
    ["1875401", "att"],
    ["1972676", "att"],
    ["1720069", "att"],
    ["1972436", "att"],
    ["1972678", "att"],
    ["1874226", "att"],
    ["1972305", "att"],
    ["1972588", "att"],
    ["1982239", "att"],
    ["1972440", "att"],
    ["1871472", "att"],
    ["6690179", "att"],
    ["1972675", "att"],
    ["1982741", "att"],
    ["1972669", "att"],
    ["1972586", "att"],
    ["1871474", "att"],
]);

export const PhoneNumberFormattedMask = "(###) ###-####";

export interface MaxLengthState {
    enforce: boolean;
    length?: number;
}

export type InputOption = "PhoneNumber" | "Extension" | "SpeedDial" | "None";
