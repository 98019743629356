import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import type { BaseNotificationType } from "../models";

interface NotificationStore {
    notifications: BaseNotificationType[];
    addNotification: (notification: BaseNotificationType) => void;
    removeNotification: (id: string) => void;
}

export const useNotificationStore = create<NotificationStore>()(
    immer((set) => ({
        notifications: [],
        addNotification: (notification: BaseNotificationType) => {
            set((state) => {
                state.notifications = [...state.notifications, notification];
            }, false);
        },
        removeNotification: (id: string) => {
            set((state) => {
                state.notifications = state.notifications.filter((n) => n.id !== id);
            }, false);
        },
    })),
);
