import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import type { IntegratedSalesSteps } from "../components/types";

export interface CurrentStepStore {
    integratedStep: IntegratedSalesSteps;
    updateIntegratedStep: (step: IntegratedSalesSteps) => void;
    resetIntegratedStepStore: () => void;
}

export const useIntegratedFlowStore = create<CurrentStepStore>()(
    persist(
        devtools(
            (set) => ({
                integratedStep: "SuggestedOfferStep",
                updateIntegratedStep: (integratedStep: IntegratedSalesSteps) =>
                    set({ integratedStep }, false, "updateIntegratedStep"),
                resetIntegratedStepStore: () =>
                    set({ integratedStep: "SuggestedOfferStep" }, false, "resetIntegratedStepStore"),
            }),
            {
                enabled: import.meta.env.MODE !== "production",
                store: "integrated-flow-store",
                name: "workspace/sales-tools",
            },
        ),
        { name: "integrated-flow-store", skipHydration: true },
    ),
);
