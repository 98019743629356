export function GreenCheckMark() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" viewBox="-2 -2 16 16">
            <path
                fill="#6EFAC3"
                fillRule="evenodd"
                d="M12.202 1.603 3.9 11.63.206 7.744l1.087-1.033 2.53 2.66L11.046.647l1.155.956Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
