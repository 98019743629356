import { Button, Flex, Text } from "@mantine/core";
import React, { useState } from "react";
import { withPolymorphicClickAnalytics } from "../../../analytics";
import { usePartner, useSession } from "../../../sdk";
import {
    createEnrollment,
    type EligibleProduct,
    postDeclination,
    useHomeProductSessionStore,
} from "../../home-product";
import { QuotationMarkIcon } from "../../home-product/components/Icons";
import { type Enrollment, EnrollmentStatus, SaleProgress } from "../../home-product/types";

export function EnrollmentCard({ product }: { product: EligibleProduct }): JSX.Element {
    const session = useSession();
    const [enrolling, setEnrolling] = useState(false);
    const [declining, setDeclining] = useState(false);
    const updateProduct = useHomeProductSessionStore((state) => state.updateProduct);
    const AnalyticsButton = withPolymorphicClickAnalytics(Button, "ButtonClick");
    const partner = usePartner();

    const enrollmentDetails: Enrollment = {
        callId: session.id,
        sessionId: session.id,
        partner,
        productOfferId: product.id,
    };

    const enroll = async () => {
        try {
            const { enrollmentStatus } = await createEnrollment(enrollmentDetails);
            const saleProgress =
                enrollmentStatus === EnrollmentStatus.Success ? SaleProgress.Success : SaleProgress.Failure;
            updateProduct({ ...product, ...{ saleProgress } });
        } catch {
            // ignore
        } finally {
            setEnrolling(false);
        }
    };
    const decline = async () => {
        try {
            await postDeclination(enrollmentDetails);
            updateProduct({ ...product, ...{ saleProgress: SaleProgress.Declined } });
        } catch (e) {
            // ignore
        } finally {
            setDeclining(false);
        }
    };

    return (
        <Flex p="1rem" direction="column" bg="dark.4">
            <Text size="14px">Ask the customer</Text>
            <Flex maw="95%" justify="space-between" mt="10px">
                <QuotationMarkIcon />
                <Text mt="5px" maw="89%" fw={700} size="16px">
                    May I complete your enrollment today?
                </Text>
            </Flex>
            <Flex mt="2rem">
                <AnalyticsButton
                    analytics={{
                        actionId: "EnrollmentDeclineButton",
                        eventData: {
                            ProductOfferId: enrollmentDetails.productOfferId,
                        },
                    }}
                    color="white"
                    data-testid="enrollment-decline-button"
                    disabled={enrolling}
                    loading={declining}
                    onClick={() => {
                        setDeclining(true);
                        void decline();
                    }}
                    size="md"
                    variant="subtle"
                >
                    <Text td="underline" size="sm">
                        Customer declined
                    </Text>
                </AnalyticsButton>
                <AnalyticsButton
                    analytics={{
                        actionId: "EnrollmentAcceptButton",
                        eventData: {
                            ProductOfferId: enrollmentDetails.productOfferId,
                        },
                    }}
                    color="asurion.5"
                    data-testid="enrollment-accept-button"
                    disabled={declining}
                    loading={enrolling}
                    onClick={() => {
                        setEnrolling(true);
                        void enroll();
                    }}
                    radius="xl"
                    size="md"
                    variant="filled"
                >
                    Submit enrollment
                </AnalyticsButton>
            </Flex>
        </Flex>
    );
}
