import { getLogger } from "@expert/logging";
import ky, { type BeforeRequestHook, HTTPError } from "ky";
import { env } from "../../../config";
import { type IVRDetailsPayload } from "./types";

const logger = getLogger({
    module: "ivrDetails",
});

export function createHeadersHook(token: string): BeforeRequestHook {
    return ({ headers }) => {
        headers.set("Authorization", `Bearer ${token}`);
        headers.set("Content-Type", "application/json");
    };
}

export const getIVRDetails = async (callSid: string, token: string): Promise<IVRDetailsPayload | null> => {
    try {
        return ky
            .create({
                retry: 0,
                hooks: {
                    beforeRequest: [createHeadersHook(token)],
                },
            })
            .get(`${env.ivrDetailsUrl}/api/call-details?callSid=${callSid}`)
            .json<IVRDetailsPayload>();
    } catch (err: unknown) {
        if (err instanceof HTTPError) {
            logger.error({ statusCode: err.response.status }, `IVR details API request failed`);
            return null;
        }
        logger.error({ err }, `IVR details API failed with unexpected exception`);
        return null;
    }
};
