import type { SVGProps } from "react";

export function RightArrowIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0122 10.0001L12.2562 14.756L11.0777 13.5775L14.6551 10.0001L11.0777 6.42265L12.2562 5.24414L17.0122 10.0001Z"
                fill="#A5AAAF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.16699 9.16673L15.8337 9.16673V10.8334L4.16699 10.8334L4.16699 9.16673Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
