import type { SVGProps } from "react";

export function DropsIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.08785 4.83785C3.31565 4.61004 3.685 4.61004 3.9128 4.83785L7.00032 7.92537L10.0878 4.83785C10.3157 4.61004 10.685 4.61004 10.9128 4.83785C11.1406 5.06565 11.1406 5.435 10.9128 5.6628L7.4128 9.1628C7.185 9.39061 6.81565 9.39061 6.58785 9.1628L3.08785 5.6628C2.86004 5.435 2.86004 5.06565 3.08785 4.83785Z"
                fill="#FF6B6B"
            />
        </svg>
    );
}
