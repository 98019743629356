export function DeviceMoneyIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="none">
            <path
                stroke="#A5AAAF"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.333 6.667H8.905a1.236 1.236 0 0 0-.553 2.341l1.3.651A1.236 1.236 0 0 1 9.1 12H7.667M8.67 12v1M9.336 5.667v1"
            />
            <path
                stroke="#A5AAAF"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.003 16.667a7.667 7.667 0 1 0 0-15.334 7.667 7.667 0 0 0 0 15.334Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
