import { env } from "../../../config";
import { getSessionId } from "../../sdk";
import { api } from "../../shared-utils";

export const getCustomerDetailsApi = () => {
    return api.extend(() => ({
        prefixUrl: env.customerDetailsBaseApiUrl,
        hooks: {
            beforeRequest: [
                (request) => {
                    request.headers.set("x-ewp-correlation-id", getSessionId());
                },
            ],
        },
    }));
};
