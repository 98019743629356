import { useQuery } from "@tanstack/react-query";
import { getAvatar } from "../api";
import { useContentfulClient } from "../ContentfulClientProvider";

export const useCustomerAvatar = (sessionId: string): string | undefined => {
    const contentfulClient = useContentfulClient();
    const { data } = useQuery({
        queryKey: [`customer-profile-${sessionId}`],
        queryFn: async () => await getAvatar(contentfulClient, sessionId),
        staleTime: Infinity,
    });
    return data;
};
