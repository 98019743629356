import { getLogger } from "@expert/logging";
import { Text, TextInput } from "@mantine/core";
import type { Contact } from "../../shared-types";
import { formatPhoneNumber, validatePhoneNumber } from "../../shared-utils";
import styles from "./PhoneNumber.module.css";

const logger = getLogger({
    module: "PhoneNumber",
});

interface PhoneNumberProps {
    mdn: string;
}

interface EditablePhoneNumberProps {
    mdn: string;
    customerLeft?: boolean;
}

interface VdnNameProps {
    vdn: Contact;
}

const unknownMdnFallback = "Unknown";

function validateAndFormatMdn(mdn: string) {
    if (!validatePhoneNumber(mdn).valid) {
        logger.error(`Unexpected MDN received: ${mdn}`);
        return unknownMdnFallback;
    }
    return formatPhoneNumber(mdn);
}

export function PhoneNumber({ mdn }: PhoneNumberProps): JSX.Element {
    const formattedPhoneNumber = validateAndFormatMdn(mdn);
    const testId = formattedPhoneNumber === unknownMdnFallback ? "unexpected-phone-number" : "phone-number";
    return <span data-testid={testId}>{formattedPhoneNumber}</span>;
}

export function EditablePhoneNumber({ mdn, customerLeft }: EditablePhoneNumberProps): JSX.Element {
    const defaultText = validateAndFormatMdn(mdn);
    const testId = defaultText === unknownMdnFallback ? "unexpected-editable-phone-number" : "editable-phone-number";
    return (
        <TextInput
            className="fs-mask"
            c="white"
            classNames={{ input: styles.phoneNumberTextInput }}
            data-customerleft={customerLeft}
            data-testid={testId}
            defaultValue={defaultText}
            fw="var(--mantine-font-weight-heavy)"
            variant="unstyled"
        />
    );
}

export function VdnName({ vdn: contact }: VdnNameProps): JSX.Element {
    const hasFriendlyName = contact.group !== "Unlisted"; // TODO: Unlisted VDN's don't have easily understandable names (yet)
    const lastFour = contact.vdn.slice(-4);
    return (
        <Text
            className="fs-mask"
            classNames={{ root: styles.phoneNumberVdnName }}
            data-testid="vdn-name"
            fw="var(--mantine-font-weight-heavy)"
        >
            {hasFriendlyName ? contact.name : `Extension ${lastFour}`}
        </Text>
    );
}
