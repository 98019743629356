import { useEffect, useState } from "react";
import useInterval from "react-use/lib/useInterval";
import { formatTime } from "../formatters";

/**
 * A hook that returns the time elapsed since the startTime. If no start time is provided the timer will start from 0.
 * Hiding a stopwatch with no start time should be intentionally handled by the consuming component.
 * @param startTime -:string epoch time-string in ms that the timer should start from
 * @param appendUnit - (false default) Whether to append the unit to the formatted string (ie. sec or min)
 * @param format - (mm:ss default) The format to use see: https://day.js.org/docs/en/display/format
 * @returns the time elapsed since startTime in a pretty format
 */

export function useStopwatch(startTime?: number | null, appendUnit = false, format?: string): string {
    const [secondsElapsed, setSecondsElapsed] = useState(0);

    useInterval(
        () => setSecondsElapsed(startTime ? Math.floor((Date.now() - startTime) / 1000) : 0),

        // ! a simple `setSecondsElapsed((s) => s + 1)` counter had issues with the time being incorrect when the system sleeps, lags, or locks
        // ! we can use the window.freeze and window.resume events to pause the timer if we need performance improvements
        1000,
    );

    useEffect(() => {
        setSecondsElapsed(startTime ? Math.floor((Date.now() - startTime) / 1000) : 0);
    }, [startTime]);

    return formatTime(secondsElapsed, appendUnit, format);
}
