import { Button, Group, Text } from "@mantine/core";
import { ArrowRectangleDown, ArrowRectangleUp, PhoneNumber } from "../../../common-ui";
import type { VoiceTask } from "../../../sdk";
import { getConferenceCallersCount } from "../../utils";
import { useControlsStore } from "../controls.store";
import { CallDuration } from "./CallDuration";
import classes from "./StandardCallBar.module.css";

interface CallBarProps {
    task: VoiceTask;
}

export function StandardCallBar({ task }: CallBarProps): JSX.Element {
    const { isConference, conferencePanelOpen, toggleConferencePanelOpen } = useControlsStore();

    const conferenceCallersCount = getConferenceCallersCount(task);
    const conferenceCallersCountText = conferenceCallersCount > 1 ? "participants" : "participant";
    return (
        <Group gap="0">
            {isConference ? (
                <Button
                    classNames={{ root: classes.conferenceButtonRoot, section: classes.conferenceButtonSection }}
                    data-testid="call-controls-conference-toggle"
                    variant="transparent"
                    pl="0"
                    pr="xs"
                    color="white"
                    size="xs"
                    onClick={toggleConferencePanelOpen}
                    rightSection={conferencePanelOpen ? <ArrowRectangleUp /> : <ArrowRectangleDown />}
                >
                    <Text fw={700} size="lg" td="underline">
                        {conferenceCallersCount} {conferenceCallersCountText}
                    </Text>
                </Button>
            ) : (
                <Text className="fs-mask" fw={700} mr="sm" size="md">
                    <PhoneNumber mdn={task.callerId} />
                </Text>
            )}
            {task.status === "assigned" ? <CallDuration task={task} /> : null}
        </Group>
    );
}
