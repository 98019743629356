import { getLogger } from "@expert/logging";
import { Button, Menu, Space, Text } from "@mantine/core";
import { useClipboard, useDisclosure } from "@mantine/hooks";
import { FeedbackModal } from "@soluto-private/expert-workspace-feedback";
import { useEffect, useMemo, useState } from "react";
import { useFeatures, type WorkspaceFeatures } from "@expert/features";
import { SoundNotificationsSwitch } from "@expert/proactive-notifications";
import { useAnalytics } from "../../analytics";
import { CaretDown, CaretUp, CopyIcon, Exit, Megaphone, UserInitial, useToast } from "../../common-ui";
import {
    getSessionId,
    getSessionIdHistory,
    sdkEventBus,
    useAgentActivity,
    useAgentStore,
    useOptionalActiveVoiceTask,
    usePartner,
} from "../../sdk";
import { classNames, formatAgentName, userCache } from "../../shared-utils";
import { useFeedbackNotification } from "../../feedback";
import classes from "./Profile.module.css";
import { env } from "./environment";

const logger = getLogger({ module: "Profile" });

interface ProfileProps {
    isProdMode: boolean;
    commitHash: string;
}

export function Profile({ commitHash }: ProfileProps): JSX.Element {
    const {
        features: { isProactiveEnabled },
    } = useFeatures<WorkspaceFeatures>();

    const agentName = useAgentStore((state) => formatAgentName(state.agentName));
    const { activity } = useAgentStore();
    const { dispatcher } = useAnalytics();
    const partner = usePartner();
    const clipboard = useClipboard({ timeout: 500 });
    const triggerFeedbackNotification = useFeedbackNotification();

    const sessionId = getSessionId();
    const WorkerId = useAgentStore.getState().agentId;
    const Username = useAgentStore.getState().agentName;
    const voiceTask = useOptionalActiveVoiceTask();
    const ssoAccessToken = userCache.ssoAccessToken ?? "";
    const toast = useToast();
    const { setNewAgentActivity } = useAgentActivity();

    const [menuOpen, setMenuOpen] = useState(false);
    const loggerWithContext = useMemo(
        () =>
            logger.child({
                sessionId,
                callSid: voiceTask?.agentCallId,
            }),
        [sessionId, voiceTask?.agentCallId],
    );

    const copyToDebug = () => {
        clipboard.copy(
            JSON.stringify({
                SessionId: sessionId || "undefined",
                WorkerId: WorkerId || "undefined",
                UserName: Username || "undefined",
                TaskSid: voiceTask?.id ?? "undefined",
                CallSid: voiceTask?.agentCallId ?? "undefined",
                CommitHash: commitHash || "undefined",
                Mdn: voiceTask?.mdn ?? "undefined",
                Timestamp: new Date().toISOString(),
            }),
        );
        toast.success("Copied debug info to clipboard!");
        setMenuOpen(false);
    };

    function handleProfileMenuView() {
        void dispatcher.dispatchAction("Click", "agent-profile-menu-view");
    }

    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => {
            void dispatcher.dispatchAction("Click", "ShareFeedback", { source: "Menu" });
            loggerWithContext.info("Feedback modal in Menu opened");
            setMenuOpen(false);
        },
        onClose: () => {
            triggerFeedbackNotification("success");
            loggerWithContext.info("Feedback modal in Menu closed");
        },
    });
    const error = () => {
        triggerFeedbackNotification("error");
    };

    const logOff = async () => {
        await setNewAgentActivity("Offline");
        setMenuOpen(false);
    };

    useEffect(() => {
        // to handle the case of closing the modal on an incoming call or when the task
        // changes to a wrapping state (meaning the session summary modal would be opening)
        const subscriptions = [
            sdkEventBus.on("task_assigned", () => {
                close();
            }),
            sdkEventBus.on("task_wrapping", () => {
                close();
            }),
        ];

        return () => {
            subscriptions.forEach((unsub) => {
                unsub();
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSoundNotificationsClick = (isMuted: boolean) => {
        loggerWithContext.info("Sound notifications toggled");
        void dispatcher.dispatchAction("Click", "sound-notification-toggle", { isMuted });
    };

    const formattedVersionNumber = env.versionNumber.startsWith("v")
        ? `v${env.versionNumber.substring(3)}`
        : env.versionNumber;

    return (
        <>
            <FeedbackModal
                isOpen={opened}
                onClose={close}
                onError={error}
                feedbackPayload={{
                    appName: "expert-workspace",
                    caller: "PlatformHeader",
                    callSid: voiceTask?.agentCallId,
                    partner,
                    sessionId,
                    ssoAccessToken,
                    extraData: {
                        "Previous Sessions": JSON.stringify(getSessionIdHistory(5)),
                        "Workspace Version": formattedVersionNumber,
                    },
                }}
                logger={loggerWithContext.child({ supportTeam: "solve" })}
            />
            <Menu
                shadow="sm"
                radius="md"
                zIndex="var(--mantine-priority-highest)"
                opened={menuOpen}
                onChange={setMenuOpen}
                closeOnClickOutside
                closeOnItemClick={false}
            >
                <Menu.Target>
                    <Button
                        color="gray"
                        data-testid="agent-profile-menu"
                        leftSection={<UserInitial name={agentName} />}
                        mr="lg"
                        className={classNames(classes.profileBtn, opened ? classes.profileBtnOpen : null)}
                        onClick={handleProfileMenuView}
                        size="sm"
                        variant="subtle"
                    >
                        <Text data-expert-name={agentName} size="xs">
                            {agentName}
                        </Text>
                        <Space w="xs" />
                        {menuOpen ? <CaretUp /> : <CaretDown />}
                    </Button>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item leftSection={<Megaphone />} key="Share your feedback" onClick={open}>
                        <Text size="sm">Share your feedback</Text>
                    </Menu.Item>
                    {isProactiveEnabled && <SoundNotificationsSwitch onClick={onSoundNotificationsClick} />}
                    <Menu.Item
                        leftSection={<CopyIcon width={20} height={21} />}
                        key="Copy Troubleshooting"
                        onClick={copyToDebug}
                    >
                        <Text size="sm">Copy troubleshoot info</Text>
                    </Menu.Item>
                    <Menu.Item
                        leftSection={<Exit height={20} width={20} fill="var(--mantine-color-red-5)" />}
                        key="Log Off"
                        color="red.5"
                        onClick={logOff}
                        disabled={activity === "Offline"}
                    >
                        <Text size="sm">End your shift</Text>
                    </Menu.Item>
                    <Text mb="sm" size="xs" className={classes.versionText}>
                        {formattedVersionNumber}
                    </Text>
                </Menu.Dropdown>
            </Menu>
        </>
    );
}
