import { Flex } from "@mantine/core";
import { type ReactNode, useEffect } from "react";
import styles from "./BaseNotification.module.css";

export function BaseNotification({
    children,
    side,
    width,
    isClosing,
    onClose,
}: {
    children: ReactNode;
    side: "left" | "right";
    isClosing: boolean;
    onClose: () => void;
    width?: string;
}) {
    useEffect(() => {
        // set style to dismissed
        if (isClosing) {
            setTimeout(onClose, 500);
        }
    }, [isClosing, onClose]);

    return (
        <Flex
            w={width ?? "31.75rem"}
            className={`${isClosing ? styles.dismissed : styles.opened} ${styles.baseNotification} ${side === "left" ? styles.alignSelfStart : styles.alignSelfEnd}`}
            ml="1%"
            mr="1%"
        >
            {children}
        </Flex>
    );
}
