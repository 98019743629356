import { ActionIcon, CopyButton } from "@mantine/core";
import { CheckMark, CopyIcon } from "../assets";

export function GuideCopyButton({ value }: { value: string }): JSX.Element | null {
    return (
        <CopyButton value={value}>
            {({ copied, copy }) => {
                return (
                    <ActionIcon color="text" onClick={copy} variant="subtle" id="guide-copy-action-icon">
                        {copied ? <CheckMark height="1rem" /> : <CopyIcon height="1rem" />}
                    </ActionIcon>
                );
            }}
        </CopyButton>
    );
}
