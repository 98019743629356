import type { SVGProps } from "react";

export function CaretUp({ height = 8, width = 12, fill, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" {...rest}>
            <path
                fill={fill ?? "#A839F3"}
                fillRule="evenodd"
                d="M6 .586.293 6.293l1.414 1.414L6 3.414l4.293 4.293 1.414-1.414L6 .586Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
