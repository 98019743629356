import { Group, Stack, Text } from "@mantine/core";
import { useActiveVoiceTask, useAgentSdk } from "../../../../sdk";
import { classNames, extractNumbers } from "../../../../shared-utils";
import { AddParticipantButton, HoldButton, RemoveParticipantButton } from "../../Buttons";
import sharedStyles from "../../sharedStyles.module.css";
import { useVerifiedCustomerStore } from "../../../../customer-info";
import styles from "./ParticipantControls.module.css";
import { ParticipantLabel } from "./ParticipantLabel";

export function PrimaryParticipantControls(): JSX.Element {
    const agentSdk = useAgentSdk();
    const activeTask = useActiveVoiceTask();

    const { customer } = useVerifiedCustomerStore();
    const customerLabel = customer?.fullName ?? "Primary caller";
    const customerLeft = activeTask.customerLeftTheConference;

    return (
        <Group
            classNames={{
                root: classNames(
                    styles.participantContainer,
                    customerLeft ? styles.disabledBackground : styles.primaryBackground,
                ),
            }}
            data-testid="primary-participant-controls"
            wrap="nowrap"
        >
            <Stack gap="1px">
                <Text size="xs" c={customerLeft ? "gray.6" : "white"}>
                    {customerLabel} {!!customerLeft && "dropped"}
                </Text>
                <Group gap="xs">
                    <ParticipantLabel mdn={activeTask.callerId} customerLeft={customerLeft} />
                </Group>
            </Stack>
            <span className={sharedStyles.stretch} />
            {customerLeft ? (
                <AddParticipantButton
                    size="sm"
                    task={activeTask}
                    disabled={false}
                    transferTfn={extractNumbers(activeTask.callerId).slice(-10)}
                    isPrimary
                />
            ) : (
                <>
                    <HoldButton
                        task={activeTask}
                        outlined
                        isOnHold={activeTask.holdState.onHold}
                        onPauseCallButtonClick={() => agentSdk.holdCall(activeTask)}
                        onResumeCallButtonClick={() => agentSdk.resumeCall(activeTask)}
                    />
                    <RemoveParticipantButton
                        onClick={() => agentSdk.hangupCall(activeTask, "Remove primary participant from conference")}
                        disabled={activeTask.holdState.onHold}
                    />
                </>
            )}
        </Group>
    );
}
