import { Button, Flex, Text } from "@mantine/core";
import { WorkspaceFullScreenError } from "../../../common-ui/error/WorkspaceFullScreenError";

export function EvlDisconnectedErrorPage() {
    return (
        <WorkspaceFullScreenError error="EVL Failed to connect">
            <Text>
                <Text fw="bold" span>
                    Your audio is disconnected—check your EVL settings.
                </Text>{" "}
                Make sure EVL is open and the correct audio devices are selected.
            </Text>
            <Flex direction="row" gap="sm" justify="right">
                <Button size="lg" variant="filled" onClick={() => window.location.reload()}>
                    Refresh this page
                </Button>
            </Flex>
        </WorkspaceFullScreenError>
    );
}
