import { Button, Flex, Input, Text } from "@mantine/core";
import { IMaskInput } from "react-imask";
import { useState } from "react";
import { useAnalytics, withPolymorphicClickAnalytics } from "../../../analytics";
import { formatPhoneNumber, useFeatureFlag, validatePhoneNumber } from "../../../shared-utils";
import classes from "./CustomerVerificationUpdated.module.css";

interface MdnSectionProps {
    mdn: string;
    onComplete: (input: string) => void;
    onSave: (input: boolean) => void;
}

export function SimplifiedMdnSection({ mdn, onComplete, onSave }: MdnSectionProps): JSX.Element {
    const ButtonWithAnalytics = withPolymorphicClickAnalytics(Button, "Click");
    const { dispatcher } = useAnalytics();
    const [isInEditMode, setIsInEditMode] = useState(true);
    const [inputMdn, setInputMdn] = useState<string>(mdn);
    const [error, setError] = useState<string | null>(null);
    const { data: blacklistedNumbers } = useFeatureFlag<string>("EWP-isBlacklistedNumber");
    const { data: blacklistedPrefixes } = useFeatureFlag<string>("EWP-blacklistedPrefixes");

    function onClickSave() {
        const strippedInputMdn = inputMdn.replace(/[^\d+]/g, "");
        if (
            !!blacklistedPrefixes?.includes(strippedInputMdn.slice(0, 5)) ||
            blacklistedNumbers?.includes(strippedInputMdn)
        ) {
            void dispatcher.dispatchBusinessEvent("BlacklistedMdnSubmitted");

            setError("This isn’t the customer’s phone number. Please ask for the right one.");
            return;
        }
        setIsInEditMode(false);
        void dispatcher.dispatchBusinessEvent("MDN_Submitted");
        setError(null);
        onComplete(strippedInputMdn);
        onSave(false);
    }

    const onInputChange = ({ currentTarget: { value } }: React.ChangeEvent<HTMLInputElement>) => {
        setInputMdn(value);
    };
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && validatePhoneNumber(inputMdn).valid) {
            void dispatcher.dispatchUserAction("EnterKey", "MdnContinue");
            onClickSave();
        }
    };

    return (
        <Flex direction="column">
            <Text c="dark.1" size="18px" mb="1rem" mt="1rem">
                What’s the phone number they’re calling about?
            </Text>
            {isInEditMode ? (
                <>
                    <Input.Wrapper
                        data-testid="mdn-edit"
                        error={error}
                        className="fs-mask"
                        classNames={{
                            label: classes.label,
                        }}
                    >
                        <Input
                            /* eslint-disable-next-line jsx-a11y/no-autofocus */
                            autoFocus={mdn === ""}
                            size="lg"
                            disabled={!isInEditMode}
                            value={inputMdn}
                            rightSectionPointerEvents="all"
                            rightSectionWidth={100}
                            component={IMaskInput}
                            onKeyDown={onKeyDown}
                            onInput={onInputChange}
                            mask="+1 (000) 000-0000"
                            onChange={(event) => {
                                setInputMdn(event.currentTarget.value);
                            }}
                            classNames={{ wrapper: classes.styledInputWrapper, input: classes.styledInput }}
                        />
                    </Input.Wrapper>
                    <ButtonWithAnalytics
                        size="md"
                        mt="sm"
                        disabled={inputMdn ? !validatePhoneNumber(inputMdn).valid : true}
                        onClick={onClickSave}
                        analytics={{ actionId: "SavePhoneNumber" }}
                    >
                        Save
                    </ButtonWithAnalytics>
                </>
            ) : (
                <Flex align="center" justify="space-between">
                    <Text size="sm" c="dark.1" className="fs-mask">
                        {formatPhoneNumber(inputMdn)}
                    </Text>
                    <ButtonWithAnalytics
                        td="underline"
                        variant="subtle"
                        color="dark.1"
                        size="sm"
                        onClick={() => {
                            setIsInEditMode(true);
                            onSave(true);
                        }}
                        analytics={{ actionId: "change phone number" }}
                    >
                        Change
                    </ButtonWithAnalytics>
                </Flex>
            )}
        </Flex>
    );
}
