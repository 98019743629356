import type { GaiaSubscription, GaiaWebsocketContext } from "@expert/gaia";
import { useGaiaWebsocket } from "@expert/gaia";
import type { Logger } from "@expert/logging";
import { usePrevious } from "@mantine/hooks";
import {
    addMessage,
    formatTimestamp,
    getDefaultMessage,
    solveSessionClosed,
    solveSessionStarted,
} from "@soluto-private/expert-workspace-timeline";
import { type TimelineFeatures, useFeatures } from "@expert/features";
import { useEffect, useState } from "react";
import { retryAsync } from "ts-retry";
import { useSessionStore } from "../../../sdk/sessions/session.store";
import { type VoiceTask, useActiveTask, usePartner, useSession } from "../../../sdk";
import { formatTime } from "../../../shared-utils";

const subscriptionTimeoutDuration = 2000;
const subscriptionRetryCount = 3;

interface SessionLifecycleProps {
    features: Pick<TimelineFeatures, "isProactiveEnabled" | "isMultiModalEnabled">;
    partner: string;
    sessionId: string;
    websocketObj: GaiaWebsocketContext;
    withCustomer: boolean;
    logger: Logger;
    callSid?: string;
}

type SubscribeProps = {
    websocketObj: GaiaWebsocketContext;
} & Omit<GaiaSubscription, "sendJsonMessage" | "timeout">;

export const useSolveSession = (logger: Logger) => {
    const { id: sessionId, kind: sessionKind } = useSession();
    const partner = usePartner();
    const { features } = useFeatures<TimelineFeatures>();

    const previousSession = useSessionStore.getState().computed.previousSession();
    const isPreviousSessionWithCustomer = previousSession?.kind === "with-customer";
    const endedSessionStartTime = previousSession?.startedTimestamp;

    const websocketObj = useGaiaWebsocket();
    const task = useActiveTask();
    // TODO(afd): Revisit this to properly handle nonvoice tasks and not unsafely cast this
    // eslint-disable-next-line
    const callSid = (task as VoiceTask)?.agentCallId;
    const prevSessionId = usePrevious(sessionId);
    const previousCallSid = usePrevious(callSid);
    const previousWSLoading = usePrevious(websocketObj?.loading);
    const [currentSolveSessionId, setCurrentSolveSessionId] = useState("");

    useEffect(() => {
        if (!sessionId || !websocketObj || websocketObj.loading) return;

        const callSidChanged = callSid && previousCallSid !== callSid;
        const sessionIdChanged = prevSessionId !== sessionId;
        const websocketObjJustLoaded = previousWSLoading === true;
        const withCustomer = sessionKind === "with-customer";

        if (isPreviousSessionWithCustomer && sessionIdChanged && !withCustomer) {
            const sessionTimeInSeconds = endedSessionStartTime
                ? Math.floor((Date.now() - endedSessionStartTime) / 1000)
                : undefined;
            const formattedCallDuration = formatTime(sessionTimeInSeconds ?? 0, true);

            addMessage({
                ...getDefaultMessage("offCall"),
                id: `offCall_${crypto.randomUUID()}`,
                timestamp: formatTimestamp(),
                callDuration: formattedCallDuration,
                isUnread: true,
            });
        }

        if (sessionIdChanged && prevSessionId) {
            setCurrentSolveSessionId(sessionId);
            switchSession({
                callSid,
                features,
                partner,
                prevSessionId,
                sessionId,
                websocketObj,
                withCustomer: sessionKind === "with-customer",
                logger,
            });
            return;
        }

        /** When a call comes, we subscribe to GAIA with a sessionId (callSid is undefined).
         * Once the callSid arrives, we need to resubscribe to GAIA with the existing sessionId and new callSid **/
        if (callSidChanged) {
            void resubscribeWithCallSid({ sessionId, websocketObj, callSid, partner, logger });
            return;
        }

        // On initial load, we must wait for websocket to finish loading before handling session start
        // when websocket cycles through ready states on reconnect, we should resubscribe session to gaia
        if (websocketObjJustLoaded) {
            if (currentSolveSessionId !== sessionId) {
                setCurrentSolveSessionId(sessionId);
                solveSessionStarted({ sessionId, callSid, withCustomer, partner, features });
            }
            void subscribeSessionToGaia({ sessionId, callSid, websocketObj, partner, logger });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callSid, sessionId, websocketObj?.loading]);
};

const startSession = async ({
    sessionId,
    websocketObj,
    withCustomer,
    callSid,
    partner,
    features,
    logger,
}: SessionLifecycleProps) => {
    solveSessionStarted({ sessionId, callSid, withCustomer, partner, features });
    await subscribeSessionToGaia({ sessionId, callSid, websocketObj, partner, logger });
};

const subscribeSessionToGaia = async ({ sessionId, callSid, partner, websocketObj, logger }: SubscribeProps) => {
    try {
        await retryAsync(
            async () => {
                await websocketObj.subscribeSessionToGaia({
                    sessionId,
                    callSid,
                    partner,
                    sendJsonMessage: websocketObj.sendJsonMessage,
                    timeout: subscriptionTimeoutDuration,
                    logger,
                });
            },
            { delay: subscriptionTimeoutDuration, maxTry: subscriptionRetryCount },
        );
    } catch (error) {
        logger.error(
            { error, module: "useSolveSession" },
            `Error subscribing session ${sessionId} to gaia with max retries (${subscriptionRetryCount})`,
        );
    }
};

const resubscribeWithCallSid = async ({ sessionId, websocketObj, callSid, partner, logger }: SubscribeProps) => {
    await subscribeSessionToGaia({ sessionId, partner, websocketObj, callSid, logger });
};

const switchSession = ({
    callSid,
    features,
    logger,
    partner,
    prevSessionId,
    sessionId,
    websocketObj,
    withCustomer,
}: SessionLifecycleProps & {
    prevSessionId: string;
}) => {
    closeSession({ sessionId: prevSessionId, websocketObj, callSid, partner, features, logger });
    void startSession({
        callSid,
        features,
        partner,
        sessionId,
        websocketObj,
        withCustomer,
        logger,
    });
};

const closeSession = ({
    callSid,
    features,
    logger,
    partner,
    sessionId,
    websocketObj,
}: Omit<SessionLifecycleProps, "withCustomer">) => {
    solveSessionClosed({ sessionId, callSid, partner, features });
    websocketObj.unsubscribeSessionFromGaia({
        logger,
        sessionId,
        sendJsonMessage: websocketObj.sendJsonMessage,
    });
};
