import type { SVGProps } from "react";

export function WrongAnswer(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M8 17.999L18 7.99902"
                stroke="#FF6B6B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 17.999L8 7.99902"
                stroke="#FF6B6B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 24.499C19.3513 24.499 24.5 19.3503 24.5 12.999C24.5 6.64775 19.3513 1.49902 13 1.49902C6.64873 1.49902 1.5 6.64775 1.5 12.999C1.5 19.3503 6.64873 24.499 13 24.499Z"
                stroke="#FF6B6B"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
