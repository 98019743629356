import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { sdkEventBus } from "../../sdk";
import { getContacts } from "../api/contactsApiClient";
import { type Contact, type Partner, filterContacts } from "../../shared-types";

// Ensure we reset our state after the session ends
sdkEventBus.on("session_changed", () => {
    useContactsStateStore.getState().resetState();
});

interface VdnContactsState {
    /** List of contacts pulled from Avaya mapping table */
    contacts: Contact[]; // Add contacts to the state
}
interface VdnContactsActions {
    resetState: () => void;
    getAndSetContacts: (partner: Partner) => Promise<void>;
}

type VdnContactsStateStore = VdnContactsState & VdnContactsActions;

const defaultState: VdnContactsState = {
    contacts: [],
};

/** Only used for managing the transfer UI state */
export const useContactsStateStore = create<VdnContactsStateStore>()(
    devtools(
        (set) => ({
            ...defaultState,

            resetState: () => set(defaultState, false, "resetState"),
            getAndSetContacts: async (partner: Partner) => {
                try {
                    const contacts = filterContacts(await getContacts(), partner);
                    set({ contacts }, false, "getAndSetContacts");
                } catch (error) {
                    console.error("Failed to fetch contacts:", error);
                }
            },
        }),
        { enabled: import.meta.env.MODE !== "production", store: "transfers", name: "workspace/call-controls" },
    ),
);
