import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import type { AgentActivity } from "../types";

export interface AgentStore {
    agentId: string;
    setAgentId: (id: string) => void;
    agentName: string;
    setAgentName: (name: string) => void;
    agentTools: string[];
    setAgentTools: (tools: string[]) => void;
    activity: AgentActivity;
    activityUpdatedAt?: number;
    pendingActivity: AgentActivity | undefined;
    setAgentActivity: (activity: AgentActivity, activityUpdatedAt?: number) => void;
    setPendingActivity: (activity: AgentActivity | undefined) => void;
}

export const useAgentStore = create<AgentStore>()(
    immer(
        devtools(
            (set) => ({
                agentId: "---", // fallback value
                setAgentId: (id: string) => {
                    set(
                        (state) => {
                            state.agentId = id;
                        },
                        false,
                        "setAgentId",
                    );
                },

                activity: "Default",
                pendingActivity: undefined,
                activityUpdatedAt: undefined,
                setAgentActivity: (activity: AgentActivity, activityUpdatedAt?: number) => {
                    set(
                        (state) => {
                            state.activity = activity;
                            if (activityUpdatedAt) {
                                state.activityUpdatedAt = activityUpdatedAt;
                            }
                        },
                        false,
                        "setAgentActivity",
                    );
                },
                setPendingActivity: (activity?: AgentActivity) => {
                    set(
                        (state) => {
                            state.pendingActivity = activity;
                        },
                        false,
                        "setPendingActivity",
                    );
                },

                agentName: "Agent", // fallback value
                setAgentName: (name: string) => {
                    set(
                        (state) => {
                            state.agentName = name;
                        },
                        false,
                        "setAgentName",
                    );
                },
                agentTools: [],
                setAgentTools: (tools: string[]) => {
                    set(
                        (state) => {
                            state.agentTools = tools;
                        },
                        false,
                        "setAgentTools",
                    );
                },
            }),
            { enabled: import.meta.env.MODE !== "production", store: "agent", name: "workspace/sdk" },
        ),
    ),
);
