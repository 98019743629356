import { getLogger } from "@expert/logging";
import { Stack } from "@mantine/core";
import { useEffect } from "react";
import { expertWorkspaceWebSocketEventBus } from "../websocket";
import { type BaseNotificationType } from "./models";
import { useNotificationStore } from "./stores/useNotificationStore";
import { buildLeftNotifications } from "./utils/buildLeftNotifications";
import { buildRightNotifications } from "./utils/buildRightNotifications";

const logger = getLogger({
    module: "notifications",
    supportTeam: "sales",
});

export function NotificationContainer(): JSX.Element {
    const notifications = useNotificationStore((state) => state.notifications);
    const addNotification = useNotificationStore((state) => state.addNotification);
    const removeNotification = useNotificationStore((state) => state.removeNotification);

    useEffect(() => {
        const subscription = expertWorkspaceWebSocketEventBus.on(
            "ws_notification-event",
            (payload: BaseNotificationType) => {
                logger.info(payload, "Notification received");
                addNotification(payload);
            },
        );

        return () => {
            subscription();
        };
    }, [addNotification]);
    return (
        <>
            <Stack w="50%" pos="absolute" bottom="4%" left="1%">
                {notifications.map((n) => buildLeftNotifications(n, () => removeNotification(n.id)))}
            </Stack>
            <Stack w="50%" pos="absolute" bottom="4%" right="1%">
                {notifications.map((n) => buildRightNotifications(n, () => removeNotification(n.id)))}
            </Stack>
        </>
    );
}
