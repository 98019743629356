import type { AbstractAnalyticsDispatcher } from "@soluto-private/eventualize";
import { buildDispatcher } from "@soluto-private/eventualize";
import { reactAnalytics } from "@soluto-private/eventualize-react";
import { type Partner } from "../shared-types";
import type { Actions, ApplicationEvents } from "./eventTypes";

const isProd = import.meta.env.MODE === "production" && import.meta.env.PROD;

/** Our main dispatcher instance, it should not be accessed directly */
let rootDispatcher = buildDispatcher({
    platform: "ExWo",
    appPath: "/",
    appName: "expert-workspace-platform",
    isProd,
    partner: "asurion" /** We don't know the partner until the worker logs in to the workspace */,
})
    .typeActions<Actions>()
    .typeEvents<ApplicationEvents>()
    .getDispatcher();

export type RootDispatcherType = typeof rootDispatcher;

// Critical to initialize react analytics with the root dispatcher
reactAnalytics.init(rootDispatcher);
reactAnalytics.onChange.on(onDispatcherChange);

/** This ensures that the root dispatcher is always up-to-date with the source-of-truth reactAnalytics */
function onDispatcherChange(dispatcher: RootDispatcherType) {
    rootDispatcher = dispatcher;
}

export function getRootDispatcher(): RootDispatcherType {
    return reactAnalytics.dispatcher;
}

export function setIdentityAsTestUser(): void {
    reactAnalytics.transformDispatcher((dispatcher) => dispatcher.skipEdp().withIdentity("TestUser", "true"));
}

export function updateDispatcherPartner(newPartner: Partner) {
    reactAnalytics.transformDispatcher((dispatcher) => dispatcher.withExtra({ partner: newPartner }));
}

/** Simple wrapper around base lib transformDispatcher for use directly */
export function transformDispatcher<TIn extends AbstractAnalyticsDispatcher, TOut extends AbstractAnalyticsDispatcher>(
    transformFunc: (dispatcher: TIn) => TOut,
) {
    reactAnalytics.transformDispatcher(transformFunc);
}
