import { getAppConfigSingleton } from "../../config/appConfigSingleton";
import { type Partner } from "../../shared-types";

export function isActivationCall(partner: Partner, supportNumberCalled?: string) {
    if (!supportNumberCalled) return false;
    const partnerConfig = getAppConfigSingleton().partnersConfig;

    const partnerConfigInfo = partnerConfig[partner];
    return partnerConfigInfo.activationSupportNumbers.includes(supportNumberCalled);
}
