import { Container, Flex, Overlay, Popover, Text, Tooltip } from "@mantine/core";
import { useEffect } from "react";
import { useGlobalNotification } from "../../../common-ui";
import { useControlsStore } from "../controls.store";
import styles from "./Dialpad.module.css";
import { DialButtons } from "./elements";

export function Dialpad() {
    const { dialpadInput, setDialpadInput, isMuted, dialpadNotification, setDialpadNotification } = useControlsStore();
    const globalNotification = useGlobalNotification();

    useEffect(() => {
        if (isMuted && !dialpadNotification) {
            setDialpadNotification(globalNotification.error("Unmute to use dialpad", { persistent: true }));
        } else if (!isMuted && dialpadNotification) {
            globalNotification.clearNotification(dialpadNotification);
            setDialpadNotification(undefined);
        }
        // eslint-disable-next-line
    }, [isMuted, dialpadNotification]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) return;
            if (/[^#*0-9]/.test(event.key)) return;
            handleKey(event.key);
        };

        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialpadInput]);

    const handleKey = (key: string) => {
        setDialpadInput(dialpadInput + key);
    };

    function truncateString(str: string) {
        if (str.length > 10) {
            return `...${str.slice(-10)}`;
        }
        return str;
    }

    return (
        <Popover zIndex="var(--mantine-priority-highest)">
            <Container bg="dark.9" classNames={{ root: styles.containerRoot }}>
                {isMuted ? (
                    <Tooltip.Floating label="Disabled while muted" color="dark.7" fz="xs">
                        <Overlay
                            color="dark.9"
                            backgroundOpacity={0.6}
                            zIndex="var(--mantine-priority-highest)"
                            classNames={{ root: styles.overlay }}
                        />
                    </Tooltip.Floating>
                ) : null}
                <Flex align="center" direction="column" justify="center" mih={56} p="0.75rem" wrap="wrap">
                    <Text classNames={{ root: styles.textRoot }} size="lg">
                        <b>{truncateString(dialpadInput)}</b>
                    </Text>
                </Flex>
                <DialButtons />
            </Container>
        </Popover>
    );
}
