import {
    type Contact,
    VDN_PREFIX,
    VDN_PREFIX_LENGTH,
    VDN_LENGTH,
    TFN_LENGTH,
    type ContactGroup,
    type Group,
} from "../../shared-types";

/** check the transfer list and return a transfer option if a match is found for given number */
export function tryGetByVdnNumber(vdn: string, contacts: Contact[]) {
    // Don't include 555 vdn prefix
    if (vdn.startsWith(VDN_PREFIX)) {
        return contacts.find((option) => option.vdn === vdn.slice(VDN_PREFIX_LENGTH));
    }
    return contacts.find((option) => option.vdn === vdn);
}
export function tryGetByVdnDestination(destination: string, contacts: Contact[]) {
    return contacts.find((option) => option.number.toString() === destination);
}

export function getVdn(num: string, contacts: Contact[]) {
    let key = extractNumbers(num);
    if (key.length >= VDN_LENGTH) {
        key = key.slice(-VDN_LENGTH); // VDN's are 7 digits appended to name
    }
    return tryGetByVdnNumber(key, contacts);
}

export function isInternalTfn(num: string, contacts: Contact[]) {
    let key = extractNumbers(num);
    if (key.length >= TFN_LENGTH) {
        key = key.slice(-TFN_LENGTH); // VDN's are 7 digits appended to name
    }
    return tryGetByVdnNumber(key, contacts)?.isInternal;
}

export function extractNumbers(value: string): string {
    return value.replace(/\D/g, "");
}

export function tryGetByVdnName(rawInput: string, contacts: Contact[]): Contact | undefined {
    return contacts.find((option) => option.name && option.name.toLowerCase() === rawInput.toLowerCase());
}

export function isValidVdn(vdn: string) {
    return /(?:\d{3})-(?:\d{4})/.test(vdn) || /^\d{7}$/.test(vdn) || /^\d{10}$/.test(vdn);
}

/** Returns a masked version of a TFN to use as a name
 * this utility could use work to not be dependant on a good string being passed in
 */
export const tfnToMaskedName = (tfn: string | null | undefined) => {
    const safeTfn = (tfn ?? "XXX").toString();
    const lastThreeDigits = safeTfn.slice(-3);
    return `+1(XXX) XXX-X${lastThreeDigits}`;
};

/** Returns a formatted version of contacts specifically to use
 * in Mantine's Autocomplete component
 */
export function groupContacts(contacts: Contact[]): ContactGroup[] {
    const filteredContacts = contacts.filter(
        (contact): contact is Contact & { group: Group } => contact.group !== undefined && contact.group !== "Unlisted",
    );

    const groupedContactsMap = filteredContacts.reduce((index, { group, name }) => {
        if (!index.has(group)) {
            index.set(group, []);
        }
        index.get(group)?.push(name);
        return index;
    }, new Map<Group, string[]>());

    return Array.from(groupedContactsMap, ([group, items]) => ({
        group,
        items: items.sort(), // Alphabetize items
    }));
}
