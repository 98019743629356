import { isVoiceTask } from "../../agent/voice/typeGuards";
import { useSession } from "./useSession";

/**
 * NOTE: This function must be used with an active voice task. Any other task types will return undefined.
 *
 * Will provide the most recent known task, callback, or last task MDN for a session otherwise undefined.
 * This can and should be used to replace direct task MDN or callback MDN use cases for UI display
 */
export function useSessionActiveCallerId() {
    const session = useSession();
    const currentTask = session.currentTask;

    if (isVoiceTask(currentTask)) {
        return currentTask.callerId;
    }

    if (session.callbackState?.callbackMDN) {
        return session.callbackState.callbackMDN;
    }

    const prevTask = session.tasks.at(-1);
    if (isVoiceTask(prevTask)) {
        return prevTask.callerId;
    }

    return undefined;
}
