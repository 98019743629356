import { Button, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMemo } from "react";
import { FeedbackModal } from "@soluto-private/expert-workspace-feedback";
import { getLogger } from "@expert/logging";
import { useAnalytics } from "../../analytics";
import { useFeedbackNotification } from "../../feedback";
import { getSessionId, getSessionIdHistory, useOptionalActiveVoiceTask, usePartner } from "../../sdk";
import { userCache } from "../../shared-utils";
import { env } from "../Profile/environment";
import { MegaphoneIcon } from "./MegaphoneIcon";

const logger = getLogger({ module: "WorkspaceFeedbackButton", supportTeam: "solve" });

// TODO: SOLVE TEAM TO REFACTOR ONCE NEW DESIGNS ARE FINALIZED
export function FeedbackButton(): JSX.Element {
    const theme = useMantineTheme();

    const { dispatcher } = useAnalytics();

    const triggerFeedbackNotification = useFeedbackNotification();

    const voiceTask = useOptionalActiveVoiceTask();
    const partner = usePartner();
    const sessionId = getSessionId();
    const ssoAccessToken = userCache.ssoAccessToken ?? "";

    const loggerWithContext = useMemo(
        () =>
            logger.child({
                sessionId,
                callSid: voiceTask?.agentCallId,
            }),
        [sessionId, voiceTask?.agentCallId],
    );

    const [opened, { open, close }] = useDisclosure(false, {
        onOpen: () => {
            void dispatcher.dispatchAction("Click", "ShareFeedback", { source: "Header" });
            loggerWithContext.info("Feedback modal in Workspace Header opened");
        },
        onClose: () => {
            triggerFeedbackNotification("success");
            loggerWithContext.info("Feedback modal in Menu closed");
        },
    });

    const error = () => {
        triggerFeedbackNotification("error");
    };

    const formattedVersionNumber = env.versionNumber.startsWith("v")
        ? `v${env.versionNumber.substring(3)}`
        : env.versionNumber;

    return (
        <>
            <FeedbackModal
                isOpen={opened}
                onClose={close}
                onError={error}
                feedbackPayload={{
                    appName: "expert-workspace",
                    caller: "PlatformHeader",
                    callSid: voiceTask?.agentCallId,
                    partner,
                    sessionId,
                    ssoAccessToken,
                    extraData: {
                        "Previous Sessions": JSON.stringify(getSessionIdHistory(5)),
                        "Workspace Version": formattedVersionNumber,
                    },
                }}
                logger={loggerWithContext}
            />
            <Button
                color={theme.colors.indigo[7]}
                leftSection={<MegaphoneIcon />}
                onClick={open}
                size="sm"
                variant="filled"
            >
                Feedback
            </Button>
        </>
    );
}
