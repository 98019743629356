import { Drawer, Flex, Skeleton, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { BarGraph, DropsIcon, IncreaseIcon, RightArrowIcon } from "./icons";
import { useDashboardData } from "./calls/useDashboardData";
import { PayDashboard } from "./PayDashboard";
import { UpdatedTime } from "./components/UpdatedTime";
import classes from "./PayWidget.module.css";
import { WidgetRow } from "./components/WidgetRow";
import { type DashboardResponse } from "./calls/getDashboardData";

function Header() {
    return (
        <Flex className={classes.inner}>
            <Flex fw={700} fz={16} lh="18px" className={classes.header}>
                My stats
            </Flex>
            <UnstyledButton variant="outline">
                <Flex align="center">
                    <RightArrowIcon />
                </Flex>
            </UnstyledButton>
        </Flex>
    );
}

export const calculatePayOnNextEnroll = (data: DashboardResponse): number => {
    const netSales = data.enrolls - data.churns;
    if (netSales >= 0 && netSales + 1 < data.sellPayScale.length) {
        return data.sellPayScale[netSales + 1].pay - data.sellPayScale[netSales].pay;
    }
    if (netSales > 0 || netSales + 1 > data.sellPayScale.length) {
        return (
            data.sellPayScale[data.sellPayScale.length - 1].pay - data.sellPayScale[data.sellPayScale.length - 2].pay
        );
    }
    return 0;
};

export function PayWidget(): JSX.Element {
    const [opened, { open, close }] = useDisclosure(false);
    const { data, isLoading } = useDashboardData();
    const onClick = () => {
        open();
    };
    if (isLoading && !data) {
        return (
            <Flex className={classes.main} direction="column" fz={12} onClick={onClick}>
                <Header />
                <Flex align="center" direction="column" justify="center" h="111px">
                    <Skeleton mt={10} h="16px" />
                    <Skeleton h="50px" mt={10} />
                    <Skeleton h="14px" mt={10} />
                </Flex>
            </Flex>
        );
    }
    if (!data) {
        return (
            <Flex
                direction="column"
                className={classes.main}
                h="20%"
                align="center"
                justify="center"
                p={16}
                fw={400}
                c="dark.2"
                fz={14}
            >
                Waiting on sales and survey data.
            </Flex>
        );
    }

    const totalPay = data.sellPay + data.servePay;
    return (
        <>
            <Drawer
                opened={opened}
                size="50%"
                padding={0}
                onClose={close}
                title={
                    <Flex pl={16} fz={20} fw={700} c="dark.0">
                        My stats
                    </Flex>
                }
                classNames={{
                    content: classes.widgetRoot,
                    header: classes.widgetHeader,
                    close: classes.closeButton,
                }}
            >
                <PayDashboard />
            </Drawer>
            <Flex className={classes.main} direction="column" fz={12} onClick={onClick}>
                <Header />

                <Flex pt={10}>
                    <WidgetRow largeText={`₱${Math.trunc(totalPay)}`} smallText=" projected earnings" />
                </Flex>
                <Flex direction="column" pt={10}>
                    <WidgetRow
                        largeText={`₱${Math.trunc(calculatePayOnNextEnroll(data)).toString()}`}
                        smallText=" increase on next enroll"
                        SVGIcon={IncreaseIcon}
                    />
                    <WidgetRow
                        largeText={`₱${Math.trunc(data.sellRate).toString()}`}
                        smallText=" per VHDP enroll"
                        SVGIcon={BarGraph}
                    />
                    <WidgetRow largeText={Math.trunc(data.churns).toString()} smallText=" drops" SVGIcon={DropsIcon} />
                </Flex>
                <Flex pt={10} c="dark.2">
                    <UpdatedTime updatedTime={data.lastUpdated} />
                </Flex>
            </Flex>
        </>
    );
}
