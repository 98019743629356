export function ChatIcon() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.595703 15.0003H5.9407V13.3337H4.61904L6.00237 11.9512L5.47737 11.3637C4.6907 10.482 4.27404 9.43366 4.27404 8.33366C4.27404 5.57616 6.8907 3.33366 10.1074 3.33366C13.0174 3.33366 15.5032 5.19533 15.889 7.66533L17.5357 7.40783C17.024 4.13449 13.8307 1.66699 10.1074 1.66699C5.97237 1.66699 2.60737 4.65783 2.60737 8.33366C2.60737 9.59616 2.99737 10.7995 3.74154 11.8553L0.595703 15.0003Z"
                fill="#A5AAAF"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.0241 19.167H19.9974L17.9916 16.1578C18.2866 15.532 18.4408 14.852 18.4408 14.167C18.4408 11.4095 16.0108 9.16699 13.0241 9.16699C10.0374 9.16699 7.60742 11.4095 7.60742 14.167C7.60742 16.9245 10.0374 19.167 13.0241 19.167ZM9.27409 14.167C9.27409 12.3295 10.9566 10.8337 13.0241 10.8337C15.0916 10.8337 16.7741 12.3295 16.7741 14.167C16.7741 14.732 16.6191 15.2687 16.3124 15.7645L16.0308 16.2195L16.8841 17.5003H13.0241C10.9566 17.5003 9.27409 16.0045 9.27409 14.167Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
