import { getLogger } from "@expert/logging";
import { api } from "../../shared-utils";
import { env } from "../environment";

const contextLogger = getLogger({ module: "accessTokenGenerator" });

interface TwilioTokenResponse {
    accessToken: string;
}

export async function getServiceProviderAccessToken(userFriendlyName?: string): Promise<string> {
    try {
        const { accessToken } = await api
            .post(env.accessTokenGeneratorPath, { json: { userFriendlyName } })
            .json<TwilioTokenResponse>();
        return accessToken;
    } catch (err: unknown) {
        contextLogger.error({ err });
        throw err;
    }
}
