import { Group, Pill } from "@mantine/core";
import classes from "./WhisperPill.module.css";

interface WhisperPillProps {
    whisper: string;
}

export function WhisperPill({ whisper }: WhisperPillProps): JSX.Element {
    return (
        <Group pos="absolute" left={35} top={5} classNames={{ root: classes.whisperContainer }}>
            <Pill
                bg="linear-gradient(90deg, var(--mantine-color-success-4) 0.3%, #60FFFF 105.93%)"
                c="var(--mantine-color-black)"
                fw={700}
                fz={12}
                p="0 .1875rem"
                radius={2}
                size="xs"
                w="fit-content"
            >
                {whisper}
            </Pill>
        </Group>
    );
}
