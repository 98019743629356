export function QuotationMarkIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none">
            <path
                fill="#20C997"
                d="M10.972 14.992c0 1.212-.458 2.226-1.375 3.045-.917.818-2.063 1.228-3.437 1.228-1.343 0-2.505-.459-3.487-1.375-.982-.95-1.473-2.308-1.473-4.076 0-1.048.213-2.095.638-3.143.426-1.047.966-2.03 1.62-2.946a17.827 17.827 0 0 1 2.21-2.455c.786-.72 1.54-1.277 2.26-1.67l2.455 2.406c-.393.164-.802.41-1.228.737a7.663 7.663 0 0 0-1.179 1.13c-.36.392-.654.85-.883 1.374-.23.491-.344.982-.344 1.473 1.211.164 2.21.622 2.995 1.375.819.72 1.228 1.686 1.228 2.897Zm11.828 0c0 1.212-.458 2.226-1.375 3.045-.917.818-2.062 1.228-3.437 1.228-1.343 0-2.505-.459-3.487-1.375-.982-.95-1.473-2.308-1.473-4.076 0-1.048.213-2.095.638-3.143.426-1.047.966-2.03 1.62-2.946a17.827 17.827 0 0 1 2.21-2.455c.786-.72 1.54-1.277 2.26-1.67l2.455 2.406c-.393.164-.802.41-1.228.737a7.662 7.662 0 0 0-1.178 1.13c-.36.392-.655.85-.884 1.374-.23.491-.344.982-.344 1.473 1.211.164 2.21.622 2.995 1.375.819.72 1.228 1.686 1.228 2.897Z"
            />
        </svg>
    );
}
