import { DotLottieReact } from "@lottiefiles/dotlottie-react";

interface LottieAnimatorProps {
    className?: string;
    src: string;
    shouldAutoPlay?: boolean;
    shouldLoop?: boolean;
    speed?: number;
}

export function LottieAnimator({
    className,
    src,
    shouldAutoPlay = true,
    shouldLoop = true,
    speed = 1,
}: LottieAnimatorProps) {
    return (
        <DotLottieReact
            renderConfig={{ autoResize: false }}
            speed={speed}
            className={className}
            src={src}
            autoplay={shouldAutoPlay}
            loop={shouldLoop}
        />
    );
}
