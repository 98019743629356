import { Badge, Button, Transition } from "@mantine/core";
import { ArrowDown } from "../assets";

interface NewMessageButtonProps {
    shouldShow: boolean;
    num: number;
    onClick: () => void;
}

function NewNotificationBadge({ num }: Omit<NewMessageButtonProps, "onClick" | "shouldShow">) {
    return (
        <Badge size="sm" circle>
            {num}
        </Badge>
    );
}

export function NewMessageButton({ shouldShow, num, onClick }: NewMessageButtonProps) {
    return (
        <Transition duration={400} mounted={shouldShow} transition="slide-up" timingFunction="ease">
            {(transitionStyles) => (
                <Button
                    bottom="7rem"
                    color="dark.7"
                    leftSection={<NewNotificationBadge num={num} />}
                    onClick={onClick}
                    pos="absolute"
                    rightSection={<ArrowDown />}
                    style={{ ...transitionStyles, border: ` 0.125rem solid var(--mantine-color-primary-7)` }}
                >
                    {num > 1 ? "New messages" : "New message"}
                </Button>
            )}
        </Transition>
    );
}
