import { parsePhoneNumber } from "awesome-phonenumber";
import { env } from "../environment";

export const validatePhoneNumber = (mdn: string | undefined): { valid: boolean; number?: string } => {
    if (!mdn) {
        return { valid: false };
    }
    const { valid, number } = parsePhoneNumber(mdn, { regionCode: "US" });
    const isValid = valid || isWhitelistedSales(mdn);
    return { valid: isValid, number: number?.e164 };
};

export const isWhitelistedSales = (mdn: string | undefined): boolean => {
    if (!mdn) {
        return false;
    }

    return env.salesWhitelistedMdns.includes(mdn.replace(/-|\s|\(|\)/g, ""));
};

export const isValidPhoneNumber = (mdn: string | undefined): { valid: boolean; number?: string } => {
    if (!mdn) {
        return { valid: false };
    }
    const { valid, number } = parsePhoneNumber(mdn, { regionCode: "US" });
    return { valid, number: number?.e164 };
};
