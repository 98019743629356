import { ActionIcon, CopyButton, Flex, Notification, Text } from "@mantine/core";
import { classNames } from "../../shared-utils";
import { CheckMark, CopyIcon, useToast } from "../../common-ui";
import { usePartner } from "../../sdk";
import { getAppConfigSingleton } from "../../config/appConfigSingleton";
import styles from "./EditSessionSummaryModal.module.css";

interface SendSummaryNotification {
    summary: string;
    notificationText: string;
    validAccountNumber: boolean;
}

export function SendSummaryNotification({ validAccountNumber, summary, notificationText }: SendSummaryNotification) {
    const toast = useToast();
    const partner = usePartner();
    const {
        partnersConfig: {
            [partner]: { requiresManualCopy },
        },
    } = getAppConfigSingleton();

    function getBackgroundClass() {
        // Partner requires manual action
        if (partner === "att") {
            return styles.brandedBg;
        }

        // Partner supports automatic summary sending
        if (validAccountNumber) {
            return styles.defaultBg;
        }

        // Auto-send failed due to invalid account number or partner restrictions
        return styles.errorBg;
    }

    return (
        <Flex className={classNames(styles.notificationWrapper, getBackgroundClass())}>
            {validAccountNumber && !requiresManualCopy ? (
                <Notification
                    color="dark.0"
                    classNames={{
                        root: styles.notificationRoot,
                    }}
                    radius="sm"
                    withCloseButton={false}
                >
                    <Text fz="xs" lh="1.125rem">
                        {notificationText}
                    </Text>
                </Notification>
            ) : (
                <CopyButton value={summary}>
                    {({ copied, copy }) => {
                        const handleCopyClick = () => {
                            copy();
                            toast.success("Session summary copied to clipboard");
                        };
                        return (
                            <>
                                <Notification
                                    onClick={handleCopyClick}
                                    classNames={{
                                        root: styles.notificationRoot,
                                    }}
                                    color="red.1"
                                    radius="sm"
                                    withCloseButton={false}
                                >
                                    <Text fz="xs" lh="1.125rem">
                                        {notificationText}
                                    </Text>
                                </Notification>
                                <ActionIcon
                                    mr="0.6rem"
                                    color="red.1"
                                    onClick={handleCopyClick}
                                    variant="subtle"
                                    id="copy-summary-action-icon"
                                >
                                    {copied ? (
                                        <CheckMark fill="var(--mantine-color-red-1)" width={18} />
                                    ) : (
                                        <CopyIcon stroke="var(--mantine-color-red-1)" width={17} height={18} />
                                    )}
                                </ActionIcon>
                            </>
                        );
                    }}
                </CopyButton>
            )}
        </Flex>
    );
}
