import { Flex, Text } from "@mantine/core";
import { type PayList } from "../types";
import classes from "./SharedChartProps.module.css";

interface XAxisProps {
    payList: PayList;
}
export function XAxis({ payList }: XAxisProps): JSX.Element {
    return (
        <Flex className={classes.labelWrapper} h={16} w="100%" pl={40} pr={40} mt={4}>
            {payList.map((entry, index) => {
                if (index + 1 === payList.length)
                    return (
                        <Flex key={`chart-label-${entry.metric}`} className={classes.labelElement}>
                            <Flex w={0} className={classes.centeredNumber}>
                                <Text fz="xs">{`${entry.metric}+`}</Text>
                            </Flex>
                        </Flex>
                    );
                return (
                    <Flex key={`chart-label-${entry.metric}`} className={classes.labelElement}>
                        <Flex w={0} className={classes.centeredNumber}>
                            <Text fz="xs">{`${entry.metric}`}</Text>
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
}
