import { getLogger } from "@expert/logging";
import { api } from "../../shared-utils";
import { type Partner } from "../../shared-types";
import type { ChannelShareDatabasePayload } from "./types";

const logger = getLogger({
    module: "getCallDetails",
});

export const getCallDetails = async (
    asurionCallId: string | undefined,
    partner: Partner,
): Promise<ChannelShareDatabasePayload | null> => {
    if (!asurionCallId) return null;

    try {
        return await api
            .get(`api/v1/call-details/details/${asurionCallId}`, { searchParams: { partner } })
            .json<ChannelShareDatabasePayload>();
    } catch (err: unknown) {
        logger.error({ err }, `getFromChannelShareDatabase API call failed with exception`);
        return null;
    }
};
