import { Container } from "@mantine/core";
import { type ReactNode } from "react";
import styles from "./SideBar.module.css";

export function SideBarDisplayPanel({
    children,
    isDisplayed,
}: {
    children?: ReactNode;
    isDisplayed: boolean;
}): JSX.Element {
    return (
        <Container display={isDisplayed ? "contents" : "none"} className={styles.hidden}>
            {children}
        </Container>
    );
}
