export function DeviceCheckMark(props: React.SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={12} height={10} fill="none" {...props}>
            <path
                fill={props.fill}
                fillRule="evenodd"
                d="m11.513 1.092-7.38 8.912L.85 6.55l.966-.919 2.248 2.365L10.486.241l1.027.85Z"
                clipRule="evenodd"
            />
        </svg>
    );
}
