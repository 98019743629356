import { useEffect } from "react";
import { type GaiaWebSocketResponse, gaiaWsEventBus } from "@expert/gaia";
import type { MessageSuggestion } from "@expert/shared-types";
import { useIntegratedFlowStore } from "../home-product/stores/useIntegratedFlowStore";
import { useOfferChecklistStore } from "../home-product/stores/useOfferChecklistStore";
import { getRootDispatcher } from "../../analytics";
import { useVerifiedCustomerStore } from "../../customer-info";

export const useSalesChecklistEventListener = () => {
    useEffect(() => {
        const cleanup = gaiaWsEventBus.on("gaia_ws_message-suggestion", ({ body }: GaiaWebSocketResponse) => {
            const transformed = body as MessageSuggestion;
            if (
                transformed.suggestion.responseType !== "sales-checklist-mentions" ||
                useIntegratedFlowStore.getState().integratedStep === "LegalDisclosureStep"
            ) {
                return;
            }

            transformed.suggestion.sales_checklist_mentions?.forEach((mention) => {
                void getRootDispatcher().dispatchBusinessEvent("SalesChecklistBehaviorMentioned", {
                    behavior: mention.name,
                    techSavvyRating: useVerifiedCustomerStore.getState().customer?.techSavvyRating,
                });
                const data = useOfferChecklistStore.getState().data;
                data[mention.name] = mention.value;
                useOfferChecklistStore.getState().setData(data);
            });
        });

        return () => cleanup();
    }, []);
};
