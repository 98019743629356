import { Stack } from "@mantine/core";
import { LeaderBoardWidget } from "../../leaderboard/components/LeaderBoardWidget";
import { PayWidget } from "../../pay-dashboard/PayWidget";

export function WidgetContainer(): JSX.Element {
    return (
        <Stack ml={16} mt={16} mb={18} w={220}>
            <PayWidget />
            <LeaderBoardWidget />
        </Stack>
    );
}
