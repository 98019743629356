import { type SVGProps } from "react";

export function CloseIcon({ height = 25, width = 24, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.41083 6.6607C6.73626 6.33527 7.2639 6.33527 7.58934 6.6607L12.0001 11.0714L16.4108 6.6607C16.7363 6.33527 17.2639 6.33527 17.5893 6.6607C17.9148 6.98614 17.9148 7.51378 17.5893 7.83921L13.1786 12.25L17.5893 16.6607C17.9148 16.9861 17.9148 17.5138 17.5893 17.8392C17.2639 18.1647 16.7363 18.1647 16.4108 17.8392L12.0001 13.4285L7.58934 17.8392C7.2639 18.1647 6.73626 18.1647 6.41083 17.8392C6.08539 17.5138 6.08539 16.9861 6.41083 16.6607L10.8216 12.25L6.41083 7.83921C6.08539 7.51378 6.08539 6.98614 6.41083 6.6607Z"
                fill="var(--mantine-color-dark-0)"
            />
        </svg>
    );
}
