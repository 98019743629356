import type { SVGProps } from "react";

export function IncreaseIcon(props: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.08333 4.08333C4.08333 3.76117 4.3445 3.5 4.66667 3.5H9.91667C10.2388 3.5 10.5 3.76117 10.5 4.08333V9.33333C10.5 9.6555 10.2388 9.91667 9.91667 9.91667C9.5945 9.91667 9.33333 9.6555 9.33333 9.33333V5.49162L4.49581 10.3291C4.26801 10.557 3.89866 10.557 3.67085 10.3291C3.44305 10.1013 3.44305 9.73199 3.67085 9.50419L8.50838 4.66667H4.66667C4.3445 4.66667 4.08333 4.4055 4.08333 4.08333Z"
                fill="#20C997"
            />
        </svg>
    );
}
