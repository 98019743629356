import { Button, type ButtonProps, Group } from "@mantine/core";
import { type SolveGuidedFlowAction, type SolveGuidedFlowStep } from "../types";
import classes from "./styles.module.css";

interface GuideActionGroupProps extends ButtonProps {
    currentStep: SolveGuidedFlowStep;
    onClick: (action: SolveGuidedFlowAction) => void;
}

export function GuideActionGroup({ currentStep, onClick }: GuideActionGroupProps): JSX.Element {
    return (
        <Group h="2.5rem" gap="sm">
            {...currentStep.actions.map((action) => (
                <Button
                    className={classes.guideActionButton}
                    key={`guide-action-button-${action.label}`}
                    variant={action.variant ?? "default"}
                    onClick={() => onClick(action)}
                    miw="5.5rem"
                    size="sm"
                >
                    {action.label}
                </Button>
            ))}
        </Group>
    );
}
