import { getLogger } from "@expert/logging";
import type { AgentSdk, VoiceTask } from "../sdk";
import { clearCallbackState, setCallbackState, TASK_WRAPUP_REASON } from "../sdk";
import { isValidPhoneNumber } from "../shared-utils";
import { getCallbackStatus } from "./api";
import { useCallbacksFormStore } from "./ScheduleCallback/callbacks.store";

const logger = getLogger({ module: "callback-status" });

//TODO: Consider moving this logic

/** interacts with the agentSdk to end the given task/end the call */
export const handleEndCall = async (agentSdk: AgentSdk, task: VoiceTask) => {
    if (task.status === "pending") {
        await agentSdk.wrapupTask(task, TASK_WRAPUP_REASON);
    } else if (task.status === "assigned") {
        await agentSdk.hangupCall(task, TASK_WRAPUP_REASON);
    } else {
        throw new Error("Cannot hangup a call associated with a task not in pending or assigned status.");
    }
};

/** Updates the task state with an existing customer callbacks upon call accept */
export async function updateExistingCallback(task: VoiceTask) {
    try {
        const { valid } = isValidPhoneNumber(task.callerId);
        if (!valid) {
            logger.error({ task }, "Phone number not valid to check existing callback");
            return;
        }

        const result = await getCallbackStatus({ phoneNumber: task.callerId });

        if (!result?.length) {
            logger.debug({ task, result }, "Callback-status api did not return an existing callback");
            return;
        }

        const existingCallback = result.find((callback) => {
            return callback.status === "Pending";
        });

        if (!existingCallback) return;

        setCallbackState(task.id, {
            callbackType: "CallbackLater",
            scheduledAt: existingCallback.createdAt,
            scheduledFor: existingCallback.estimatedFor,
            callbackDelay: null,
            callbackMDN: task.callerId,
        });

        useCallbacksFormStore.getState().setCallbackScheduled(true);
    } catch (error) {
        // TODO: refactor to be able to display a toast here.
        logger.error({ task, error }, "Unable to receive callback information from Mindful");
    }
}

/** Clears the customer callback state when a call is completed */
export function clearCallbacksState() {
    clearCallbackState();
    useCallbacksFormStore.getState().setCallbackScheduled(false);
}
