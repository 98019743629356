export const DeclinedIcon = (
    <svg data-testid="declined-icon" xmlns="http://www.w3.org/2000/svg" width={66} height={66} fill="none">
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="M33 63.667c16.937 0 30.667-13.73 30.667-30.667S49.937 2.334 33 2.334 2.333 16.064 2.333 33c0 16.937 13.73 30.667 30.667 30.667Z"
            clipRule="evenodd"
        />
        <path
            stroke="#6E767D"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={3}
            d="m11.315 11.315 43.37 43.37"
        />
    </svg>
);
