import { useQuery } from "@tanstack/react-query";
import { getQuestionnaire } from "../components/api/questionnaire";

export const useGetQuestionnaire = (isEnabled = true) => {
    const { data, isLoading, isError } = useQuery({
        queryKey: [`sales-quiz`],
        queryFn: async () => getQuestionnaire(),
        retry: 0,
        gcTime: 0,
        enabled: isEnabled,
    });
    return { data, isLoading, isError };
};
