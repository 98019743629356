import { getLogger } from "@expert/logging";
import { Flex, Group, Image, Stack, Text } from "@mantine/core";
import { useEffect } from "react";
import type { RequireAtLeastOne } from "type-fest";
import LostAstronaut from "../assets/image/LostAstronaut.svg";
import classes from "./GlobalError.module.css";

type GlobalErrorProps = React.PropsWithChildren<RequireAtLeastOne<{ title?: string; msg?: string }>>;

const logger = getLogger({ module: "global" });

export function GlobalError({ msg, children, title }: GlobalErrorProps) {
    useEffect(() => {
        if (msg) logger.error({ title }, msg);
        else logger.error(title);
    }, [msg, title]);

    return (
        <Flex justify="center" align="center" h="100vh" p="xl" w="100vw" className={classes.mainFlexContainer}>
            <Stack maw={500}>
                <Text fw="var(--mantine-font-weight-heavy)" c="dark.0">
                    {title ?? "Something’s not right-try refreshing the page"}
                </Text>
                <Text fw="var(--mantine-font-weight-light)" c="dark.1" w="95%">
                    {msg ?? "Try again in a bit, or see if refreshing helps."}
                </Text>
                {children}
            </Stack>
            <Group className={classes.asset}>
                <Image src={LostAstronaut} alt="Lost astronaut" />
            </Group>
        </Flex>
    );
}
