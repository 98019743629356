import ky from "ky";
import { getLogger } from "@expert/logging";
import { userCache } from "../../shared-utils";
import { env } from "../../../config";

interface GaiaSummaryRequest {
    callSid: string;
    sessionId: string;
    partner: string;
}

interface BackupGaiaSummaryRequest {
    sessionId: string;
    partner: string;
}

interface GaiaSummaryResponse {
    response: string;
}

interface GaiaHistoryMessage {
    role: string;
    id: string;
    sessionId: string;
    messageId: string;
    content: string;
    timestamp: string;
}

interface GaiaHistoryResponse {
    messages: GaiaHistoryMessage[];
    sessionGroupId: string;
    userId: string;
    inputTimestamp: string;
    outputTimestamp: string;
}

const logger = getLogger({ module: "sessionSummary" });
type GaiaSummaryModel = "post-call-summarization" | "post-call-multistep-summarization";

const fetchGaiaSummary = async (request: GaiaSummaryRequest, model: GaiaSummaryModel) => {
    return await ky
        .post(`${env.gaiaApiUrl}/orchestrator/invoke`, {
            headers: {
                "x-app-name": model,
                "x-partner-name": request.partner,
                "x-correlation-id": request.sessionId,
                Authorization: `Bearer ${userCache.ssoAccessToken}`,
                "Content-Type": "application/json",
            },
            json: {
                sessionId: request.callSid,
                sessionGroupId: request.sessionId,
                message: crypto.randomUUID(),
                userId: userCache.employeeId,
                messageId: request.sessionId,
                userType: "expert",
            },
        })
        .json<GaiaSummaryResponse>();
};

const fetchGaiaSummaryHistory = async (
    request: GaiaSummaryRequest | BackupGaiaSummaryRequest,
    model: GaiaSummaryModel,
) => {
    return await ky
        .get(`${env.gaiaApiUrl}/orchestrator/session-history?sessionGroupId=${request.sessionId}&limit=1`, {
            headers: {
                "x-app-name": model,
                "x-partner-name": request.partner,
                "x-correlation-id": request.sessionId,
                Authorization: `Bearer ${userCache.ssoAccessToken}`,
                "Content-Type": "application/json",
            },
        })
        .json<GaiaHistoryResponse>();
};

export const getGaiaSummary = async (
    request: GaiaSummaryRequest,
    model: "post-call-multistep-summarization" | "post-call-summarization",
) => {
    logger.debug({ ...request }, "Fetching gaia generated summary");

    try {
        return (await fetchGaiaSummary(request, model)).response;
    } catch (fetchGaiaSummaryException) {
        logger.error({ err: fetchGaiaSummaryException, ...request }, "Error fetching Gaia summary");
        return await getBackupGaiaSummary({ sessionId: request.sessionId, partner: request.partner }, model);
    }
};

export const getBackupGaiaSummary = async (
    request: BackupGaiaSummaryRequest,
    model: "post-call-multistep-summarization" | "post-call-summarization",
) => {
    logger.debug({ ...request }, "Fetching backup gaia generated summary");

    try {
        return (await fetchGaiaSummaryHistory(request, model)).messages[0]?.content ?? "";
    } catch (fetchGaiaSummaryHistoryException) {
        logger.error({ err: fetchGaiaSummaryHistoryException, ...request }, "Error fetching Gaia summary backup");
        return "";
    }
};
