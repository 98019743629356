interface DotOffset {
    xOffset: number;
    yOffset: number;
}

export function ActiveDot({ xOffset, yOffset }: DotOffset) {
    return (
        <svg width={xOffset + 12} height={yOffset + 12} viewBox={`0 0 ${xOffset + 12} ${yOffset + 12}`} fill="none">
            <circle cx={xOffset} cy={yOffset} r="12" fill="url(#paint0_linear_1634_9961)" />
            <circle cx={xOffset} cy={yOffset} r="8" fill="#F7EDFF" />
            <defs>
                <linearGradient
                    id="paint0_linear_1634_9961"
                    x1="0.732394"
                    y1="6.49202e-06"
                    x2="24.8427"
                    y2="2.67223"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#6B1BAF" />
                    <stop offset="1" stopColor="#933ADC" />
                </linearGradient>
            </defs>
        </svg>
    );
}
