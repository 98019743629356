import { getSalesApiClient } from "../../home-product/api/api";
import type { ScaleEntry } from "../types";

export interface DashboardResponse {
    churns: number;
    enrolls: number;
    surveys: number;
    sellPay: number;
    servePay: number;
    sellRate: number;
    serveRate: number;
    lastUpdated: string;
    payPeriodStart: string;
    servePayScale: ScaleEntry[];
    sellPayScale: ScaleEntry[];
}

export const getPayDashboard = async () => {
    const salesApi = getSalesApiClient();
    const response: DashboardResponse = await salesApi.get("v2/pay-dashboard").json();
    return response;
};
