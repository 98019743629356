import type { BaseNotificationType, SaleMadeNotificationType } from "../models";
import { SaleMadeNotification } from "../SaleMadeNotification/components/SaleMadeNotification";

export const buildLeftNotifications = (payload: BaseNotificationType, onClose: () => void) => {
    switch (payload.type) {
        case "SaleMadeNotification":
            return (
                <SaleMadeNotification
                    seller={(payload as SaleMadeNotificationType).props.seller}
                    onClose={onClose}
                    key={payload.id}
                />
            );
        default:
            return undefined;
    }
};
