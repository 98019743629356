import type { SVGProps } from "react";

export function ThumbsUp({ x = 1, y = 1, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
    return (
        <svg
            width="20"
            transform={`scale(${x} ${y})`}
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...rest}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8333 4.16667C10.6123 4.16667 10.4003 4.25446 10.244 4.41074C10.0878 4.56702 9.99996 4.77899 9.99996 5V5.83333C9.99996 6.9384 9.56097 7.99821 8.77957 8.77961C8.19759 9.3616 7.46116 9.75364 6.66663 9.91583V14.1667C6.66663 14.6087 6.84222 15.0326 7.15478 15.3452C7.46734 15.6577 7.89126 15.8333 8.33329 15.8333H14.1666C14.1902 15.8333 14.2138 15.8343 14.2373 15.8363C14.3096 15.8425 14.4358 15.822 14.5962 15.6615C14.7622 15.4956 14.9258 15.2114 15.019 14.8222L15.8305 10.7649C15.8143 10.5689 15.7293 10.3841 15.5892 10.2441C15.4329 10.0878 15.221 10 15 10H12.5C12.0397 10 11.6666 9.6269 11.6666 9.16667V5C11.6666 4.77899 11.5788 4.56702 11.4225 4.41074C11.2663 4.25446 11.0543 4.16667 10.8333 4.16667ZM6.33329 16.8333C6.28624 16.8961 6.23456 16.9558 6.17847 17.0118C5.86591 17.3244 5.44199 17.5 4.99996 17.5H3.33329C2.89127 17.5 2.46734 17.3244 2.15478 17.0118C1.84222 16.6993 1.66663 16.2754 1.66663 15.8333V10C1.66663 9.55797 1.84222 9.13405 2.15478 8.82149C2.46734 8.50893 2.89126 8.33333 3.33329 8.33333H5.83329C6.49633 8.33333 7.13222 8.06994 7.60106 7.6011C8.0699 7.13226 8.33329 6.49637 8.33329 5.83333V5C8.33329 4.33696 8.59668 3.70107 9.06553 3.23223C9.53437 2.76339 10.1703 2.5 10.8333 2.5C11.4963 2.5 12.1322 2.76339 12.6011 3.23223C13.0699 3.70107 13.3333 4.33696 13.3333 5V8.33333H15C15.663 8.33333 16.2989 8.59673 16.7677 9.06557C17.2366 9.53441 17.5 10.1703 17.5 10.8333C17.5 10.8882 17.4945 10.943 17.4838 10.9968L16.6504 15.1634C16.6487 15.1724 16.6467 15.1813 16.6446 15.1902C16.4977 15.8171 16.2083 16.4065 15.7748 16.8401C15.3431 17.2717 14.7693 17.541 14.136 17.5H8.33329C7.6079 17.5 6.90689 17.2636 6.33329 16.8333ZM4.99996 10H3.33329V15.8333H4.99996V10Z"
                fill="#A5AAAF"
            />
        </svg>
    );
}
