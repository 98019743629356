export function MessageBubbleIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={26} height={24} fill="none">
            <path
                stroke="#E6E6EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M24.5 17a1 1 0 0 1-1 1h-11l-4 4v-4h-6a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1v14Z"
                clipRule="evenodd"
            />
            <path
                stroke="#E6E6EB"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M6.5 12h13M6.5 7h13"
            />
        </svg>
    );
}
