import { useState } from "react";
import { NewCallCallControl } from "./NewCallCallControl";
import { NoActiveCallControl } from "./NoActiveCallControl";

export function IdleNoCallControls() {
    const [isAddingCall, setIsAddingCall] = useState(false);

    function handleAddCallClick() {
        setIsAddingCall(true);
    }

    function handleCancelAddCallClick() {
        setIsAddingCall(false);
    }

    if (isAddingCall) {
        return <NewCallCallControl onAddCall={handleAddCallClick} onCancel={handleCancelAddCallClick} />;
    }

    return <NoActiveCallControl onAddCall={handleAddCallClick} />;
}
