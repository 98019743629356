import type { Logger } from "@expert/logging";
import type { ExpertAssistMessage } from "../../shared-types";
import { isFromBot } from "../../utils";
import { TimelineItem } from "../timeline-item";
import { generateTimelineItemFromType } from "./generateTimelineItemForType";

interface GenerateTimelineItemProps {
    messages: ExpertAssistMessage[];
    logger: Logger;
    scrollRef?: React.RefObject<HTMLDivElement>;
    isFocused: boolean;
}

export function generateTimelineItems({ messages = [], scrollRef, isFocused, logger }: GenerateTimelineItemProps) {
    const items: JSX.Element[] = [];

    const lastProactiveMessage =
        messages
            .slice()
            .reverse()
            .find((message) => ["Proactive", "Default"].includes(message.type))?.id ?? -1;

    items.push(
        ...messages.map((msg, i) => (
            <TimelineItem
                isLastMessage={i === messages.length - 1}
                isLastProactiveMessage={msg.id === lastProactiveMessage && isFocused}
                message={msg}
                type={msg.type}
                isLoading={false}
                key={`timeline-item-${msg.id}-${msg.type}`}
                scrollRef={scrollRef}
                metaData={msg.metaData}
                logger={logger}
            >
                {generateTimelineItemFromType(msg)}
            </TimelineItem>
        )),
    );

    if (messages.length > 0) {
        const mostRecentMessage = messages[messages.length - 1];
        const isLoadingBotMessage = !isFromBot(mostRecentMessage.type);
        if (isLoadingBotMessage) {
            items.push(
                <TimelineItem
                    isLastMessage
                    isLastProactiveMessage
                    isLoading
                    key="LoadingBotMessage"
                    type="Proactive"
                    logger={logger}
                />,
            );
        }
    }
    return items;
}
