import { type SVGProps } from "react";

export function CopyIcon(props: SVGProps<SVGSVGElement>) {
    return (
        <svg width="100%" height="100%" fill="none" viewBox="0 0 20 21" {...props}>
            <path
                stroke={props.stroke ?? "var(--mantine-color-text)"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M5.833 1.667h7.989c.22 0 .433.088.589.244l2.011 2.012a.834.834 0 0 1 .245.589v11.322c0 .46-.373.833-.834.833h-10A.833.833 0 0 1 5 15.834V2.5c0-.46.373-.833.833-.833Z"
                clipRule="evenodd"
            />
            <path
                stroke={props.stroke ?? "var(--mantine-color-text)"}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M13.17 19.334h-10a.833.833 0 0 1-.834-.834V5.167"
            />
        </svg>
    );
}
