import { List, Stack, Text } from "@mantine/core";
import { MarkdownViewer } from "@expert/solve-tools";
import { type DeviceInfo, type IvrInfo } from "../types";

export function ActivationInfoTooltip({ iccid, imei, details }: DeviceInfo & Partial<IvrInfo>) {
    const hasDetails = !!iccid || !!imei || !!details;
    if (!hasDetails) {
        return undefined;
    }
    return (
        <Stack gap="0.25rem">
            <Stack gap="0.25rem">
                <Text size="xs" fw={700}>
                    Details
                </Text>
                {details ? <MarkdownViewer content={details} /> : null}
                <List size="xs" spacing="0.25rem" pl="sm">
                    {imei ? <List.Item>IMEI: {imei}</List.Item> : null}
                    {iccid ? <List.Item>ICCID: {iccid}</List.Item> : null}
                </List>
            </Stack>
        </Stack>
    );
}
