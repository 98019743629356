export function triggerTabNotification(tabTitle: string, isProactiveEnabled: boolean) {
    if (isProactiveEnabled) {
        const flickerBrowserTab = setInterval(() => {
            document.title = document.title === tabTitle ? `(1) New message` : tabTitle;
        }, 1000);

        const onTabFocus = () => {
            clearInterval(flickerBrowserTab);
            document.title = tabTitle;
            window.removeEventListener("focus", onTabFocus);
        };

        window.addEventListener("focus", onTabFocus);
    }
}
