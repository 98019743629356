const deviceActivationFeatures = {
    isConversationalIVRGuideEnabled: false,
    isDeviceActivationGuideEnabled: false,
    isDeviceReplacementInfoEnabled: false,
};

const workspaceHeaderFeatures = {
    isProactiveEnabled: false,
};

export const workspaceFeatures = { ...deviceActivationFeatures, ...workspaceHeaderFeatures };
