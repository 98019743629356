import { Container, Flex, Text } from "@mantine/core";
import type { EligibilityStatus, SaleProgress } from "../../types";
import { getSalesViewConfig } from "./SaleStatusMap";
import classes from "./SaleView.module.css";

interface SaleViewProps {
    status: EligibilityStatus | SaleProgress;
    planType?: "Home" | "Other";
}

export function SaleView({ status, planType = "Home" }: SaleViewProps): JSX.Element {
    const { statusHeader, statusBody, icon } = getSalesViewConfig(status);
    return (
        <Flex className={classes.statusContainer} data-testid="sale-finished-view">
            <Container className={classes.statusIcon}>{icon}</Container>
            <Text className={classes.statusHeader} data-testid="sale-view-status-header">
                {statusHeader}
            </Text>
            <Text className={classes.statusBodyText} data-testid="sale-view-status-body">
                {statusBody(planType)}
            </Text>
        </Flex>
    );
}
