import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { type IvrInfo, type ActivationState, type DeviceInfo, type DeviceSource } from "../types";
import { type DeviceReplacement } from "../../customer-info";

export interface DeviceActivationStore {
    device: DeviceInfo;
    pendingDevice: DeviceInfo;
    ivrInfo?: IvrInfo;

    // provide individual setters to be used by device activation components to avoid overwriting other values
    setDeviceReplacement: (replacement: DeviceReplacement) => void;
    setIMEI: (imei: string) => void;
    setICCID: (iccid: string) => void;
    setIvrInfo: (ivr: IvrInfo, model?: string) => void;
    setNewDeviceModel: (model: string) => void;
    setDeviceSource: (deviceSource: DeviceSource) => void;
    setOldDeviceModel: (model: string) => void;

    // data may come from multiple sources - used for analytics + auto open logic
    includesDetails: { includesIVRDetails: boolean; includesDeviceReplacementDetails: boolean };

    activationState: ActivationState;
    setActivationState: (state: ActivationState) => void;

    activationAttempts: number;
    incrementActivationAttempts: () => void;

    validationError?: string;
    setValidationError: (val: string) => void;

    swapPendingDevice: VoidFunction;
    reset: () => void;
}

export const useDeviceActivationStore = create<DeviceActivationStore>()(
    persist(
        immer(
            devtools(
                (set) => ({
                    device: {},
                    pendingDevice: {},
                    ivrInfo: undefined,
                    includesDetails: { includesIVRDetails: false, includesDeviceReplacementDetails: false },
                    setDeviceReplacement: ({ imei, iccid, model, eSim }: DeviceReplacement) => {
                        set(
                            (state) => {
                                const hasDeviceInfo = Object.keys(state.device).length > 0;
                                if (!hasDeviceInfo) {
                                    state.device.iccid = iccid;
                                    state.device.imei = imei;
                                    state.device.model = model;
                                    state.device.eSim = eSim;
                                    state.device.source = "AsurionClaims";
                                } else {
                                    state.pendingDevice.iccid = iccid;
                                    state.pendingDevice.imei = imei;
                                    state.pendingDevice.model = model;
                                    state.pendingDevice.eSim = eSim;
                                    state.pendingDevice.source = "AsurionClaims";
                                }
                                state.includesDetails = {
                                    ...state.includesDetails,
                                    includesDeviceReplacementDetails: true,
                                };
                            },
                            false,
                            "setDeviceReplacement",
                        );
                    },
                    setIMEI: (imei: string) => {
                        set(
                            (state) => {
                                state.device.imei = imei;
                            },
                            false,
                            "setIMEI",
                        );
                    },
                    setICCID: (iccid: string) => {
                        set(
                            (state) => {
                                state.device.iccid = iccid;
                            },
                            false,
                            "setICCID",
                        );
                    },
                    setIvrInfo: (ivr: IvrInfo, model?: string) => {
                        set(
                            (state) => {
                                if (!state.device.model) {
                                    state.device.model = model;
                                } else {
                                    state.pendingDevice.model = model;
                                }
                                state.ivrInfo = ivr;
                                state.includesDetails = { ...state.includesDetails, includesIVRDetails: true };
                            },
                            false,
                            "setIvrInfo",
                        );
                    },
                    setNewDeviceModel: (model: string) => {
                        set(
                            (state) => {
                                state.device.model = model;
                                state.validationError = undefined;
                            },
                            false,
                            "setNewDeviceModel",
                        );
                    },
                    setOldDeviceModel: (model: string) => {
                        set(
                            (state) => {
                                state.device.previousModel = model;
                                state.validationError = undefined;
                            },
                            false,
                            "setOldDeviceModel",
                        );
                    },
                    setDeviceSource: (source: DeviceSource) => {
                        set(
                            (state) => {
                                state.device.source = source;
                            },
                            false,
                            "setDeviceSource",
                        );
                    },
                    activationState: "NotStarted",
                    setActivationState: (activationState: ActivationState) => {
                        set(
                            (state) => {
                                state.activationState = activationState;
                            },
                            false,
                            "setActivationState",
                        );
                    },
                    activationAttempts: 0,
                    incrementActivationAttempts: () => {
                        set(
                            (state) => {
                                state.activationAttempts += 1;
                            },
                            false,
                            "incrementActivationAttempts",
                        );
                    },
                    validationError: undefined,
                    setValidationError: (val: string) => {
                        set(
                            (state) => {
                                state.validationError = val;
                            },
                            false,
                            "setValidationError",
                        );
                    },
                    swapPendingDevice: () => {
                        set(
                            (state) => {
                                state.device = { ...state.device, ...state.pendingDevice };
                                state.pendingDevice = {};
                            },
                            false,
                            "reset",
                        );
                    },
                    reset: () => {
                        set(
                            (state) => {
                                state.device = {};
                                state.pendingDevice = {};
                                state.ivrInfo = undefined;
                                state.activationState = "NotStarted";
                                state.activationAttempts = 0;
                                state.includesDetails = {
                                    includesIVRDetails: false,
                                    includesDeviceReplacementDetails: false,
                                };
                            },
                            false,
                            "reset",
                        );
                    },
                }),
                {
                    enabled: import.meta.env.MODE !== "production",
                    store: "device-activation",
                    name: "device-activation",
                },
            ),
        ),
        { name: "device-activation-store", skipHydration: true },
    ),
);
